import React, { Fragment, useEffect, useState } from "react";
import "./direccion.scss"
import { Button } from "@mui/material";
import { Scheduler } from "@aldabil/react-scheduler";
import Axios from "axios";
import CardDireccion from "../../components/cardDireccion/cardDireccion";
import ModalEditEvent from "../../components/modalDireccion/modalEditEvent/modalEditEvent";
import ConfirmModal from "../../components/confirmModal/confirmModal";
import ModalTeacherClass from "../../components/modalDireccion/modalTeacherClass/modalTeacherClass";
import ModalAddEvent from "../../components/modalDireccion/modalAddEvent/modalAddEvent";
import ModalDataReport from "../../components/modalDireccion/modalDataReport/modalDataReport";
///privilage redirect
import { json, useNavigate } from "react-router-dom";
import { UserContext } from "../../context/userProvider";
import { dataDecrypt } from "../../utils/dataDecrypt";
import LoadingSpinner from "../../components/loading/loading.jsx";
import FunctionButton from "../../components/functionButton/functionButton.jsx";
import ModalGroupsTable from "../../components/modalDireccion/modalGroupsTable/modalGroupsTable.jsx";
import Alert from "../../components/alert/Alert.jsx";
const Direccion = () => {


  const [mode, setMode] = useState("default");
  var view = localStorage.getItem("ResourceView");
  const [teacherWithClass, setTeacherWithClass] = useState(JSON.parse(localStorage.getItem("teacherList")) || []);
  const [bookListClass, setBookListClass] = useState(JSON.parse(localStorage.getItem("bookList")) || []);
  const [unitListClass, setUnitListClass] = useState(JSON.parse(localStorage.getItem("unitList")) || []);
  const [typeListClass, setTypeListClass] = useState(JSON.parse(localStorage.getItem("typeList")) || []);

  ///privilege variables
  const [user, setUser] = React.useContext(UserContext);
  const navigate = useNavigate();
  var sessionStorageArray = [];

  const listEventInput = {
    teacherList: teacherWithClass || [],
    bookList: bookListClass || [],
    unitList: unitListClass || [],
    typeList: typeListClass || [],
  };
  //////////////modal select teacher class
  const [eventsState, setEventsState] = useState([]);
  const [eventsStateFilterForDate, setEventsStateFilterForDate] = useState([]);
  const [stateToFilterWReport, setStateToFilterWReport] = useState({
    date1: "",
    date2: "",
    case: null
  });

  const [updateEvents, setUpdateEvents] = useState(false);
  /////////card event variables
  const [anchorEl, setAnchorEl] = useState(null);
  const [info, setInfo] = useState({});
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  /////////Modal edit Event
  const [openModalEdit, setOpenModalEdit] = useState(false);
  const [dataToEdit, setDataToEdit] = useState({});
  //////////////////////////Teacher Group CRUD
  const [teacherGroupModal, setTeacherGroupModal] = useState(false);
  const [openModalWeeklyReport, setOpenModalWeeklyReport] = useState(false);
  const [createReportDiseabled, setCreateReportDiseabled] = useState(true);
  var arrayTeacher = [];
  var arrayBook = [];
  var arrayUnit = [];
  var arrayType = [];
  var eventsArray;
  const ColorPerLevel = [
    {
      book_id: 1,
      name: "Yellow",
      color: "#fdd835"
    },
    {
      book_id: 2,
      name: "Red",
      color: "#f45c43"
    },
    {
      book_id: 3,
      name: "Blue",
      color: "#1a2980"
    },
    {
      book_id: 4,
      name: "Green",
      color: "#1d976c"
    },
    {
      book_id: 5,
      name: "Purple",
      color: "#6e48aa"
    },
    {
      book_id: 6,
      name: "Smile 1",
      color: "#f45c43"
    },
    {
      book_id: 7,
      name: "Smile 2",
      color: "#1a2980"
    },
    {
      book_id: 8,
      name: "Smile 3",
      color: "#1d976c"
    },
    {
      book_id: 9,
      name: "Smile 4",
      color: "#fdd835"
    },
    {
      book_id: 10,
      name: "Smile 5",
      color: "#6e48aa"
    },
    {
      book_id: 11,
      name: "Smile 6",
      color: "#00f7ff"
    },
    {
      book_id: 12,
      name: "Mega",
      color: "#fa9638"
    }
  ];

  ///filter
  // const label = { inputprops: { 'aria-label': 'Checkbox demo' } };
  const [filterDataArray, setFilterDataArray] = useState([])
  const [yellowSelect, setYellowSelect] = useState(false);
  const [redSelect, setRedSelect] = useState(false);
  const [blueSelect, setBlueSelect] = useState(false);
  const [greenSelect, setGreenSelect] = useState(false);
  const [purpleSelect, setPurpleSelect] = useState(false);
  const [smile1Select, setSmile1Select] = useState(false);
  const [smile2Select, setSmile2Select] = useState(false);
  const [smile3Select, setSmile3Select] = useState(false);
  const [smile4Select, setSmile4Select] = useState(false);
  const [smile5Select, setSmile5Select] = useState(false);
  const [smile6Select, setSmile6Select] = useState(false);
  const [megaSelect, setMegaSelect] = useState(false);
  ///////////GroupsTable
  const [openGroupsTable, setOpenGroupsTable] = useState(false);
  ///modal confirm
  const [openConfirm, setOpenConfirm] = useState(false)
  const [stateDateToRefresh, setStateDateToRefresh] = useState(localStorage.getItem("dateStartToRefresh") && JSON.parse(localStorage.getItem("dateStartToRefresh")))
  ////Alert
  const [isVisibleAlert, setIsVisibleAlert] = useState(false);
  const [arrayAlerts, setArrayAlerts] = useState([])



  useEffect(() => {
    if (!localStorage.getItem("user")) {
      localStorage.removeItem("token");
    }
    Axios.get(process.env.REACT_APP_API_URL + "isUserAuth/", {
      headers: {
        "x-access-token": localStorage.getItem("token"),
      },
    }).then((response) => {
      if (response.data.auth === false) {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        navigate("/login");
      } else {
        if (Object.keys(user).length === 0) {
          var userStorage = localStorage.getItem("user");
          var userJson = dataDecrypt(userStorage);
          setUser(userJson);
          var checkAuth = false;
          sessionStorageArray = dataDecrypt(localStorage.getItem("session"));
          sessionStorageArray.forEach((e) => {
            if (userJson.id === e.id) {
              checkAuth = true;
            }
          });
          if (
            (userJson.idPriv.toString() !==
              process.env.REACT_APP_API_DIRECCION_PRIVILEGE &&
              userJson.idPriv.toString() !==
              process.env.REACT_APP_API_SUPERADMIN_PRIVILEGE) ||
            checkAuth !== true
          ) {
            navigate("/login");
          } else {
            GetAllLists()
            if (!localStorage.getItem("ResourceView")) {
              localStorage.setItem("ResourceView", mode)
            } else {
              setMode(localStorage.getItem("ResourceView"))
            }
          }
        } else {
          var checkAuth = false;
          sessionStorageArray = dataDecrypt(localStorage.getItem("session"));
          sessionStorageArray.forEach((e) => {
            if (user.id === e.id) {
              checkAuth = true;
            }
          });
          if (
            (user.idPriv.toString() !==
              process.env.REACT_APP_API_DIRECCION_PRIVILEGE &&
              user.idPriv.toString() !==
              process.env.REACT_APP_API_SUPERADMIN_PRIVILEGE) ||
            checkAuth !== true
          ) {
            navigate("/login");
          } else {
            GetAllLists()
            if (!localStorage.getItem("ResourceView")) {
              localStorage.setItem("ResourceView", mode)
            } else {
              setMode(localStorage.getItem("ResourceView"))
            }
          }

        }
      }
    });
  }, []);

  // Charge Events
  useEffect(() => {
    Axios.get(process.env.REACT_APP_API_URL + "isUserAuth/", {
      headers: {
        "x-access-token": localStorage.getItem("token"),
      },
    }).then((response) => {
      if (response.data.auth === true) {
        var getEvents = GetTeacherEvents()
        getEvents.then((res) => {
          var eventArray = []
          var arrayData = res.data;
          arrayData.forEach((d) => {
            var start = new Date(d.start)
            start.setHours(start.getHours() + 7);
            var end = new Date(d.end)
            end.setHours(end.getHours() + 7);
            var objEvent = {
              event_id: d.event_id,
              start: start,
              end: end,
              id_teacher: d.id_teacher,
              book_id: d.book_id,
              unit_id: d.unit_id,
              type_id: d.type_id,
              qtyStudents: d.qtyStudents,
              description: d.description,
              fk_unit_part: d.fk_unit_part,
              color: ColorPerLevel[d.book_id - 1].color
            }
            eventArray.push(objEvent)
          })
          setEventsState(eventArray)
          console.log(eventArray)
          eventsArray = eventArray;
          if (stateToFilterWReport.date1 !== "") {
            FilterForDate(stateToFilterWReport.date1, stateToFilterWReport.date2)
          }

        })
      }
    })
  }, [updateEvents])

  useEffect(() => {
    localStorage.removeItem("deletedEvents")
    if (localStorage.getItem("dateStartToRefresh")) {
      setTimeout(() => {
        setEventsStateFilterForDate([])
        setStateToFilterWReport({
          date1: stateDateToRefresh.dateS,
          date2: stateDateToRefresh.dateE,
          case: 1
        })
        FilterForDate(stateDateToRefresh.dateS, stateDateToRefresh.dateE)
        localStorage.removeItem("dateStartToRefresh")
      }, 1000);
    }
    if (localStorage.getItem("alertArray")) {
      setTimeout(() => {
        setArrayAlerts(JSON.parse(localStorage.getItem("alertArray")));
        setIsVisibleAlert(true);
        setTimeout(() => {
          localStorage.removeItem("alertArray")
        }, 1000)
      }, 1000);
      setTimeout(() => {
        setIsVisibleAlert(false)
      }, 4000);
    }
    if (localStorage.getItem("teacherAdded")) {
      setTimeout(() => {
        localStorage.removeItem("teacherAdded")
      }, 1500);
    }
  }, []);


  ///////////////////////
  const changeView = (mode) => {
    switch (mode) {
      case 1:
        setMode("default")
        localStorage.setItem("ResourceView", "default")
        window.location.reload()
        break;
      case 2:
        setMode("tabs")
        localStorage.setItem("ResourceView", "tabs")
        window.location.reload()

        break;
      default:
        break;
    }
  }

  const GetTeacherEvents = async () => {

    const res = await Axios.get(process.env.REACT_APP_API_URL + "getAllTeacherEvents/", {
      headers: {
        "x-access-token": localStorage.getItem("token"),
      },
    })
    return res.data
  }
  //// PROCESS TO CHARGE lIST DATA IN FORM SCHEDULE
  const GetAllLists = () => {
    GetTeacherWithClass()
    GetBookList()
    GetUnitList()
    GetTypeList()

  }
  const GetTeacherWithClass = () => {
    Axios.get(process.env.REACT_APP_API_URL + "getTeacherWithClass/", {
      headers: {
        "x-access-token": localStorage.getItem("token"),
      },
    }).then((response) => {
      arrayTeacher = response.data
      localStorage.setItem("teacherList", JSON.stringify(arrayTeacher))
      if (teacherWithClass) {
        if (arrayTeacher.length !== teacherWithClass.length) {
          window.location.reload()
        }
      } else {
        window.location.reload()
      }


    });
  }
  const GetBookList = () => {
    Axios.get(process.env.REACT_APP_API_URL + "getBookList/", {
      headers: {
        "x-access-token": localStorage.getItem("token"),
      },
    }).then((response) => {
      arrayBook = response.data
      localStorage.setItem("bookList", JSON.stringify(arrayBook))
      if (bookListClass) {
        if (arrayBook.length !== bookListClass.length) {
          window.location.reload()
        }
      } else {
        window.location.reload()
      }
    });
  }
  const GetUnitList = () => {
    Axios.get(process.env.REACT_APP_API_URL + "getUnitList/", {
      headers: {
        "x-access-token": localStorage.getItem("token"),
      },
    }).then((response) => {
      arrayUnit = response.data
      localStorage.setItem("unitList", JSON.stringify(arrayUnit))
      if (unitListClass) {
        if (arrayUnit.length !== unitListClass.length) {
          window.location.reload()
        }
      } else {
        window.location.reload()
      }
    });
  }
  const GetTypeList = () => {
    Axios.get(process.env.REACT_APP_API_URL + "getTypeList/", {
      headers: {
        "x-access-token": localStorage.getItem("token"),
      },
    }).then((response) => {
      arrayType = response.data
      localStorage.setItem("typeList", JSON.stringify(arrayType))
      if (typeListClass) {
        if (arrayType.length !== typeListClass.length) {
          window.location.reload()
        }
      } else {
        window.location.reload()
      }
    });
  }

  /////////////////////////
  ////////CArd on click event functions

  const handlePopOver = (event, data) => {
    setAnchorEl(event.currentTarget);
    const dateStart = ConvertDateFormat(data.start)
    const dateEnd = ConvertDateFormat(data.end)
    const teacherName = getTeacherName(data.id_teacher)

    setInfo({
      idEvent: data.event_id,
      nameBook: ColorPerLevel[data.book_id - 1].name,
      nameUnit: unitListClass[data.unit_id - 1].name,
      color: ColorPerLevel[data.book_id - 1].color,
      date: dateStart + " " + data.start.toLocaleTimeString("en-US", {
        timeStyle: "short"
      }) + " - " + dateEnd + " " + data.end.toLocaleTimeString("en-US", {
        timeStyle: "short"
      }),
      teacher: teacherName,
      qtyStudents: data.qtyStudents
    })
    setDataToEdit(data)

  };
  const getTeacherName = (idTeacher) => {
    var name
    teacherWithClass.forEach((n) => {
      if (n.id_teacher === idTeacher) {
        name = n.name
      }
    })
    return name
  }
  const handleClosePopOver = () => {
    setAnchorEl(null);
  };

  const handleFinishProcessEdit = () => {
    setUpdateEvents(!updateEvents)
    setOpenModalEdit(false)

    if (anchorEl !== null) {
      handleClosePopOver()
    }
  }


  const ConvertDateFormat = (date) => {
    var convert = new Date(date),
      mnth = ("0" + (convert.getMonth() + 1)).slice(-2),
      day = ("0" + convert.getDate()).slice(-2);
    return [day, mnth, convert.getFullYear()].join("-")
  }
  /////////////////////////

  const handleOpenModalEdit = () => {
    setOpenModalEdit(true)
  }
  const handleCancelEdit = (e) => {
    e.preventDefault()
    setOpenModalEdit(false)
  }


  ////////////////////////////////
  /////DeleteEvent////////////////
  ////////////////////////////////
  const [idEventToDelete, setIdEventToDelete] = useState()
  const DeleteTeacherEvent = async (idEvent) => {
    const res = await Axios.delete(process.env.REACT_APP_API_URL + "deleteEventTeacher/" + idEvent, {
      headers: {
        "x-access-token": localStorage.getItem("token"),
      },
    })
    return res.data
  }
  const handleDeleteEvent = (e) => {
    setOpenConfirm(true)
    setIdEventToDelete(e.idEvent)

  }


  const handleConfirmAction = () => {
    const deleteEvent = DeleteTeacherEvent(idEventToDelete)
    deleteEvent.then((res) => {
      const status = res.status
      if (status === 200) {
        setUpdateEvents(!updateEvents)
        handleClosePopOver()
        setOpenConfirm(false)
        if (localStorage.getItem("deletedEvents")) {
          let arrayDelete = JSON.parse(localStorage.getItem("deletedEvents"))
          arrayDelete.push(idEventToDelete)
          localStorage.setItem("deletedEvents", JSON.stringify(arrayDelete))
        } else {
          localStorage.setItem("deletedEvents", JSON.stringify([idEventToDelete]))
        }
      }
    })
  }
  const handleCancelAction = () => {
    setOpenConfirm(false)
  }
  /////////////////////////////// TEACHER CLASSES CRUD
  const handleOpenGroupModal = () => {
    setTeacherGroupModal(true)
  }

  const handleCloseGroupModal = () => {
    setTeacherGroupModal(false)
    if (localStorage.getItem("teacherAdded")) {
      let number = JSON.parse(localStorage.getItem("teacherAdded"))
      if (number !== 0) {
        window.location.reload()
      }
    }
  }

  //////////Weekly Report
  const handleOpanModalWeeklyReport = () => {
    setOpenModalWeeklyReport(true)
  }
  const handleCloseModalWeeklyReport = () => {
    setOpenModalWeeklyReport(false)
  }

  const ConvertDateFormatUSA = (date) => {
    var convert = new Date(date),
      mnth = ("0" + (convert.getMonth() + 1)).slice(-2),
      day = ("0" + convert.getDate()).slice(-2);
    return [mnth, day, convert.getFullYear()].join("-")
  }

  const GetDayName = (dateFormated) => {
    let dateName = new Date(dateFormated);
    return dateName.toLocaleString('en-us', { weekday: 'long' });
  }

  const MinusDays = (date) => {
    var newDate = new Date(date)
    return ConvertDateFormatUSA(newDate.setDate(newDate.getDate() - 6))

  }
  const SumDays = (date) => {
    var newDate = new Date(date)
    return ConvertDateFormatUSA(newDate.setDate(newDate.getDate() + 6))

  }
  const changeDateRange = (date) => {
    var today = new Date();
    setCreateReportDiseabled(false)
    var formatDate = ConvertDateFormatUSA(date)
    var dayName = GetDayName(formatDate)
    if (dayName !== "Monday" && dayName !== "Sunday") {
      let i = 1
      do {
        var dateToChange = new Date(date)
        dateToChange.setDate(dateToChange.getDate() - i)
        formatDate = ConvertDateFormatUSA(dateToChange)
        dayName = GetDayName(formatDate)
        if (dayName !== "Monday") {
          i++
        } else {
          i = 8
        }
      } while (i < 6);
    }
    if (dayName === "Sunday") {
      var minusDays = MinusDays(date)
      setStateToFilterWReport({
        date1: formatDate,
        date2: minusDays,
        case: 0
      })
      if (today >= new Date(minusDays) && today >= new Date(formatDate)) {
        setCreateReportDiseabled(false)
      } else {
        setCreateReportDiseabled(true)
      }
      FilterForDate(formatDate, minusDays)
      // FilterArrayData(formatDate, minusDays, 0)
    } else if (dayName === "Monday") {
      var sumDays = SumDays(date)
      setStateToFilterWReport({
        date1: formatDate,
        date2: sumDays,
        case: 1
      })
      if (today >= new Date(sumDays) && today >= new Date(formatDate)) {
        setCreateReportDiseabled(false)
      } else {
        setCreateReportDiseabled(true)
      }
      FilterForDate(formatDate, sumDays)

      // FilterArrayData(formatDate, sumDays, 1)
    }


  }
  const FilterForDate = (date1, date2) => {
    setEventsStateFilterForDate([])

    var dateS
    var dateE
    var dateSChange = new Date(date1)
    var dateEChange = new Date(date2)
    if (dateSChange > dateEChange) {
      dateSChange.setHours(23)
      dateS = dateEChange
      dateE = dateSChange
    }
    if (dateEChange > dateSChange) {
      dateEChange.setHours(23)
      dateE = dateEChange
      dateS = dateSChange
    }
    if (localStorage.getItem("deletedEvents")) {
      const newArr = checkDeleteEvents(eventsArray)
      newArr.then(v => {
        eventsArray = v
      })
    }
    eventsArray.filter(e => e.start >= dateS && e.end <= dateE).forEach((v) => { setEventsStateFilterForDate(eventsStateFilterForDate => [...eventsStateFilterForDate, v]) })

  }
  const checkDeleteEvents = async (eventsArray) => {
    let arrToDelete = JSON.parse(localStorage.getItem("deletedEvents"))
    var arr = eventsArray
    await arrToDelete.forEach(v => {
      arr = arr.filter(e => e.event_id !== v)
    });
    return arr

  }

  ///////////////Create Weekly report For each teacher
  const handleWeeklyReport = () => {
    var timeS
    var timeE
    var minutesS
    var minutesE
    var sum
    var dateToSet
    var teacherId
    var type_id
    var book_id
    var unit_id
    var unit_part
    var arrayToCreateReport = []
    const teacherClasses = GetAllTeacherClasses();
    teacherClasses.then((v) => {
      if (v.auth === false) {
        window.location.reload()
      } else {
        if (v.status === 200) {
          setLoading(true);
          setCreateReportDiseabled(true)
          switch (stateToFilterWReport.case) {
            case 0:
              let arrayClasses = v.teacherClasses
              arrayClasses = arrayClasses.filter(c => c.st !== 2)
              arrayClasses.forEach((c, i) => {
                if (c.day_off === 0) {
                  if (c.class_day.id === 1) {
                    sum = 0
                    for (let e = 0; e < 5; e++) {
                      teacherId = c.teacher.id;
                      type_id = 1;
                      book_id = c.book.id
                      unit_id = c.book.idUnit
                      unit_part = c.book.idUnitPart === null ? 0 : c.book.idUnitPart
                      timeS = parseInt(c.time.start)
                      timeE = parseInt(c.time.end)

                      dateToSet = new Date(stateToFilterWReport.date2)
                      dateToSet.setHours(sum * 24)
                      dateToSet.setHours(timeS)
                      const isoDateStart = new Date(dateToSet.getTime() - (dateToSet.getTimezoneOffset() * 60000)).toISOString().replace(/(?:\.\d{1,3})?Z$/, '')
                      dateToSet.setHours(timeE)
                      const isoDateEnd = new Date(dateToSet.getTime() - (dateToSet.getTimezoneOffset() * 60000)).toISOString().replace(/(?:\.\d{1,3})?Z$/, '')
                      arrayToCreateReport.push({ teacherId, isoDateStart, isoDateEnd, type_id, book_id, unit_id, unit_part })
                      sum++
                    }
                  }
                  if (c.class_day.id === 2 || c.class_day.id === 3) {
                    sum = 5
                    teacherId = c.teacher.id;
                    type_id = 1;
                    book_id = c.book.id
                    unit_id = c.book.idUnit
                    unit_part = c.book.idUnitPart === null ? 0 : c.book.idUnitPart
                    timeS = parseInt(c.time.start)
                    timeE = parseInt(c.time.end)
                    minutesS = parseInt(c.time.start.slice(-2))
                    minutesE = parseInt(c.time.end.slice(-2))
                    dateToSet = new Date(stateToFilterWReport.date2)
                    dateToSet.setHours(sum * 24)
                    dateToSet.setHours(timeS)
                    dateToSet.setMinutes(minutesS)
                    const isoDateStart = new Date(dateToSet.getTime() - (dateToSet.getTimezoneOffset() * 60000)).toISOString().replace(/(?:\.\d{1,3})?Z$/, '')
                    dateToSet.setHours(timeE)
                    dateToSet.setMinutes(minutesE)
                    const isoDateEnd = new Date(dateToSet.getTime() - (dateToSet.getTimezoneOffset() * 60000)).toISOString().replace(/(?:\.\d{1,3})?Z$/, '')
                    arrayToCreateReport.push({ teacherId, isoDateStart, isoDateEnd, type_id, book_id, unit_id, unit_part })
                  }
                  if (c.class_day.id === 4) {
                    sum = 6
                    teacherId = c.teacher.id;
                    type_id = 1;
                    book_id = c.book.id
                    unit_id = c.book.idUnit
                    unit_part = c.book.idUnitPart === null ? 0 : c.book.idUnitPart
                    timeS = parseInt(c.time.start)
                    timeE = parseInt(c.time.end)
                    dateToSet = new Date(stateToFilterWReport.date2)
                    dateToSet.setHours(sum * 24)
                    dateToSet.setHours(timeS)
                    const isoDateStart = new Date(dateToSet.getTime() - (dateToSet.getTimezoneOffset() * 60000)).toISOString().replace(/(?:\.\d{1,3})?Z$/, '')
                    dateToSet.setHours(timeE)
                    const isoDateEnd = new Date(dateToSet.getTime() - (dateToSet.getTimezoneOffset() * 60000)).toISOString().replace(/(?:\.\d{1,3})?Z$/, '')
                    arrayToCreateReport.push({ teacherId, isoDateStart, isoDateEnd, type_id, book_id, unit_id, unit_part })
                  }
                }
              })
              StartCreateWeeklyReport(arrayToCreateReport)
              break;
            case 1:
              let arrayClasses1 = v.teacherClasses
              arrayClasses1 = arrayClasses1.filter(c => c.st !== 2)
              arrayClasses1.forEach((c, i) => {
                if (c.day_off === 0) {
                  if (c.class_day.id === 1) {
                    sum = 0
                    for (let e = 0; e < 5; e++) {
                      teacherId = c.teacher.id;
                      type_id = 1;
                      book_id = c.book.id
                      unit_id = c.book.idUnit
                      unit_part = c.book.idUnitPart === null ? 0 : c.book.idUnitPart
                      timeS = parseInt(c.time.start)
                      timeE = parseInt(c.time.end)
                      dateToSet = new Date(stateToFilterWReport.date1)
                      dateToSet.setHours(sum * 24)
                      dateToSet.setHours(timeS)
                      const isoDateStart = new Date(dateToSet.getTime() - (dateToSet.getTimezoneOffset() * 60000)).toISOString().replace(/(?:\.\d{1,3})?Z$/, '')
                      dateToSet.setHours(timeE)
                      const isoDateEnd = new Date(dateToSet.getTime() - (dateToSet.getTimezoneOffset() * 60000)).toISOString().replace(/(?:\.\d{1,3})?Z$/, '')
                      arrayToCreateReport.push({ teacherId, isoDateStart, isoDateEnd, type_id, book_id, unit_id, unit_part })
                      sum++
                    }
                  }
                  if (c.class_day.id === 2 || c.class_day.id === 3) {
                    sum = 5
                    teacherId = c.teacher.id;
                    type_id = 1;
                    book_id = c.book.id
                    unit_id = c.book.idUnit
                    unit_part = c.book.idUnitPart === null ? 0 : c.book.idUnitPart
                    timeS = parseInt(c.time.start)
                    timeE = parseInt(c.time.end)
                    minutesS = parseInt(c.time.start.slice(-2))
                    minutesE = parseInt(c.time.end.slice(-2))
                    dateToSet = new Date(stateToFilterWReport.date1)
                    dateToSet.setHours(sum * 24)
                    dateToSet.setHours(timeS)
                    dateToSet.setMinutes(minutesS)
                    const isoDateStart = new Date(dateToSet.getTime() - (dateToSet.getTimezoneOffset() * 60000)).toISOString().replace(/(?:\.\d{1,3})?Z$/, '')
                    dateToSet.setHours(timeE)
                    dateToSet.setMinutes(minutesE)
                    const isoDateEnd = new Date(dateToSet.getTime() - (dateToSet.getTimezoneOffset() * 60000)).toISOString().replace(/(?:\.\d{1,3})?Z$/, '')
                    arrayToCreateReport.push({ teacherId, isoDateStart, isoDateEnd, type_id, book_id, unit_id, unit_part })
                  }
                  if (c.class_day.id === 4) {
                    sum = 6
                    teacherId = c.teacher.id;
                    type_id = 1;
                    book_id = c.book.id
                    unit_id = c.book.idUnit
                    unit_part = c.book.idUnitPart === null ? 0 : c.book.idUnitPart
                    timeS = parseInt(c.time.start)
                    timeE = parseInt(c.time.end)
                    dateToSet = new Date(stateToFilterWReport.date1)
                    dateToSet.setHours(sum * 24)
                    dateToSet.setHours(timeS)
                    const isoDateStart = new Date(dateToSet.getTime() - (dateToSet.getTimezoneOffset() * 60000)).toISOString().replace(/(?:\.\d{1,3})?Z$/, '')
                    dateToSet.setHours(timeE)
                    const isoDateEnd = new Date(dateToSet.getTime() - (dateToSet.getTimezoneOffset() * 60000)).toISOString().replace(/(?:\.\d{1,3})?Z$/, '')
                    arrayToCreateReport.push({ teacherId, isoDateStart, isoDateEnd, type_id, book_id, unit_id, unit_part })
                  }
                }
              })
              StartCreateWeeklyReport(arrayToCreateReport)

              break;
            case null:
              console.log("no se ha setiado el estado")
              break;
            default:
              break;
          }
        } else if (v.status === 201) {
          console.log("no hay grupos agregados")
        }
      }
    })
  }
  const GetAllTeacherClasses = async () => {
    const res = await Axios.get(process.env.REACT_APP_API_URL + "getAllTeacherClasses/", {
      headers: {
        "x-access-token": localStorage.getItem("token"),
      },
    })
    return res.data
  }

  function StartCreateWeeklyReport(array) {
    let statusCountArray = []
    for (let i = 0; i < array.length; i++) {
      const dataCreate = array[i];
      let t = i
      let p = i + 1
      let porcentage = ((p * 100) / array.length)
      setTimeout(() => {
        if (i % 1 === 0) {
          setLoadingPercentage(Math.round(porcentage))
        }
        const postEvent = PostCreateWeeklyReport(dataCreate.teacherId, dataCreate.isoDateStart, dataCreate.isoDateEnd, dataCreate.type_id, dataCreate.book_id, dataCreate.unit_id, dataCreate.unit_part)
        postEvent.then((response) => {
          switch (response.status) {
            case 200:
              console.log("exito")
              statusCountArray.push(response.status)
              break;
            case 201:
              console.log("status 201")
              statusCountArray.push(response.status)
              break;
            case 202:
              console.log("status 202")
              statusCountArray.push(response.status)
              break;
            case 203:
              console.log("status 203")
              statusCountArray.push(response.status)
              break;
            case 204:
              console.log("status 204")
              statusCountArray.push(response.status)
              console.log(response.message)
              break;
            case 205:
              console.log("status 205")
              statusCountArray.push(response.status)
              console.log(response.message)
              break;
            default:
              break;
          }
        })
        if (porcentage === 100) {

          setTimeout(() => {
            let count200 = 0
            let count201 = 0
            let count202 = 0
            let count203 = 0
            let count204 = 0
            let count205 = 0
            statusCountArray.forEach((v) => {
              switch (v) {
                case 200:
                  count200++
                  break;
                case 201:
                  count201++
                  break;
                case 202:
                  count202++
                  break;
                case 203:
                  count203++
                  break;
                case 204:
                  count204++
                  break;
                case 205:
                  count205++
                  break;
                default:
                  break;
              }
            })
            let statusAlert = []
            if (count200 !== 0) {
              const objAlert = {
                id: 1,
                type: "success",
                message: `Se han agregado ${count200} reportes al calendario`
              }
              statusAlert.push(objAlert)
            }
            if (count203 !== 0) {
              const objAlert = {
                id: 2,
                type: "danger",
                message: `Actualmente ya se han registrado ${count203} reportes en el calendario`
              }
              statusAlert.push(objAlert)
            }
            setCreateReportDiseabled(false)
            setUpdateEvents(!updateEvents)
            setLoading(false);
            setLoadingPercentage(0)
            if (count200 === 0) {
              setArrayAlerts(statusAlert)
              setIsVisibleAlert(true)
              setTimeout(() => {
                setIsVisibleAlert(false)
              }, 3000);
            }
            if (count200 !== 0) {
              localStorage.setItem("alertArray", JSON.stringify(statusAlert))
              refreshPageAfterPost()
            }
          }, 2000);
        }
      }, 200 * (t + 1));
    }
  }

  const refreshPageAfterPost = () => {
    var dateS
    var dateE
    var dateSChange = new Date(stateToFilterWReport.date1)
    var dateEChange = new Date(stateToFilterWReport.date2)
    if (dateSChange > dateEChange) {
      dateSChange.setHours(0)
      dateS = stateToFilterWReport.date2
      dateE = stateToFilterWReport.date1
    }
    if (dateEChange > dateSChange) {
      dateEChange.setHours(0)
      dateS = stateToFilterWReport.date1
      dateE = stateToFilterWReport.date2
    }
    localStorage.setItem("dateStartToRefresh", JSON.stringify({ dateS, dateE }))
    window.location.reload()

  }



  const PostCreateWeeklyReport = async (id_teacher, start, end, type_id, book_id, unit_id, unit_part) => {
    ////Execute API to insert eventTeacher
    const res = await Axios.post(process.env.REACT_APP_API_URL + "postCreateEventTeacher/", {
      id_teacher: id_teacher,
      start: start,
      end: end,
      type_id: type_id,
      lesson_id: book_id,
      unit_id: unit_id,
      unit_part: unit_part
    },
      {
        headers: {
          "x-access-token": localStorage.getItem("token"),
        },
      })
    return res.data
  }

  /*LOADING ------ */

  const [loading, setLoading] = useState(false);
  const [loadingPercentage, setLoadingPercentage] = useState(0);
  //////Filter
  const filterDataEvents = (e, colorBook) => {
    switch (colorBook) {
      case "y":
        setYellowSelect(e.target.checked)
        if (e.target.checked) {
          eventsState.filter(event => event.book_id === 1).forEach(data => { setFilterDataArray(filterDataArray => [...filterDataArray, data]) })

        } else {
          setFilterDataArray(filterDataArray.filter(e => e.book_id !== 1))
        }
        break;
      case "r":
        setRedSelect(e.target.checked)
        if (e.target.checked) {
          eventsState.filter(event => event.book_id === 2).forEach(data => { setFilterDataArray(filterDataArray => [...filterDataArray, data]) })

        } else {
          setFilterDataArray(filterDataArray.filter(e => e.book_id !== 2))
        }
        break;
      case "b":
        setBlueSelect(e.target.checked)
        if (e.target.checked) {
          eventsState.filter(event => event.book_id === 3).forEach(data => { setFilterDataArray(filterDataArray => [...filterDataArray, data]) })

        } else {
          setFilterDataArray(filterDataArray.filter(e => e.book_id !== 3))
        }
        break;
      case "g":
        setGreenSelect(e.target.checked)
        if (e.target.checked) {
          eventsState.filter(event => event.book_id === 4).forEach(data => { setFilterDataArray(filterDataArray => [...filterDataArray, data]) })

        } else {
          setFilterDataArray(filterDataArray.filter(e => e.book_id !== 4))
        }

        break;
      case "p":
        setPurpleSelect(e.target.checked)
        if (e.target.checked) {
          eventsState.filter(event => event.book_id === 5).forEach(data => { setFilterDataArray(filterDataArray => [...filterDataArray, data]) })

        } else {
          setFilterDataArray(filterDataArray.filter(e => e.book_id !== 5))
        }
        break;
      case "s1":
        setSmile1Select(e.target.checked)
        if (e.target.checked) {
          eventsState.filter(event => event.book_id === 6).forEach(data => { setFilterDataArray(filterDataArray => [...filterDataArray, data]) })

        } else {
          setFilterDataArray(filterDataArray.filter(e => e.book_id !== 6))
        }
        break;
      case "s2":
        setSmile2Select(e.target.checked)
        if (e.target.checked) {
          eventsState.filter(event => event.book_id === 7).forEach(data => { setFilterDataArray(filterDataArray => [...filterDataArray, data]) })

        } else {
          setFilterDataArray(filterDataArray.filter(e => e.book_id !== 7))
        }
        break;
      case "s3":
        setSmile3Select(e.target.checked)
        if (e.target.checked) {
          eventsState.filter(event => event.book_id === 8).forEach(data => { setFilterDataArray(filterDataArray => [...filterDataArray, data]) })

        } else {
          setFilterDataArray(filterDataArray.filter(e => e.book_id !== 8))
        }
        break;
      case "s4":
        setSmile4Select(e.target.checked)
        if (e.target.checked) {
          eventsState.filter(event => event.book_id === 9).forEach(data => { setFilterDataArray(filterDataArray => [...filterDataArray, data]) })

        } else {
          setFilterDataArray(filterDataArray.filter(e => e.book_id !== 9))
        }
        break;
      case "s5":
        setSmile5Select(e.target.checked)
        if (e.target.checked) {
          eventsState.filter(event => event.book_id === 10).forEach(data => { setFilterDataArray(filterDataArray => [...filterDataArray, data]) })

        } else {
          setFilterDataArray(filterDataArray.filter(e => e.book_id !== 10))
        }
        break;
      case "s6":
        setSmile6Select(e.target.checked)
        if (e.target.checked) {
          eventsState.filter(event => event.book_id === 11).forEach(data => { setFilterDataArray(filterDataArray => [...filterDataArray, data]) })

        } else {
          setFilterDataArray(filterDataArray.filter(e => e.book_id !== 11))
        }
        break;
      case "mega":
        setMegaSelect(e.target.checked)
        if (e.target.checked) {
          eventsState.filter(event => event.book_id === 12).forEach(data => { setFilterDataArray(filterDataArray => [...filterDataArray, data]) })

        } else {
          setFilterDataArray(filterDataArray.filter(e => e.book_id !== 12))
        }
        break;

      default:
        break;
    }
  }

  /////GroupsTable
  const handleCloseTableGroups = () => {
    setOpenGroupsTable(false);
  };
  const handleOpenTableGroups = () => {
    setOpenGroupsTable(true);
  };
  return (
    <div className="direccionContainer">
      <Fragment>
        <div className="functionNav">
          <div className="functionButtonsDiv">
            <FunctionButton icon={"groups"} background={"gray"} text={"Listado de Grupos"} onClick={handleOpenGroupModal} />
            <FunctionButton icon={"classlist"} background={"gray"} text={"Reporte Semanal"} onClick={handleOpanModalWeeklyReport} />
            <FunctionButton icon={"groupstable"} background={"gray"} text={"Tabla de Grupos"} onClick={handleOpenTableGroups} />

            <FunctionButton icon={"createWReport"} background={"gray"} disabled={createReportDiseabled} text={"Crear Reporte Semanal"} className={!createReportDiseabled ? "" : " disabled"} onClick={handleWeeklyReport} />
          </div>
          <div className="scheduleView">
            <span className="viewSpan">Vista</span>
            <Button
              color={mode === "default" ? "primary" : "inherit"}
              variant={mode === "default" ? "contained" : "text"}
              size="small"
              onClick={() => changeView(1)}
            // text="Tabla"
            >
              <i className={mode === "default" ? "material-icons" : "material-icons notChosen"}>table_view</i>
            </Button>
            <Button
              color={mode === "tabs" ? "primary" : "inherit"}
              variant={mode === "tabs" ? "contained" : "text"}
              size="small"
              onClick={() => changeView(2)}
            >
              <i className={mode !== "default" ? "material-icons" : "material-icons notChosen"}>tabs</i>
            </Button>
          </div>
        </div>
        <div className="filterClass">
          <div className="filter">
            <span>Libro</span>
          </div>
          <input type="checkbox" className="check yellowcheck" onChange={(e) => filterDataEvents(e, "y")} checked={yellowSelect} />
          <input type="checkbox" className="check redcheck" onChange={(e) => filterDataEvents(e, "r")} checked={redSelect} />
          <input type="checkbox" className="check bluecheck" onChange={(e) => filterDataEvents(e, "b")} checked={blueSelect} />
          <input type="checkbox" className="check greencheck" onChange={(e) => filterDataEvents(e, "g")} checked={greenSelect} />
          <input type="checkbox" className="check purplecheck" onChange={(e) => filterDataEvents(e, "p")} checked={purpleSelect} />
          <label>Smile</label>
          <input type="checkbox" className="check redcheck" onChange={(e) => filterDataEvents(e, "s1")} checked={smile1Select} />
          <input type="checkbox" className="check bluecheck" onChange={(e) => filterDataEvents(e, "s2")} checked={smile2Select} />
          <input type="checkbox" className="check greencheck" onChange={(e) => filterDataEvents(e, "s3")} checked={smile3Select} />
          <input type="checkbox" className="check yellowcheck" onChange={(e) => filterDataEvents(e, "s4")} checked={smile4Select} />
          <input type="checkbox" className="check purplecheck" onChange={(e) => filterDataEvents(e, "s5")} checked={smile5Select} />
          <input type="checkbox" className="check smile6check" onChange={(e) => filterDataEvents(e, "s6")} checked={smile6Select} />
          <input type="checkbox" className="check megacheck" onChange={(e) => filterDataEvents(e, "mega")} checked={megaSelect} />
        </div>
        {/* eventsStateFilterForDate */}
        <Scheduler
          events={filterDataArray.length === 0 ? eventsStateFilterForDate : filterDataArray}
          resources={!teacherWithClass ? [] : teacherWithClass}
          week={{
            weekDays: [0, 1, 2, 3, 4, 5, 6],
            weekStartOn: 1,
            startHour: 8,
            endHour: 21,
          }}
          resourceFields={{
            idField: "id_teacher",
            textField: "name",
            subTextField: "phoneNumber"
          }}
          resourceViewMode={view}
          fields={!teacherWithClass || !bookListClass || !unitListClass ? [] : [
            {
              name: "id_teacher",
            },
            {
              name: "book_id",
            },
            {
              name: "unit_id",
            },
            {
              name: "type_id",
            },
            {
              name: "qtyStudents",
            },
            {
              name: "description",
            },
            {
              name: "unit_part",
            }

          ]}
          viewerTitleComponent={(event) => {
            return <div>{bookListClass[event.book_id - 1].name} - {unitListClass[event.unit_id - 1].name}</div>;
          }}
          eventRenderer={({ event, ...props }) => {
            return (

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  height: "100%",
                  background: event.color
                }}
                {...props}
                aria-describedby={id}
                onClick={(e) => handlePopOver(e, event)}
              >
                <div>{bookListClass[event.book_id - 1].name} - {unitListClass[event.unit_id - 1].name} {event.fk_unit_part === 1 && 'a'} {event.fk_unit_part === 2 && 'b'}</div>
                <div
                  style={{ height: 20, background: "#ffffffb5", color: "black" }}
                >
                  {event.start.toLocaleTimeString("en-US", {
                    timeStyle: "short"
                  })} - {event.end.toLocaleTimeString("en-US", { timeStyle: "short" })}
                </div>
              </div>

            );
          }}
          customEditor={(scheduler) => <ModalAddEvent getDayName={GetDayName} listInputData={listEventInput} refresh={updateEvents} setRefresh={setUpdateEvents} scheduler={scheduler} />}
          onSelectedDateChange={(date) => { changeDateRange(date); }}
          selectedDate={stateDateToRefresh !== null ? new Date(stateDateToRefresh.dateS) : new Date()}

        />
      </Fragment>
      <CardDireccion
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClosePopOver}
        cardData={info}
        onClickEdit={handleOpenModalEdit}
        onClickDelete={(e) => { handleDeleteEvent(e) }}
      />
      <ModalEditEvent
        open={openModalEdit}
        onClickCancel={(e) => handleCancelEdit(e)}
        handleEndProcess={handleFinishProcessEdit}
        dataToEdit={dataToEdit}
      />
      <ConfirmModal
        open={openConfirm}
        handleConfirm={handleConfirmAction}
        onClickCancel={handleCancelAction}
      />
      <ModalTeacherClass
        open={teacherGroupModal}
        onClose={handleCloseGroupModal}
      />
      <ModalDataReport
        open={openModalWeeklyReport}
        onClose={handleCloseModalWeeklyReport}
        arrayDataWeeklyReport={eventsState}
        filterWeeklyReport={stateToFilterWReport}
        teacherList={teacherWithClass}
        bookList={bookListClass}
        unitList={unitListClass}
        onClickCancel={(e) => handleCancelEdit(e)}
        handleEndProcess={handleFinishProcessEdit}
      />
      <ModalGroupsTable
        open={openGroupsTable}
        onClose={handleCloseTableGroups}
        // teacherClassesData={groupsListArray}
        arrayData={eventsState}
      />
      <div className="divAlert">
        <Alert arrayAlertsModule={arrayAlerts} setArrayAlertsModule={setArrayAlerts} isVisibleModule={isVisibleAlert} setIsVisibleModule={setIsVisibleAlert} />
      </div>
      {/* componente loading------------ */}
      {
        loading &&
        <LoadingSpinner
          percentage={loadingPercentage}
        />
      }
    </div>
  )
}

export default Direccion;