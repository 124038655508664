import React, { useState, useEffect, useRef } from 'react';
import './inputDate.scss';

const InputDate = ({ selectionType, dateSelection }) => {
    const [selectedDate, setSelectedDate] = useState(null);
    const [isSelected, setIsSelected] = useState(false);
    const [showCalendar, setShowCalendar] = useState(false);
    const [selectedRange, setSelectedRange] = useState({ start: null, end: null });
    const [selectedWeek, setSelectedWeek] = useState(null);
    const [dateRangeSelected, setDateRangeSelected] = useState("");
    const [todaysDate, setTodaysDate] = useState();
    const datepickerRef = useRef(null);

    useEffect(() => {
        setCurrentDate();
        const onClickOutside = (e) => {
            if (datepickerRef.current && !datepickerRef.current.contains(e.target)) {
                setShowCalendar(false);
            }
        };
        document.addEventListener('click', onClickOutside);

        return () => {
            document.removeEventListener('click', onClickOutside);
        };

    }, [dateRangeSelected]);

    const setCurrentDate = () => {
        const currentDate = new Date();
        setTodaysDate(currentDate);
    };

    const renderCalendar = () => {
        const currentDate = selectedDate || new Date();
        const year = currentDate.getFullYear();
        const month = currentDate.getMonth();
        const firstDayOfMonth = new Date(year, month, 1);
        const lastDayOfMonth = new Date(year, month + 1, 0);
        const today = new Date();

        const daysInMonth = lastDayOfMonth.getDate();
        const startDayOfWeek = firstDayOfMonth.getDay() - 1;

        let dayCounter = 1;

        const rows = [];
        for (let i = 0; i < 6; i++) {
            const cells = [];
            for (let j = 0; j < 7; j++) {
                const dayValue = dayCounter - startDayOfWeek;
                const isOutOfRange = dayValue <= 0 || dayValue > daysInMonth;
                const isCurrentMonth = !isOutOfRange && dayValue <= daysInMonth;
                const isSelectedDate = isCurrentMonth && selectedDate && dayValue === selectedDate.getDate();
                const isSelectedRange = selectedRange.start && selectedRange.end && dayValue >= selectedRange.start && dayValue <= selectedRange.end;

                // Check if the day belongs to the previous month or the next month
                const isPreviousMonth = dayValue < 1;
                const isNextMonth = dayValue > daysInMonth;

                // Get the month corresponding to the current day
                const currentMonth = isPreviousMonth ? month - 1 : isNextMonth ? month + 1 : month;

                // Adjust the year if necessary
                const currentYear = isPreviousMonth && month === 0 ? year - 1 : isNextMonth && month === 11 ? year + 1 : year;

                // Create a new date for the current day
                const currentDay = new Date(currentYear, currentMonth, isPreviousMonth ? daysInMonth + dayValue : isNextMonth ? dayValue - daysInMonth : dayValue);

                // Check if the current day is within the selected week
                const isSelectedWeek = selectedWeek && currentDay >= selectedWeek.start && currentDay <= selectedWeek.end;

                const isToday = today.getFullYear() === year && today.getMonth() === month && today.getDate() === dayValue;

                cells.push(
                    <td
                        key={j}
                        onClick={() => isCurrentMonth && selectDate(dayValue)}
                        className={selectionType + ` day ${isOutOfRange ? 'out-of-range' : selectionType === 'date' && isSelectedDate ? isToday ? 'todays-date selected' : 'selected' : isSelectedRange ? isToday ? 'todays-date selected-range' : 'selected-range' : isSelectedWeek ? 'selected-week' : isToday ? 'todays-date' : ''}`}
                    >
                        {isCurrentMonth ? dayValue : ''}
                    </td>
                );

                dayCounter++;
            }
            rows.push(<tr key={i}>{cells}</tr>);
        }

        return rows;
    };


    const toggleCalendar = () => {
        setShowCalendar(!showCalendar);
    };

    const selectDate = (day) => {
        setIsSelected(true);
        const year = selectedDate ? selectedDate.getFullYear() : new Date().getFullYear();
        const month = selectedDate ? selectedDate.getMonth() : new Date().getMonth();
        let start, end, newRangeSelected;

        if (selectionType === 'date') {
            setSelectedDate(new Date(year, month, day));
            setShowCalendar(false);
        } else if (selectionType === 'range') {
            if (!selectedDate) {
                setSelectedDate(new Date(year, month, day));
            } else {
                start = Math.min(selectedDate.getDate(), day);
                end = Math.max(selectedDate.getDate(), day);
                setSelectedRange({ start, end });
                setShowCalendar(false);
            }
        } else if (selectionType === 'week') {
            start = new Date(year, month, day);
            const dayOfWeek = start.getDay();
            start.setDate(start.getDate() - (dayOfWeek === 0 ? 6 : dayOfWeek - 1)); // Adjust start to Monday of the selected week
            end = new Date(start);
            end.setDate(end.getDate() + 6); // Adjust end to Sunday of the selected week
            setSelectedWeek({ start, end });
            newRangeSelected = `${formatDate3(start)} - ${formatDate3(end)}`;
            setDateRangeSelected(newRangeSelected);
            setShowCalendar(false);
            dateSelection(start);
        }
    };

    const prevMonth = () => {
        const year = selectedDate ? selectedDate.getFullYear() : new Date().getFullYear();
        const month = selectedDate ? selectedDate.getMonth() : new Date().getMonth();
        setSelectedDate(new Date(year, month - 1, 1));
    };

    const nextMonth = () => {
        const year = selectedDate ? selectedDate.getFullYear() : new Date().getFullYear();
        const month = selectedDate ? selectedDate.getMonth() : new Date().getMonth();
        setSelectedDate(new Date(year, month + 1, 1));
    };

    const getMonthName = (month) => {
        const months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
        return months[month];
    };

    const getYear = (date) => {
        let year = new Date(date).getFullYear();
        return year;
    };

    const formatDate = (date) => {
        const options = { year: 'numeric', month: 'short', day: '2-digit' };
        return date.toLocaleDateString('es-ES', options);
    };

    const formatDate2 = (date) => {
        const options = { year: 'numeric', month: 'long', day: '2-digit' };
        return date.toLocaleDateString('es-ES', options);
    };

    const formatDate3 = (date) => {
        const options = { year: 'numeric', month: 'short', day: '2-digit' };
        return date.toLocaleDateString('es-ES', options);
    };

    return (
        <div className="custom-datepicker" ref={datepickerRef}>
            <svg xmlns="http://www.w3.org/2000/svg" className="calendar-icon" onClick={toggleCalendar} height="24" viewBox="0 -960 960 960" width="24" style={{}}><path d="M200-80q-33 0-56.5-23.5T120-160v-560q0-33 23.5-56.5T200-800h40v-80h80v80h320v-80h80v80h40q33 0 56.5 23.5T840-720v560q0 33-23.5 56.5T760-80H200Zm0-80h560v-400H200v400Zm0-480h560v-80H200v80Zm0 0v-80 80Zm280 240q-17 0-28.5-11.5T440-440q0-17 11.5-28.5T480-480q17 0 28.5 11.5T520-440q0 17-11.5 28.5T480-400Zm-160 0q-17 0-28.5-11.5T280-440q0-17 11.5-28.5T320-480q17 0 28.5 11.5T360-440q0 17-11.5 28.5T320-400Zm320 0q-17 0-28.5-11.5T600-440q0-17 11.5-28.5T640-480q17 0 28.5 11.5T680-440q0 17-11.5 28.5T640-400ZM480-240q-17 0-28.5-11.5T440-280q0-17 11.5-28.5T480-320q17 0 28.5 11.5T520-280q0 17-11.5 28.5T480-240Zm-160 0q-17 0-28.5-11.5T280-280q0-17 11.5-28.5T320-320q17 0 28.5 11.5T360-280q0 17-11.5 28.5T320-240Zm320 0q-17 0-28.5-11.5T600-280q0-17 11.5-28.5T640-320q17 0 28.5 11.5T680-280q0 17-11.5 28.5T640-240Z" /></svg>
            <input type="text" value={isSelected & selectionType === 'week' ? dateRangeSelected : isSelected & selectionType === 'date' ? formatDate2(selectedDate) : ''} readOnly onClick={toggleCalendar}></input>

            {showCalendar && (
                <div className="calendar-container" style={{ display: showCalendar ? 'block' : 'none' }}>
                    <div className="calendar-header">
                        <span className='year'>{getYear(selectedDate || new Date())}</span>
                        <div>
                            <span className="prev-month" onClick={prevMonth}>&#60;</span>
                            <span className="current-month">{getMonthName(selectedDate ? selectedDate.getMonth() : new Date().getMonth())}</span>
                            <span className="next-month" onClick={nextMonth}>&#62;</span>
                        </div>
                    </div>
                    <table>
                        <thead>
                            <tr>
                                <th>L</th>
                                <th>M</th>
                                <th>M</th>
                                <th>J</th>
                                <th>V</th>
                                <th>S</th>
                                <th>D</th>
                            </tr>
                        </thead>
                        <tbody>{renderCalendar()}</tbody>
                    </table>
                </div>
            )}
        </div>
    );
};

export default InputDate;
