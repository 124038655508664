import React, { useRef, useState, useEffect } from "react";
import "./header.scss";
import Axios from "axios";
import { dataDecrypt } from "../../utils/dataDecrypt";
import firebase from "firebase/compat/app";
import { format } from "crypto-js";
const HeaderT = (props) => {
  // Component states
  const [arrayMaterialTeacher, setArrayMaterialTeacher] = useState([])
  const [flag, setFlag] = useState(false)
  const initialized = useRef(false)
  // Array of colors and folder names in Firebase Storage
  const color = ["yellow", "red", "blue", "green", "purple"];
  const fireBfolderColors = [
    'YELLOW LEVEL',
    'RED LEVEL',
    'BLUE LEVEL',
    'GREEN LEVEL',
    'PURPLE LEVEL'
  ]
  // Effect to list folders when the component mounts
  useEffect(() => {
    listFolder()
  }, [])
  // Function to list folders in Firebase Storage
  function listFolder() {
    if (!initialized.current) {

      initialized.current = true
      if (arrayMaterialTeacher.length === 0) {

        const storageRef = firebase.storage().ref();
        // [START storage_list_all]
        // Create a reference under which you want to list
        let i = 0
        let arrayMain = []
        // Create an object for each color level and add it to arrayMain
        fireBfolderColors.forEach((v) => {
          var folderName = fireBfolderColors[i]
          let objMain = {
            name: folderName,
            unitFolders: []
          }

          arrayMain.push(objMain)
          i++
        })
        // Iterate over arrayMain to get folders and files for each color level
        for (let i = 0; i < arrayMain.length; i++) {
          let arrayFolder = []
          const b = fireBfolderColors[i]
          var listRef = storageRef.child(`MaterialV2/${b}`);
          // Find all the prefixes and items.
          listRef
            .listAll()
            .then((res) => {
              res.prefixes.forEach((folderRef) => {
                var nFolder = folderRef.name;
                let subObj = {
                  name: nFolder,
                  folderItems: [
                    {
                      name: "BOOK",
                      icon: "book"
                    },
                    {
                      name: "CLASS AUDIO",
                      icon: "graphic_eq",
                      items: []
                    },
                    {
                      name: "EXTRA MATERIAL",
                      icon: "storage",
                      items:[]
                    },
                    {
                      name: "INSTRUCTION AUDIOS",
                      icon: "headphones",
                      items: []
                    },
                    {
                      name: "LESSON PLAN",
                      icon: "content_paste",
                    },
                    {
                      name: "PPT",
                      icon: "web_asset",
                    },
                    {
                      name: "SONG",
                      icon: "music_note",
                    },
                    {
                      name: "TRAINING VIDEOS",
                      icon: "movie",
                    },
                    {
                      name: "VIDEO",
                      icon: "videocam",
                    }
                  ]
                }
                if (nFolder !== "BOOK") {
                  arrayFolder.push(subObj)
                }
              });
              arrayMain[i].unitFolders = arrayFolder
            })
            .catch((error) => {
              // Uh-oh, an error occurred!
              console.log(error); // Handle errors in data retrieval
            });
          // [END storage_list_all]
        }
        setArrayMaterialTeacher(arrayMain) // Update state with retrieved folders and files
      }
    }

  }

  // Function to handle obtaining file URLs in Firebase Storage
  const handlePathToPlay = (bookLevel, unit, contentToPlay, indexBook, indexUnit, indexFolderI) => {
    var folder = `MaterialV2/${bookLevel}/${unit}/${contentToPlay}`
    if (contentToPlay === "BOOK") {
      folder = `MaterialV2/${bookLevel}/${contentToPlay}`
    }
    const storageRef = firebase.storage().ref();

    // [START storage_list_all]
    // Create a reference under which you want to list
    var listRef = storageRef.child(folder);
    // Find all the prefixes and items.
    listRef
      .listAll()
      .then((res) => {
        if (res.items.length === 1) {
          // Handle obtained files
          res.items.forEach((itemRef) => {
            itemRef.getDownloadURL().then((url) => {
              let flag = 0
              props.url.forEach((v) => {
                if (v.url === url) {
                  flag++
                }
              })
              if (flag === 0) {
                let typeFile = url.split(".").pop();
                let format = typeFile.split('?');
                let objUrl = {
                  nameFile: contentToPlay,
                  format: format[0],
                  url: url
                }
                if (format[0] === "pdf") {
                  props.url.forEach((v, i) => {
                    if (v.format === "pdf") {
                      if (v.nameFile !== "PPT") {
                        if (props.url.length > 0) {
                          console.log(contentToPlay)
                          props.setURL(props.url.filter((a) => a.nameFile !== "BOOK" && a.nameFile !== "LESSON PLAN" && a.nameFile !== "EXTRA MATERIAL"))
                        }
                      } else {
                        if (props.url.length > 0) {
                          console.log(contentToPlay)
                          if (contentToPlay === "PPT") {
                            props.setURL(props.url.filter((a) => a.nameFile !== contentToPlay))
                          }
                        }
                      }
                    }
                  })
                }
                if (format[0] === "mp3" || format[0] === "wav" || format[0] === "wma") {
                  console.log("entra audio")
                  if (props.url.length > 0) {
                    props.setURL(props.url.filter((a) => a.format !== "mp3" && a.format !== "wav" && a.format !== "wma"))
                  }
                }
                if (format[0] === "mp4") {
                  console.log("entra video")
                  if (props.url.length > 0) {
                    props.setURL(props.url.filter((a) => a.format !== "mp4"))
                  }
                }
                props.setURL((url) => [...url, objUrl])
                CloseSideMenu()

              } else {
                console.log('se repite')
              }
            });
          });
        } else {
          let isEmptyItem = arrayMaterialTeacher[indexBook].unitFolders[indexUnit].folderItems[indexFolderI].items;
          if (isEmptyItem === undefined) {
            //Condicion para que el powerpoint se reproduzca
            if (contentToPlay === "PPT") {
              let objURL = {
                nameFile: contentToPlay,
              }
              res.items.forEach((itemRef) => {
                let iName = itemRef.name
                let typeFile = iName.split(".").pop();
                if (typeFile === "txt") {
                  itemRef.getDownloadURL().then((url) => {
                    fetch(url).then((r) => {
                      r.text().then((linkGoogle) => objURL.googleURL = linkGoogle);
                    });
                  })
                } else {
                  itemRef.getDownloadURL().then((url) => {
                    if (props.url.length > 0) {
                      props.setURL(props.url.filter((a) => a.nameFile !== contentToPlay))
                    }
                    objURL.url = url
                    objURL.format = typeFile;
                    setTimeout(() => {
                      props.setURL((url) => [...url, objURL])
                    }, 800);
                  })

                }

              });
              console.log("entra aqui?")
              CloseSideMenu()

            }
          } else {
            if (isEmptyItem.length === 0) {
              let arrayToChange = arrayMaterialTeacher
              let arrayItem = []
              res.items.forEach((itemRef) => {
                let iName = itemRef.name
                itemRef.getDownloadURL().then((url) => {
                  let typeFile = url.split(".").pop();
                  let format = typeFile.split('?');
                  let objItemToDisplay = {
                    nameFile:contentToPlay,
                    name: iName,
                    format: format[0],
                    url: url
                  }
                  arrayItem.push(objItemToDisplay)
                })
              });
              console.log(arrayItem)
              arrayToChange[indexBook].unitFolders[indexUnit].folderItems[indexFolderI].items = arrayItem;
              setArrayMaterialTeacher(arrayToChange);
              setTimeout(() => {
                setFlag(!flag)
              }, 500);
            }
          }
        }
      })
      .catch((error) => {
        // Uh-oh, an error occurred!
        console.log(error);
      });
    // [END storage_list_all]
  }
  // Function to handle playing subitems of files
  const handleToPlaySubItems = (nameF, formatF, urlF) => {
    // Handle playing files
    let objUrl = {
      nameFile: nameF,
      format: formatF,
      url: urlF
    }
    if (formatF === "pdf") {
      props.url.forEach((v, i) => {
        if (v.format === "pdf") {
          if (v.nameFile !== "PPT") {
            if (props.url.length > 0) {
              props.setURL(props.url.filter((a) => a.nameFile !== "BOOK" && a.nameFile !== "LESSON PLAN" && a.nameFile !== "EXTRA MATERIAL"))
            }
          }
        }
      })
    }
    if (formatF === "mp3" || formatF === "wav" || formatF === "wma") {
      console.log("entra")
      if (props.url.length > 0) {
        props.setURL(props.url.filter((a) => a.format !== "mp3" && a.format !== "wav" && a.format !== "wma"))
      }
    }
    if (formatF === "mp4") {
      console.log("entra")
      if (props.url.length > 0) {
        props.setURL(props.url.filter((a) => a.format !== "mp4"))
      }
    }
    CloseSideMenu()
    props.setURL((url) => [...url, objUrl])
  }
  /* Function to close the side menu.
   --------------------------------------------------------------------------------------
   * This function is used to close the side menu by clearing the window location hash.
   * It sets the window's location hash to an empty string, effectively removing any
   * anchor-based navigation.
   */
  const CloseSideMenu = () => {
    // Clear the window's location hash to close the side menu
    window.location.hash = '';
  }
  /* Function to handle checkbox click and disable checkboxes in other dropdown lists.
  -------------------------------------------------------------------------------------------
     This function is used to ensure that when a checkbox in one dropdown list is clicked,
     all checkboxes in other dropdown lists are deselected.
  */
  const booksClosing = (element) => {
    // Select all dropdown lists
    var allDropdownLists = document.querySelectorAll('.dropdown_list');

    // Convert the element parameter to a string and select the current dropdown list
    var current = String(element);
    var currentDropdown = document.querySelector(current);

    // Find the main checkbox of the current unit
    var bookCheckbox = currentDropdown.querySelector('input[type="checkbox"]');

    // Check if the current book has an active checkbox
    if (bookCheckbox && bookCheckbox.checked == false) {
      // If the current book is open, close all sublists within it
      var currentDropdownSubLists = currentDropdown.querySelectorAll('.dropdown_sub_li');
      currentDropdownSubLists.forEach(function (dropdownSubList) {
        var subListCheckboxes = dropdownSubList.querySelectorAll('input[type="checkbox"]');
        subListCheckboxes.forEach(function (subListCheckbox) {
          subListCheckbox.checked = false;
        });
      });
    }
    // Iterate over each dropdown list
    allDropdownLists.forEach(function (dropdown) {
      // If the dropdown list is not the current dropdown list
      if (dropdown !== currentDropdown) {
        // Find and uncheck all checkboxes in the dropdown list
        var checkboxes = dropdown.querySelectorAll('input[type="checkbox"]');
        checkboxes.forEach(function (checkbox) {
          checkbox.checked = false;
          // unitClosing()
        });
      }
    });
  };

  /*Function to close other units when one is clicked.
 -----------------------------------------------------------------------------------------------
  * Closes other dropdown units and their sublists when a unit is clicked.
 * This function ensures that when a unit is clicked, all other dropdown units and their sublists are closed.
 * If the clicked unit is already open, its sublists are closed.
 */
  const unitClosing = (element) => {
    // Select all dropdown lists
    var allDropdownLists = document.querySelectorAll('.dropdown_sub_li');

    // Convert the element parameter to a string and select the current dropdown list
    var current = String(element);
    var currentDropdown = document.querySelector(current);

    // Find the main checkbox of the current unit
    var unitCheckbox = currentDropdown.querySelector('input[type="checkbox"]');

    // Check if the current unit has an active checkbox
    if (unitCheckbox && unitCheckbox.checked == false) {
      // If the current unit is open, close all sublists within it
      var currentDropdownSubLists = currentDropdown.querySelectorAll('.drop_unit_li');
      currentDropdownSubLists.forEach(function (dropdownSubList) {
        var subListCheckboxes = dropdownSubList.querySelectorAll('input[type="checkbox"]');
        subListCheckboxes.forEach(function (subListCheckbox) {
          subListCheckbox.checked = false;
        });
      });

    }

    // Iterate over each dropdown list to close other units
    allDropdownLists.forEach(function (dropdown) {
      if (dropdown !== currentDropdown) {
        // Find and uncheck all checkboxes in the dropdown list
        var checkboxes = dropdown.querySelectorAll('input[type="checkbox"]');
        checkboxes.forEach(function (checkbox) {
          checkbox.checked = false;
        });

        // Find and uncheck all checkboxes in the sublists within the dropdown list
        var allDropdownSubLists = dropdown.querySelectorAll('.drop_unit_li');
        allDropdownSubLists.forEach(function (dropdownSubList) {
          var subListCheckboxes = dropdownSubList.querySelectorAll('input[type="checkbox"]');
          subListCheckboxes.forEach(function (subListCheckbox) {
            subListCheckbox.checked = false;
          });
        });
      }
    });
  };
  return (
    <>
      <div className='adult_side_left'>
        <nav className="nav">
          <div className="nav_conteiner">
            <div className="nav_conteiner_icons_menu">
              <a href="#menu" className="nav_menu nav_start">
                <i className="material-icons nav_icon">start</i>
              </a>
              <a href="#" className="nav_menu nav_menu--second">
                <i className="material-icons nav_icon">close</i>
              </a>
            </div>
            <ul className="dropdown" id="menu">{/* Dropdown menu */}
              <li className="dropdown_header">Book Colors</li>
              {// Map and render retrieved folders and files
                arrayMaterialTeacher.length !== 0 &&
                arrayMaterialTeacher.map((cl, cil) => {
                  return (
                    <li key={cil} className={`dropdown_list ${color[cil]}`}>
                      <a href="#" className={"dropdown_link "}>
                        <span className="dropdown_span">{cl.name}</span>
                        <input type="checkbox" className="dropdown_check" onClick={() => { booksClosing(`li.${color[cil]}`); }}></input>
                        <div className="dropdown_arrow"> <i className="material-icons">keyboard_arrow_down</i></div>
                        {/* <img className="dropdown_arrow" src={expand} /> */}
                      </a>
                      <div className="dropdown_content">
                        <ul className="dropdown_sub">
                          {// Map and render subfolders and files of each folder
                            arrayMaterialTeacher.length === 0 ? <></> :

                              arrayMaterialTeacher[cil].unitFolders.map((v, i) => {
                                return (
                                  <li key={i} className={`dropdown_sub_li ${color[cil]}_unit${i + 1}`}>
                                    <a href="#" className="dropdown_anchor">
                                      <div className="back_arrow"> <i className="material-icons">arrow_back</i></div>
                                      <span className="dropdown_anchor_span">{v.name}</span>
                                      <input type="checkbox" className="dropdown_anchor_check" onClick={() => unitClosing(`li.${color[cil]}_unit${i + 1}`)}></input>

                                    </a>
                                    <div className="dropdown_sub_li_content">
                                      <ul className="drop_unit">

                                        {// Map and render files and subfiles of the subfolder
                                          arrayMaterialTeacher[cil].unitFolders[i].folderItems.map((fi, iF) => {
                                            return (
                                              // Render files if no subfiles, or subfiles if there are
                                              arrayMaterialTeacher[cil].unitFolders[i].folderItems[iF].items === undefined ?
                                                <li key={iF} className="drop_unit_li" onClick={() => { handlePathToPlay(arrayMaterialTeacher[cil].name, v.name, fi.name, cil, i, iF) }}>
                                                  <i className="material-icons">{fi.icon}</i>
                                                  <span className="dropdown_sub_li_span">{fi.name}</span>
                                                </li> :
                                                <li key={iF} className="drop_unit_li" onClick={() => { handlePathToPlay(arrayMaterialTeacher[cil].name, v.name, fi.name, cil, i, iF) }}>
                                                  <a href="#" className="drop_unit_anchor">
                                                    <i className="material-icons">{fi.icon}</i>
                                                    <span className="dropdown_anchor_unit_span">{fi.name}</span>
                                                    <input type="checkbox" className="drop_unit_anchor_check"></input>
                                                    <div className="drop_unit_arrow"><i className="material-icons">keyboard_arrow_down</i></div>

                                                  </a>
                                                  <div className="drop_unit_li_content">
                                                    <ul className="drop_unit_li_sub">
                                                      {arrayMaterialTeacher[cil].unitFolders[i].folderItems[iF].items !== undefined && arrayMaterialTeacher[cil].unitFolders[i].folderItems[iF].items.sort((a, b) => a.name.localeCompare(b.name)).map((item, iItem) => {
                                                        return (
                                                          <li key={iItem} className="drop_unit_li_sub_li" onClick={() => { handleToPlaySubItems(item.nameFile, item.format, item.url) }}>{item.name}</li>
                                                        )
                                                      }
                                                      )}
                                                    </ul>
                                                  </div>
                                                </li>
                                            )
                                          })}
                                      </ul>
                                    </div>
                                  </li>
                                )
                              })
                          }
                        </ul>
                      </div>
                    </li>
                  )
                })
              }
            </ul>
          </div>
        </nav>
      </div>
    </>
  );
};

export default HeaderT;
