import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Axios from "axios";
import "./recoverAccount.scss";
import { dataDecrypt } from "../../../utils/dataDecrypt";
import { UserContext } from "../../../context/userProvider";

const RecoverAccount = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const navigate = useNavigate();
  var sessionStorageArray = []
  const [user, setUser] = React.useContext(UserContext);

  const handleRecover = (event) => {
    event.preventDefault();
    Axios.post(process.env.REACT_APP_API_URL + "changePasswordRequestEmail/", {
      email: email,
    }).then((response) => {
      if (response.data.status === 201) {
        setMessage(response.data.message)

      } else if (response.data.status === 200) {
        navigate('/recoverAccountCode', { state: { email: email } })
      }
    });
  };
  useEffect(() => {
    Axios.get(process.env.REACT_APP_API_URL + "isUserAuth/", {
      headers: {
        "x-access-token": localStorage.getItem("token"),
      },
    }).then((response) => {
      if (response.data.auth === false) {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
      } else {
        var userStorage = localStorage.getItem("user");
        var userJson = dataDecrypt(userStorage);
        setUser(userJson);

        var checkAuth = false;
        sessionStorageArray = dataDecrypt(localStorage.getItem("session"));
        sessionStorageArray.forEach((e) => {
          if (userJson.id === e.id) {
            checkAuth = true;
          }
        });
        if (checkAuth === true) {
          navigate("/login");
        }
      }
    });
  }, []);
  return (
    <>
      <div className="recoverContainer">
        <form
          className="recoverAccountForm"
          id="recoverAccountForm"
          action="#"
          onSubmit={handleRecover}
        >
          <h1>Recuperar Contraseña</h1>
          <h4>
            Podemos ayudarte a recuperar tu contraseña, ingresa el correo
            electrónico de tu cuenta y se te enviarán las instrucciones.
          </h4>
          <div className="containerEmailInputAndMessage">
            <input
              className="emailInput"
              type="email"
              placeholder="Email"
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
            <p>{message}</p>
          </div>
          <input className="submitButton" type="submit" value={"Enviar"} />
        </form>
      </div>
    </>
  );
};

export default RecoverAccount;
