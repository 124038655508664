import React, { useEffect, useState } from "react";
import "../modalsDireccionForm.scss"
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import InputAnimated from "../../inputAnimated/inputAnimated";
import Axios from "axios";
import Alert from "../../alert/Alert";
const ModalEditEvent = (props) => {
    const [listUnitParse, setListUnitParse] = useState([])
    const [listTypeParse, setListTypeParse] = useState([])
    const [idEvent, setEventId] = useState(0)
    const [unitId, setUnitId] = useState(0)
    const [typeId, setTypeId] = useState(0)
    const [qtyStudent, setQtyStudent] = useState(0)
    const [description, setDescription] = useState("")
    const [message, setMessage] = useState("")
    const [checkPart, setCheckPart] = useState(0)

    const [arrayAlerts, setArrayAlerts] = useState([])
    const [isVisible, setIsVisible] = useState(false);
    const [disableAll, setDisableAll] = useState(false);

    useEffect(() => {
        if (props.open === true) {
            setListUnitParse(JSON.parse(localStorage.getItem("unitList")))
            setListTypeParse(JSON.parse(localStorage.getItem("typeList")))
            setEventId(props.dataToEdit.event_id)
            setUnitId(props.dataToEdit.unit_id || props.dataToEdit.book.idUnit)
            setTypeId(props.dataToEdit.type_id)
            setQtyStudent(props.dataToEdit.qtyStudents)
            setDescription(props.dataToEdit.description)
            if (props.dataToEdit.fk_unit_part === undefined) {
                setCheckPart(props.dataToEdit.book.fk_unit_part)

            } else {
                setCheckPart(props.dataToEdit.fk_unit_part)

            }
        }
    }, [props.dataToEdit, props.open])

    //////////////modal EditEvent functions
    const PatchEditEvent = async (type_id, unit_id, students_assist, description, unit_part, idEvent) => {
        const res = await Axios.patch(process.env.REACT_APP_API_URL + "editEventTeacher/" + idEvent, {
            type_id: type_id,
            unit_id: unit_id,
            students_assist: students_assist,
            description: description,
            unit_part: unit_part
        }, {
            headers: {
                "x-access-token": localStorage.getItem("token"),
            },
        })
        return res.data
    }
    const handleConfirmEdit = () => {
        var thereIsAlert = 0
        const qtyStudentRespaldo = qtyStudent === "" ? props.dataToEdit.qtyStudents : qtyStudent
        const descriptionRespaldo = description === "" ? props.dataToEdit.description : description
        const editEvent = PatchEditEvent(typeId, unitId, qtyStudentRespaldo, descriptionRespaldo, checkPart, idEvent)
        editEvent.then((response) => {
            const status = response.status
            if (status === 200) {
                setIsVisible(true)
                setDisableAll(true)

                if (arrayAlerts.length <= 4) {
                    const objAlert = {
                        id: arrayAlerts.length + 1,
                        type: "success",
                        message: response.message
                    }
                    if (arrayAlerts.length > 0) {
                        arrayAlerts.forEach((v) => {
                            if (v.message === objAlert.message) {
                                thereIsAlert++
                            }
                        })
                        if (thereIsAlert === 0) {
                            setArrayAlerts([...arrayAlerts, objAlert])
                        }
                    } else {
                        setArrayAlerts([...arrayAlerts, objAlert])
                    }
                }
                setTimeout(() => {
                    props.handleEndProcess()
                    setIsVisible(false)
                    setDisableAll(false)
                    setArrayAlerts([])

                }, 1000);
            } else {
                setIsVisible(true)
                if (arrayAlerts.length <= 4) {
                    const objAlert = {
                        id: arrayAlerts.length + 1,
                        type: "danger",
                        message: response.message
                    }
                    if (arrayAlerts.length > 0) {
                        arrayAlerts.forEach((v) => {
                            if (v.message === objAlert.message) {
                                thereIsAlert++
                            }
                        })
                        if (thereIsAlert === 0) {
                            setArrayAlerts([...arrayAlerts, objAlert])
                        }
                    } else {
                        setArrayAlerts([...arrayAlerts, objAlert])
                    }
                    setTimeout(() => {
                        setArrayAlerts([])
                        setIsVisible(false)
                        setTimeout(() => {
                            if (response.auth === false) {
                                window.location.reload()
                            }

                        }, 500);
                    }, 3000);
                }
            }
        })

    }
    const handleCheckedPart = (part) => {
        switch (part) {
            case 1:
                if (checkPart !== 1) {
                    setCheckPart(1)

                } else {
                    setCheckPart(0)
                }
                break;
            case 2:
                if (checkPart !== 2) {
                    setCheckPart(2)
                } else {
                    setCheckPart(0)

                }

                break;
            default:
                break;
        }
    }
    return (
        <>
            <Modal
                open={props.open}
                onClose={props.onClose}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
                disableEnforceFocus
                disableAutoFocus
            >
                <Box className="modalForm">

                    <form className="formClass">
                        <div className="formHeader">
                            <div className="formTitle">
                                <h1>Editar Clase del día</h1>
                            </div>
                        </div>
                        <div className="formBody">
                            <div className="unitEditContainer">

                                <select disabled={disableAll} value={unitId} onChange={(e) => { setUnitId(e.target.value) }}>
                                    {
                                        listUnitParse.map((a, i) => {
                                            return (
                                                <option key={i} value={a.unit_id}>{a.name}</option>
                                            )
                                        })
                                    }
                                </select>
                                <div className="checkPartContainer">
                                    <div>
                                        <label>A </label>
                                        <input type="checkbox" checked={checkPart === 1} onChange={() => handleCheckedPart(1)} />

                                    </div>
                                    <div>

                                        <label>B </label>
                                        <input type="checkbox" checked={checkPart === 2} onChange={() => handleCheckedPart(2)} />
                                    </div>
                                </div>

                            </div>
                            <select disabled={disableAll} value={typeId} onChange={(e) => { setTypeId(e.target.value) }}>
                                {
                                    listTypeParse.map((a, i) => {
                                        return (

                                            <option key={i} value={a.type_id}>{a.name}</option>
                                        )
                                    })
                                }
                            </select>

                            <InputAnimated disabled={disableAll} type="number" value={qtyStudent || ''} onChange={(e) => { setQtyStudent(e.target.value) }} placeholder="Asistencia" />

                            <InputAnimated disabled={disableAll} type="text" value={description || ''} onChange={(e) => { setDescription(e.target.value) }} placeholder="Observaciones" />

                        </div>
                        <div className="formBottom">
                            <div></div>
                            <div className="buttonsContainer">
                                <button className={!disableAll ? "confirmButton" : "confirmButton disabled"} onClick={(e) => { e.preventDefault(); handleConfirmEdit() }}>Confirmar</button>
                                <button className={!disableAll ? "cancelButton" : "cancelButton disabled"} onClick={props.onClickCancel}>Cancelar</button>
                            </div>
                        </div>
                    </form>




                </Box>

            </Modal>
            <div className="divAlert">
                <Alert arrayAlertsModule={arrayAlerts} setArrayAlertsModule={setArrayAlerts} isVisibleModule={isVisible} setIsVisibleModule={setIsVisible} />
            </div>
        </>
    )
}
export default ModalEditEvent