import React from "react";
import "./inputAnimated.scss"
const InputAnimated = (props) => {
    return (
        <div className="input-container">
            <input disabled={props.disabled} className={props.className} type={props.type} value={props.value} onChange={props.onChange}  placeholder=" " required={props.required}></input>
            <label className="input-label">{props.placeholder}</label>
        </div>
    )
}
export default InputAnimated