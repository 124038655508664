import React from 'react';
import "./loading.scss"

const LoadingSpinner = ({percentage }) => {
  

  return (
    <div className="loading-spinner-container">
      <div className="loading-spinner">
        <div className='lodingcircle1'></div>
        <div className='lodingcircle2'></div>
        <div className='lodingcircle3'></div>
        <div className='lodingcircle4'></div>
        <div className='lodingcircle5'></div>
        <div className='lodingcircle6'></div>
        <div className='lodingcircle7'></div>
        <div className='lodingcircle8'></div>
        <div className="percentage">{percentage}%</div>
      </div>
    </div>
  );
};

export default LoadingSpinner;