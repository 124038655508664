import React, { useEffect, useRef, useState } from "react";
import Axios from "axios";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../context/userProvider";
import "./inscribete.scss";
import img1 from "../../assets/ao_1.jpg";
import { dataDecrypt } from "../../utils/dataDecrypt";
import FacebookAlbum from "../../components/facebookAlbum/facebookAlbum";
import ContactBubble from '../../components/contactBubble/contactBubble';
import FacebookAlbumPromociones from "../../components/facebookAlbumPromociones/facebookAlbumPromociones";
const Inscribete = () => {
  const navigate = useNavigate();
  const [user, setUser] = React.useContext(UserContext);
  const [coursesType, setCoursesType] = useState(false);
  var sessionStorageArray = []
  const initialized = useRef(false)
  useEffect(() => {
    Axios.get(process.env.REACT_APP_API_URL + "isUserAuth/", {
      headers: {
        "x-access-token": localStorage.getItem("token"),
      },
    }).then((response) => {
      if (response.data.auth === false) {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
      } else {
        var userStorage = localStorage.getItem("user");
        var userJson = dataDecrypt(userStorage);
        setUser(userJson);

        var checkAuth = false;
        sessionStorageArray = dataDecrypt(localStorage.getItem("session"));
        sessionStorageArray.forEach((e) => {
          if (userJson.id === e.id) {
            checkAuth = true;
          }
        });
        if (checkAuth !== true) {
          navigate("/login");
        }
      }
    });
  }, []);

  const accessToken = process.env.REACT_APP_ACCESS_TOKEN_FB_IMAGES; // Reemplaza con tu token de acceso de página
  const albumId = ['1101368524846590', '1101374521512657', '1101377248179051']; // Reemplaza con el ID del álbum específico, Estrella, Bugambilia, Online
  const albumPromociones = '1102006631449446';
  const handleChangeTypeCourses = () => {
    setCoursesType(!coursesType)
  }
  return (
    <>
      <div className="iContainer">
        <ContactBubble />
        <div className="oft_header">
          <div className="oft_header_img">
            <img className="img1" src={img1} />
          </div>
          <h3 className="oft_header_title1">Próximos</h3>
          <h1 className="oft_header_title2">Cursos</h1>
        </div>
        <div className="nextTermContainer">
          <div className="buttonContainer">
            <button className="buttonChange" onClick={handleChangeTypeCourses}>{coursesType === false ? "Promociones" : "Proximos Cursos"}</button>
          </div>
          {
            coursesType === false ?
              <FacebookAlbum accessToken={accessToken} albumsIds={albumId} />
              :
              <FacebookAlbumPromociones accessToken={accessToken} albumId={albumPromociones} />

          }
        </div>
      </div>
    </>
  );
};
export default Inscribete;
