import React from "react"
import './cardInfo.scss'
import { Link } from "react-router-dom";
const CardInfo = (props) => {

    return (
        <div className="cardContent">
            <div className="imageContainer">
            <img src={props.src} alt="" />
            </div>
            <div className="cardBodyContent">
                <h1>{props.title}
                </h1>
                <p>
                    {props.body}
                </p>
                <div className="cardButton">
                    <Link to={props.to} className="linkButton">Conoce Más...</Link>
                </div>
            </div>
        </div>
    )
}
export default CardInfo