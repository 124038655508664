import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Axios from "axios";
import "../../modalsDireccionForm.scss"
import InputAnimated from "../../../inputAnimated/inputAnimated";
import Alert from "../../../alert/Alert";
import ConfirmModal from "../../../confirmModal/confirmModal";
const ModalEditTeacherClass = (props) => {
    const [id, setId] = useState(0)
    const [checkDayOff, setCheckDayOff] = useState(false)
    const [checkOnline, setCheckOnline] = useState(false)
    const [checkFinished, setCheckFinished] = useState(false)
    const [checkPart, setCheckPart] = useState(0)
    const [teacher, setTeacher] = useState(0)
    const [teacherList, setTeacherList] = useState([])
    const [classDay, setClassDay] = useState(0)
    const [classDayList, setClassDayList] = useState([])
    const [subsidiary, setSubsidiary] = useState(0)
    const [subsidiaryList, setSubsidiaryList] = useState([])
    const [unitId, setUnitId] = useState(0)
    const [listUnitParse, setListUnitParse] = useState([])
    const [listBookParse, setListBookParse] = useState([])
    const [bookId, setBookId] = useState(0)
    const [qtyStudent, setQtyStudent] = useState(0)
    const [dateStart, setDateStart] = useState("")
    const [dateFinished, setDateFinished] = useState("")
    const [timeStart, setTimeStart] = useState("")
    const [timeEnd, setTimeEnd] = useState("")
    const [arrayAlerts, setArrayAlerts] = useState([])
    const [openConfirm, setOpenConfirm] = useState(false)
    const [optionState, setOptionState] = useState(0)

    var arrayEmptys = []

    /////requires
    const [teacherRequire, setTeacherRequire] = useState("")
    const [bookRequire, setBookRequire] = useState("")
    const [unitRequire, setUnitRequire] = useState("")
    const [qtyStudentRequire, setQtyStudentRequire] = useState("")
    const [timeStartRequire, setTimeStartRequire] = useState("")
    const [timeEndRequire, setTimeEndRequire] = useState("")
    const [subsidiaryRequire, setSubsidiaryRequire] = useState("")
    const [classDayRequire, setClassDayRequire] = useState("")
    const [dateStartRequire, setDateStartRequire] = useState("")
    const [dateFinishedRequire, setDateFinishedRequire] = useState("")

    ///////disabled time inputs
    const [disableTimeStart, setDisableTimeStart] = useState(false);
    const disableTimeEnd = true;

    const [disableAll, setDisableAll] = useState(false);
    //////////////
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        if (props.open === true) {
            GetAllList()
            setId(props.editData.id)
            setCheckDayOff(props.editData.day_off === 0 ? false : true)
            setCheckOnline(props.editData.is_online === 0 ? false : true)
            setCheckPart(props.editData.book.idUnitPart === null ? 0 : props.editData.book.idUnitPart)
            setTeacher(props.editData.teacher.id)
            setClassDay(props.editData.class_day.id)
            setSubsidiary(props.editData.subsidiary.id)
            setUnitId(props.editData.book.idUnit)
            setBookId(props.editData.book.id)
            setQtyStudent(props.editData.students_qty)
            setDateStart(props.editData.date.start !== null ? ConvertDateFormat(props.editData.date.start) : "")
            setDateFinished(props.editData.date.end !== null ? ConvertDateFormat(props.editData.date.end) : "")
            setTimeStart(props.editData.time.start)
            setTimeEnd(props.editData.time.end)
            setCheckFinished(props.editData.date.end !== null ? true : false)
            if (props.editData.class_day.id === 1) {
                setDisableTimeStart(false)
            } else {
                setDisableTimeStart(true)

            }
        }

    }, [props.open])

    const ConvertDateFormat = (date) => {
        var convert = new Date(date),
            mnth = ("0" + (convert.getMonth() + 1)).slice(-2),
            day = ("0" + convert.getDate()).slice(-2);
        return [convert.getFullYear(), mnth, day].join("-")
    }
    const GetAllList = () => {
        const teacherList = GetTeachersList();
        teacherList.then((t) => {
            if (t.auth === false) {
                window.location.reload();
                console.log(t)
            } else {
                setTeacherList(t.teachers)
            }
        })
        const subsidiaryList = GetSubsidiaryList();
        subsidiaryList.then((s) => {
            if (s.auth === false) {
                console.log(s)
            } else {
                setSubsidiaryList(s.subsidiary)
            }
        })
        const classDayList = GetClassDayList();
        classDayList.then((cd) => {
            if (cd.auth === false) {
                console.log(cd)
            } else {
                setClassDayList(cd.class_day)
            }
        })
        if (localStorage.getItem("bookList")) {
            setListBookParse(JSON.parse(localStorage.getItem("bookList")))
        } else {
            const bookListA = GetBookList();
            bookListA.then(bookL => {
                if (bookL.auth === false) {
                    console.log(bookL)
                } else {
                    localStorage.setItem("bookList", JSON.stringify(bookL))
                    setListBookParse(bookL)
                }
            })
        }
        if (localStorage.getItem("unitList")) {
            setListUnitParse(JSON.parse(localStorage.getItem("unitList")))
        } else {
            const unitListA = GetUnitList();
            unitListA.then(ul => {
                if (ul.auth === false) {
                } else {
                    localStorage.setItem("unitList", JSON.stringify(ul))
                    setListUnitParse(ul)
                }
            })
        }
    }

    const GetTeachersList = async () => {
        const res = await Axios.get(process.env.REACT_APP_API_URL + "getAllTeachers/", {
            headers: {
                "x-access-token": localStorage.getItem("token"),
            },
        })
        return res.data
    }
    const GetSubsidiaryList = async () => {
        const res = await Axios.get(process.env.REACT_APP_API_URL + "getSubsidiaryList/", {
            headers: {
                "x-access-token": localStorage.getItem("token"),
            },
        })
        return res.data
    }
    const GetClassDayList = async () => {
        const res = await Axios.get(process.env.REACT_APP_API_URL + "getClassDayList/", {
            headers: {
                "x-access-token": localStorage.getItem("token"),
            },
        })
        return res.data
    }
    const GetBookList = async () => {
        const res = Axios.get(process.env.REACT_APP_API_URL + "getBookList/", {
            headers: {
                "x-access-token": localStorage.getItem("token"),
            },
        })
        return res.data

    }
    const GetUnitList = () => {
        const res = Axios.get(process.env.REACT_APP_API_URL + "getUnitList/", {
            headers: {
                "x-access-token": localStorage.getItem("token"),
            },
        })

        return res.data
    }



    const handleChangeTimeStart = (e) => {
        var hour = e.target.value
        hour = hour.split(":")[0]
        var endHour = parseInt(hour) + 1;
        if (endHour < 10) {
            endHour = '0' + endHour.toString()
        }
        setTimeStart(`${hour}:00`)
        if (endHour === 24) {
            setTimeEnd("00:00")
        } else {
            setTimeEnd(`${endHour}:00`)

        }
    }
    const handleChangeTimeEnd = (e) => {
        var hour = e.target.value
        hour = hour.split(":")[0]
        setTimeEnd(`${hour}:00`)
    }
    const PatchEditTeacherClass = async (teacher_id, book_id, unit_id, subsidiary_id, qty_students, class_day_id, timeStart, timeEnd, dayOff, dateStart, dateEnd, isOnline, unit_part, id) => {
        const res = await Axios.patch(process.env.REACT_APP_API_URL + "editTeacherClass/" + id, {
            teacher_id: teacher_id,
            book_id: book_id,
            unit_id: unit_id,
            subsidiary_id: subsidiary_id,
            qty_students: qty_students,
            class_day_id: class_day_id,
            timeStart: timeStart,
            timeEnd: timeEnd,
            day_off: dayOff,
            date_start: dateStart,
            date_end: dateEnd,
            is_online: isOnline,
            unit_part: unit_part
        }, {
            headers: {
                "x-access-token": localStorage.getItem("token"),
            },
        })
        return res.data
    }
    const confirmEditTeacher = () => {
        checkEmpty();
        var thereIsAlert = 0
        const qtyStudent2nd = qtyStudent === "" ? props.editData.students_qty : qtyStudent
        if (arrayEmptys.length === 0) {
            if (timeEnd <= timeStart) {
                if (arrayAlerts.length <= 4) {
                    const objAlert = {
                        id: arrayAlerts.length + 1,
                        type: "danger",
                        message: "Verifique las horas de la clase"
                    }
                    if (arrayAlerts.length > 0) {
                        arrayAlerts.forEach((v) => {
                            if (v.message === objAlert.message) {
                                thereIsAlert++
                            }
                        })
                        if (thereIsAlert === 0) {
                            setArrayAlerts([...arrayAlerts, objAlert])
                        }
                    } else {
                        setArrayAlerts([...arrayAlerts, objAlert])
                    }
                }
                setTimeStartRequire("required")
                setTimeEndRequire("required")
            } else {
                const tStart = parseInt(timeStart)
                const checkHours = checkTime(tStart)
                checkHours.then((hr) => {

                    ////validar fecha inicia y fecha fin para que el no sea menor que el inicio
                    if (hr.status === 200) {
                        let day_off
                        let is_online
                        var book_id = bookId
                        var unit_id = unitId
                        var subsidiary_id = subsidiary
                        var student_qty = qtyStudent2nd
                        var date_start = dateStart
                        var date_end = dateFinished
                        if (checkDayOff === true) {
                            day_off = 1
                            is_online = 0
                            book_id = 1
                            unit_id = 1
                            subsidiary_id = 1
                            student_qty = 0
                            date_start = null
                            date_end = null
                        } else {
                            day_off = 0
                            if (checkOnline === true) {
                                is_online = 1
                            } else {
                                is_online = 0
                            }
                            if (checkFinished === false) {
                                date_end = null
                            }
                        }
                        const editTeacherClass = PatchEditTeacherClass(teacher, book_id, unit_id, subsidiary_id, student_qty, classDay, timeStart, timeEnd, day_off, date_start, date_end, is_online, checkPart, id)
                        editTeacherClass.then((ec) => {
                            if (ec.auth === false) {
                                setIsVisible(true)
                                setDisableAll(true)
                                setDisableTimeStart(true)
                                if (arrayAlerts.length <= 4) {
                                    const objAlert = {
                                        id: arrayAlerts.length + 1,
                                        type: "danger",
                                        message: ec.message
                                    }
                                    if (arrayAlerts.length > 0) {
                                        arrayAlerts.forEach((v) => {
                                            if (v.message === objAlert.message) {
                                                thereIsAlert++
                                            }
                                        })
                                        if (thereIsAlert === 0) {
                                            setArrayAlerts([...arrayAlerts, objAlert])
                                        }
                                    } else {
                                        setArrayAlerts([...arrayAlerts, objAlert])
                                    }
                                }
                                setTimeout(() => {
                                    window.location.reload()

                                }, 1500)
                            } else {
                                switch (ec.status) {
                                    case 200:
                                        setIsVisible(true)
                                        setDisableAll(true)
                                        setDisableTimeStart(true)
                                        props.setTeacherAddedFlag(Math.random())
                                        if (arrayAlerts.length <= 4) {
                                            const objAlert = {
                                                id: arrayAlerts.length + 1,
                                                type: "success",
                                                message: ec.message
                                            }
                                            if (arrayAlerts.length > 0) {
                                                arrayAlerts.forEach((v) => {
                                                    if (v.message === objAlert.message) {
                                                        thereIsAlert++
                                                    }
                                                })
                                                if (thereIsAlert === 0) {
                                                    setArrayAlerts([...arrayAlerts, objAlert])
                                                }
                                            } else {
                                                setArrayAlerts([...arrayAlerts, objAlert])
                                            }
                                        }
                                        setTimeout(() => {
                                            props.onCloseButton()
                                            clearVariables()
                                        }, 2500);
                                        break;
                                    case 201:
                                        setIsVisible(true)
                                        setTimeStartRequire("required")
                                        setTimeEndRequire("required")
                                        if (arrayAlerts.length <= 4) {
                                            const objAlert = {
                                                id: arrayAlerts.length + 1,
                                                type: "danger",
                                                message: ec.message
                                            }
                                            if (arrayAlerts.length > 0) {
                                                arrayAlerts.forEach((v) => {
                                                    if (v.message === objAlert.message) {
                                                        thereIsAlert++
                                                    }
                                                })
                                                if (thereIsAlert === 0) {
                                                    setArrayAlerts([...arrayAlerts, objAlert])
                                                }
                                            } else {
                                                setArrayAlerts([...arrayAlerts, objAlert])
                                            }
                                        }
                                        break;
                                    case 202:
                                        setIsVisible(true)
                                        if (arrayAlerts.length <= 4) {
                                            const objAlert = {
                                                id: arrayAlerts.length + 1,
                                                type: "danger",
                                                message: ec.message
                                            }
                                            if (arrayAlerts.length > 0) {
                                                arrayAlerts.forEach((v) => {
                                                    if (v.message === objAlert.message) {
                                                        thereIsAlert++
                                                    }
                                                })
                                                if (thereIsAlert === 0) {
                                                    setArrayAlerts([...arrayAlerts, objAlert])
                                                }
                                            } else {
                                                setArrayAlerts([...arrayAlerts, objAlert])
                                            }
                                        }
                                        break;
                                    case 203:
                                        setIsVisible(true)
                                        setTimeStartRequire("required")
                                        setTimeEndRequire("required")
                                        if (arrayAlerts.length <= 4) {
                                            const objAlert = {
                                                id: arrayAlerts.length + 1,
                                                type: "danger",
                                                message: ec.message
                                            }
                                            if (arrayAlerts.length > 0) {
                                                arrayAlerts.forEach((v) => {
                                                    if (v.message === objAlert.message) {
                                                        thereIsAlert++
                                                    }
                                                })
                                                if (thereIsAlert === 0) {
                                                    setArrayAlerts([...arrayAlerts, objAlert])
                                                }
                                            } else {
                                                setArrayAlerts([...arrayAlerts, objAlert])
                                            }
                                        }
                                        break;
                                    case 204:
                                        setIsVisible(true)
                                        setDateStartRequire("required")
                                        setDateFinishedRequire("required")
                                        if (arrayAlerts.length <= 4) {
                                            const objAlert = {
                                                id: arrayAlerts.length + 1,
                                                type: "danger",
                                                message: ec.message
                                            }
                                            if (arrayAlerts.length > 0) {
                                                arrayAlerts.forEach((v) => {
                                                    if (v.message === objAlert.message) {
                                                        thereIsAlert++
                                                    }
                                                })
                                                if (thereIsAlert === 0) {
                                                    setArrayAlerts([...arrayAlerts, objAlert])
                                                }
                                            } else {
                                                setArrayAlerts([...arrayAlerts, objAlert])
                                            }
                                        }
                                        break;

                                    default:
                                        break;
                                }

                            }
                        })
                    } else {

                        setIsVisible(true)
                        setTimeStartRequire("required")
                        if (arrayAlerts.length <= 4) {
                            const objAlert = {
                                id: arrayAlerts.length + 1,
                                type: "danger",
                                message: hr.message
                            }
                            if (arrayAlerts.length > 0) {
                                arrayAlerts.forEach((v) => {
                                    if (v.message === objAlert.message) {
                                        thereIsAlert++
                                    }
                                })
                                if (thereIsAlert === 0) {
                                    setArrayAlerts([...arrayAlerts, objAlert])
                                }
                            } else {
                                setArrayAlerts([...arrayAlerts, objAlert])
                            }
                        }
                    }
                })


            }
        }
    }

    const confirmDeleteTeacherClass = () => {
        var thereIsAlert = 0
        const deleteEvent = DeleteTeacherClass(id)
        deleteEvent.then((res) => {
            if (res.auth === false) {
                setIsVisible(true)
                setDisableAll(true)
                setDisableTimeStart(true)
                if (arrayAlerts.length <= 4) {
                    const objAlert = {
                        id: arrayAlerts.length + 1,
                        type: "danger",
                        message: res.message
                    }
                    if (arrayAlerts.length > 0) {
                        arrayAlerts.forEach((v) => {
                            if (v.message === objAlert.message) {
                                thereIsAlert++
                            }
                        })
                        if (thereIsAlert === 0) {
                            setArrayAlerts([...arrayAlerts, objAlert])
                        }
                    } else {
                        setArrayAlerts([...arrayAlerts, objAlert])
                    }
                }
                setTimeout(() => {
                    window.location.reload()

                }, 1500)
            } else {

                const status = res.status
                if (status === 200) {
                    setOpenConfirm(false)
                    ///se utiliza para actualziar la tabla
                    setIsVisible(true)
                    setDisableAll(true)
                    setDisableTimeStart(true)
                    props.setTeacherAddedFlag(Math.random())
                    const deleteEvent = DeleteEventsByIdTeacherClass(id);
                    deleteEvent.then((res) => {
                        console.log(res)
                    })
                    if (arrayAlerts.length <= 4) {
                        const objAlert = {
                            id: arrayAlerts.length + 1,
                            type: "success",
                            message: res.message
                        }
                        if (arrayAlerts.length > 0) {
                            arrayAlerts.forEach((v) => {
                                if (v.message === objAlert.message) {
                                    thereIsAlert++
                                }
                            })
                            if (thereIsAlert === 0) {
                                setArrayAlerts([...arrayAlerts, objAlert])
                            }
                        } else {
                            setArrayAlerts([...arrayAlerts, objAlert])
                        }
                    }
                    setTimeout(() => {
                        props.onCloseButton()
                        clearVariables()
                    }, 2500);
                }
            }
        })
    }
    const DeleteTeacherClass = async (idClass) => {
        const res = await Axios.delete(process.env.REACT_APP_API_URL + "deleteTeacherClass/" + idClass, {
            headers: {
                "x-access-token": localStorage.getItem("token"),
            },
        })
        return res.data
    }
    const DeleteEventsByIdTeacherClass = async (idClass) => {
        const res = await Axios.delete(process.env.REACT_APP_API_URL + "deleteEventsTeacherByIdTeacherClass/" + idClass, {
            headers: {
                "x-access-token": localStorage.getItem("token"),
            },
        })
        return res.data
    }

    const handleConfirmAction = () => {
        const optionAction = optionState
        switch (optionAction) {
            case 1:
                confirmDeleteTeacherClass()
                break;

            default:
                break;
        }

    }
    const handleCancelAction = () => {
        setOpenConfirm(false)
    }
    const handleDeleteTeacherClass = () => {
        setOpenConfirm(true)
        setOptionState(1)
    }

    const checkTime = async (timeStart) => {
        var res
        if (timeStart !== 8 && timeStart !== 9 && timeStart !== 10 && timeStart !== 13 && timeStart !== 15 && timeStart !== 16 && timeStart !== 17 && timeStart !== 18 && timeStart !== 19 && timeStart !== 20) {
            res = await {
                status: 201,
                message: "No se puede asignar ninguna clase a este horario"
            }

        } else {
            res = await {
                status: 200,
                message: "se puede asignar clase a este horario"
            }
        }
        return res
    }
    const checkEmpty = () => {
        arrayEmptys.length = 0
        if (teacher === 0) {
            arrayEmptys.push("teacher")
            setTeacherRequire("required")
        } else {
            setTeacherRequire("")

        }
        if (bookId === 0) {
            arrayEmptys.push("book")
            setBookRequire("required")
        } else {
            setBookRequire("")

        }
        if (unitId === 0) {
            arrayEmptys.push("unit")
            setUnitRequire("required")
        } else {
            setUnitRequire("")

        }
        if (timeStart === "") {
            arrayEmptys.push("timeStart")
            setTimeStartRequire("required")
        } else {
            setTimeStartRequire("")

        }
        if (timeEnd === "") {
            arrayEmptys.push("timeEnd")
            setTimeEndRequire("required")
        } else {
            setTimeEndRequire("")
        }
        if (subsidiary === 0) {
            arrayEmptys.push("teacher")
            setSubsidiaryRequire("required")
        } else {
            setSubsidiaryRequire("")

        }
        if (classDay === 0) {
            arrayEmptys.push("teacher")
            setClassDayRequire("required")
        } else {
            setClassDayRequire("")

        }

        if (checkDayOff === false) {
            if (qtyStudent === "") {
                arrayEmptys.push("qty")
                setQtyStudentRequire("required")
            } else {
                setQtyStudentRequire("")

            }
            if (dateStart === "") {
                arrayEmptys.push("dateStart")
                setDateStartRequire("required")
            } else {
                setDateStartRequire("")

            }
            if (checkFinished === true) {

                if (dateFinished === "") {
                    arrayEmptys.push("dateFinished")
                    setDateFinishedRequire("required")
                } else {
                    setDateFinishedRequire("")

                }
            }
        }
    }
    const handleChangeTimeInput = (e) => {
        var option = e.target.value
        option = parseInt(option)
        switch (option) {
            case 1:
                setDisableTimeStart(false)
                if (timeStart !== ":00") {
                    var hour = timeStart
                    hour = hour.split(":")[0]
                    var endHour = parseInt(hour) + 1;
                    if (endHour < 10) {
                        endHour = '0' + endHour.toString()
                    }
                    setTimeStart(`${hour}:00`)
                    if (endHour === 24) {
                        setTimeEnd("00:00")
                    } else {
                        setTimeEnd(`${endHour}:00`)

                    }
                }
                break;
            case 2:
                if (!disableTimeStart) {
                    setDisableTimeStart(true)
                }
                setTimeStart('08:00')
                setTimeEnd("13:00")
                break;
            case 3:
                if (!disableTimeStart) {
                    setDisableTimeStart(true)
                }
                setTimeStart('13:30')
                setTimeEnd("17:30")
                break;
            case 4:
                if (!disableTimeStart) {
                    setDisableTimeStart(true)
                }
                setTimeStart('09:00')
                setTimeEnd("13:00")
                break;
            default:
                break;
        }
    }
    const clearVariables = () => {
        setDisableAll(false)
        // setDisableTimeStart(true)
        setTeacher(0)
        setClassDay(0)
        setSubsidiary(0)
        setUnitId(0)
        setBookId(0)
        setQtyStudent(0)
        setTimeStart("")
        setTimeEnd("")
        setTeacherRequire("")
        setBookRequire("")
        setUnitRequire("")
        setQtyStudentRequire("")
        setTimeStartRequire("")
        setTimeEndRequire("")
        setSubsidiaryRequire("")
        setClassDayRequire("")
        setDateStartRequire("")
        setDateFinishedRequire("")
        setCheckDayOff(false)
        setCheckOnline(false)
        setIsVisible(false)
        setArrayAlerts([])
        setCheckPart(0)


    }

    const handleCheckDayOffOnline = (checkboxN) => {
        switch (checkboxN) {
            case 0:
                setCheckDayOff(!checkDayOff)

                break;
            case 1:
                setCheckOnline(!checkOnline)

                break;
            case 2:
                setCheckFinished(!checkFinished)
                break;
            default:
                break;
        }
    }


    const handleCheckedPart = (part) => {
        switch (part) {
            case 1:
                if (checkPart !== 1) {
                    setCheckPart(1)

                } else {
                    setCheckPart(0)
                }
                break;
            case 2:
                if (checkPart !== 2) {
                    setCheckPart(2)
                } else {
                    setCheckPart(0)

                }

                break;
            default:
                break;
        }
    }
    ///hay un desfase en los checkbox al momento de entrar a un grupo para editar
    return (
        <>
            <Modal
                open={props.open}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
                disableEnforceFocus
                disableAutoFocus
            >
                <Box className="modalForm">
                    <form className="formClass">
                        <div className="formHeader">
                            <div className="formTitle">
                                <h1>Editar Grupo</h1>
                            </div>
                        </div>
                        <div className="formBody">
                            <div className="day_off_container">
                                <label>Hora disponible</label>
                                <input type="checkbox" checked={checkDayOff} onChange={() => { handleCheckDayOffOnline(0) }} />
                                {
                                    !checkDayOff &&
                                    <>
                                        <label>Clase Online</label>
                                        <input type="checkbox" checked={checkOnline} onChange={() => { handleCheckDayOffOnline(1) }} />
                                        <label>Finalizó</label>
                                        <input type="checkbox" checked={checkFinished} onChange={() => { handleCheckDayOffOnline(2) }} />
                                    </>
                                }
                            </div>
                            <select disabled={disableAll} className={teacherRequire} value={teacher} onChange={(e) => { setTeacher(e.target.value) }}>
                                {
                                    teacherList.map((t, i) => {
                                        return (
                                            <option key={i} value={t.id}>{`${t.name} (${t.email}/${t.phoneNumber})`}</option>

                                        )
                                    })
                                }
                            </select>
                            {!checkDayOff && <>
                                <select disabled={disableAll} className={bookRequire} value={bookId} onChange={(e) => { setBookId(e.target.value) }}>
                                    {
                                        listBookParse.map((b, i) => {
                                            return (
                                                <option key={i} value={b.book_id}>{b.name}</option>
                                            )
                                        })
                                    }
                                </select>
                                <div className="unitEditContainer">
                                    <select disabled={disableAll} className={unitRequire} value={unitId} onChange={(e) => { setUnitId(e.target.value) }}>
                                        {
                                            listUnitParse.map((u, i) => {
                                                return (
                                                    <option key={i} value={u.unit_id}>{u.name}</option>
                                                )
                                            })
                                        }
                                    </select>
                                    <div className="checkPartContainer">
                                        <div>
                                            <label>A </label>
                                            <input type="checkbox" checked={checkPart === 1} onChange={() => handleCheckedPart(1)} />

                                        </div>
                                        <div>

                                            <label>B </label>
                                            <input type="checkbox" checked={checkPart === 2} onChange={() => handleCheckedPart(2)} />
                                        </div>

                                    </div>
                                </div>
                                <select disabled={true} className={subsidiaryRequire} value={subsidiary} onChange={(e) => { setSubsidiary(e.target.value) }}>
                                    {
                                        subsidiaryList.map((s, i) => {
                                            return (
                                                <option key={i} value={s.subsidiary_id}>{s.name}</option>

                                            )
                                        })
                                    }
                                </select>
                                <InputAnimated disabled={disableAll} value={qtyStudent} className={qtyStudentRequire} type="number" onChange={(e) => { setQtyStudent(e.target.value) }} placeholder="Cantidad de estudiante" />
                            </>
                            }
                            <select disabled={disableAll} className={classDayRequire} value={classDay} onChange={(e) => { setClassDay(e.target.value); handleChangeTimeInput(e) }}>
                                {
                                    classDayList.map((cd, i) => {
                                        return (
                                            <option key={i} value={cd.class_day_id}>{cd.name}</option>

                                        )
                                    })
                                }
                            </select>
                            <div className={checkFinished ? "inputTimeContainer finished" : "inputTimeContainer"}>
                                {
                                    !checkDayOff &&
                                    <div className="DateStart">
                                        <span>Fecha de inicio</span>
                                        <input type="date" value={dateStart} className={dateStartRequire} onChange={(e) => { setDateStart(e.target.value) }} />
                                    </div>
                                }
                                {checkFinished && !checkDayOff &&
                                    <div className="DateFinished">
                                        <span>Fecha de finalización</span>
                                        <input type="date" value={dateFinished} className={dateFinishedRequire} onChange={(e) => { setDateFinished(e.target.value) }} />
                                    </div>
                                }
                                <div className="timeStart">
                                    <span>inicia</span>
                                    <input className={timeStartRequire} value={timeStart} disabled={disableTimeStart} onChange={(e) => { handleChangeTimeStart(e) }} type="time" step="3600" />
                                </div>
                                <div className="timeEnd">
                                    <span>Termina</span>
                                    <input className={timeEndRequire} value={timeEnd} disabled={disableTimeEnd} onChange={(e) => { handleChangeTimeEnd(e) }} type="time" step="3600" />
                                </div>
                            </div>
                        </div>
                        <div className="formBottom">
                            <div>
                                <button disabled={disableAll} className={!disableAll ? "deleteButton" : "deleteButton disabled"} onClick={(e) => { e.preventDefault(); handleDeleteTeacherClass(); }}>Eliminar</button>

                            </div>
                            <div className="buttonsContainer">
                                <button disabled={disableAll} className={!disableAll ? "confirmButton" : "confirmButton disabled"} onClick={(e) => { e.preventDefault(); confirmEditTeacher() }}>Confirmar</button>
                                <button disabled={disableAll} className={!disableAll ? "cancelButton" : "cancelButton disabled"} onClick={() => { props.onCloseButton(); clearVariables() }}>Cancelar</button>
                            </div>
                        </div>
                    </form>
                </Box>
            </Modal>
            <div className="divAlert">
                <Alert arrayAlertsModule={arrayAlerts} setArrayAlertsModule={setArrayAlerts} isVisibleModule={isVisible} setIsVisibleModule={setIsVisible} />

            </div>

            <ConfirmModal
                open={openConfirm}
                handleConfirm={handleConfirmAction}
                onClickCancel={handleCancelAction}
            />
        </>
    )
}
export default ModalEditTeacherClass