import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './facebookAlbum.scss';
import { json } from 'react-router-dom';

const FacebookAlbum = ({ accessToken, albumsIds }) => {
    const [photosByAlbum, setPhotosByAlbum] = useState({});

    useEffect(() => {
        const fetchPhotos = async () => {
            try {
                const albumIds = albumsIds;

                const fetchPromises = albumIds.map(id => axios.get(`https://graph.facebook.com/${id}`, {
                    params: {
                        access_token: accessToken,
                        fields: 'name,photos{id,source,created_time}'
                    }
                }));

                const responses = await Promise.all(fetchPromises);
                const photosByAlbum = responses.reduce((acc, response) => {
                    const albumName = response.data.name;
                    const photos = response.data.photos ? response.data.photos.data : [];

                    acc[albumName] = photos.map(photo => ({
                        ...photo,
                        albumName
                    }));
                    return acc;
                }, {});

                const dataToStore = {
                    photosByAlbum,
                    lastUpdate: new Date().getTime()
                };
                localStorage.setItem("imagesFB", JSON.stringify(dataToStore));

                setPhotosByAlbum(photosByAlbum);
            } catch (error) {
                console.error('Error fetching photos:', error);
            }
        };

        const storedData = JSON.parse(localStorage.getItem("imagesFB"));
        const currentTime = new Date().getTime();

        // Verificar el formato del localStorage y actualizar si es necesario
        const isValidData = storedData && typeof storedData === 'object' && 'photosByAlbum' in storedData && 'lastUpdate' in storedData;

        if (!isValidData || currentTime - storedData.lastUpdate > 1 * 24 * 60 * 60 * 1000) {
            // Si los datos no son válidos o si han pasado más de 2 días, obtener las fotos nuevamente
            fetchPhotos();
        } else {
            setPhotosByAlbum(storedData.photosByAlbum);
        }
    }, [accessToken, albumsIds]);

    return (
        <div>
            {Object.keys(photosByAlbum).map((albumName,i) => (
                photosByAlbum[albumName].length !== 0 &&
                <div key={albumName} className='main-album-container'>
                    <h2 className={'albumTitle'+i}>{albumName}</h2>
                    <div className='photo-container'>
                        {photosByAlbum[albumName].map(photo => (
                            <img key={photo.id} src={photo.source} alt={`Photo from ${albumName}`} />
                        ))}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default FacebookAlbum;