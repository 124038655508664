import * as React from "react"
const MailSVG = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={500}
    height={500}
    viewBox="0 0 512 512"
    {...props}
  >
    <title>{"mail-svg"}</title>
    <path
      fillRule="evenodd"
      d="M256 75c149.3 0 225 .3 225 1 0 .5 1.4 1 3 1s3 .5 3 1 .9 1 2 1 2 .5 2 1 .7 1 1.5 1 4.4 2.9 8 6.5c3.6 3.6 6.5 7.2 6.5 8 0 .8.4 1.5 1 1.5s1 .9 1 2 .6 2.2 1.3 2.3c.9.3 1.2 32.1 1.2 154.7s-.3 154.4-1.3 154.7c-.6.2-1.2 1.2-1.2 2.3 0 1.1-.4 2-1 2s-1 .7-1 1.5-2.9 4.4-6.6 8c-3.6 3.6-7.2 6.5-8 6.5-.7 0-1.4.5-1.4 1 0 .6-.9 1-2 1s-2 .5-2 1c0 .6-1.4 1-3 1s-3 .5-3 1c0 .7-75.7 1-225 1s-225-.3-225-1c0-.5-1.4-1-3-1s-3-.4-3-1c0-.5-.9-1-2-1s-2-.4-2-1c0-.5-.7-1-1.5-1s-4.4-2.9-8-6.5c-3.6-3.6-6.5-7.2-6.5-8 0-.8-.5-1.5-1-1.5-.6 0-1-.9-1-2s-.6-2.1-1.3-2.3C.8 410.4.5 378.6.5 256s.3-154.4 1.2-154.7c.7-.1 1.3-1.2 1.3-2.3 0-1.1.4-2 1-2 .5 0 1-.7 1-1.5s2.9-4.4 6.5-8c3.5-3.6 7.1-6.5 8-6.5.8 0 1.5-.5 1.5-1s.9-1 2-1 2-.5 2-1 1.1-1 2.5-1 2.7-.5 3-1c.4-.7 76.6-1 225.5-1zM152.5 203.5c52.5 52.5 96.2 95.5 97 95.5.8 0 1.5.4 1.5 1s2.3 1 5 1c2.8 0 5-.4 5-1s.7-1 1.5-1 44.5-43 97-95.5 95.5-95.7 95.5-96c0-.3-89.6-.5-199-.5s-199 .2-199 .5 43 43.5 95.5 96zM31 380l124-124L31.1 131.9zm326-124c95.3 95.4 123.2 123 123.5 123 .3 0 .5-55.3.5-123 0-67.6-.3-122.9-.7-122.9-.5.1-28.4 27.8-62 61.5zM57 404.5c0 .3 89.5.5 199 .5 109.4 0 199-.2 199-.5 0-.2-27.7-28.1-61.5-62L332 281c-35.6 35.7-46.6 46-47.5 46-.8 0-1.6.4-1.6.8s-1.2 1.3-2.7 2c-1.5.7-3.3 1.2-4 1.2-.7 0-1.2.5-1.2 1 0 .6-1.4 1-3 1-1.7 0-3 .5-3 1 0 .6-5 1-13 1s-13-.4-13-1c0-.5-1.4-1-3-1-1.7 0-3-.4-3-1 0-.5-.6-1-1.3-1-.7 0-2.5-.5-4-1.2s-2.7-1.6-2.7-2c0-.4-.7-.8-1.5-.8-.9 0-11.9-10.3-24.5-23-12.7-12.6-23.2-22.9-23.3-22.9-.1.1-27.8 27.7-61.5 61.5S57 404.3 57 404.5z"
      style={{
        fill: "#fff",
      }}
    />
  </svg>
)
export default MailSVG
