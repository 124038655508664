import React, { useEffect, useState } from "react";
import "./Alert.scss"
import check from "../../assets/alertImg/check.png"
import info from "../../assets/alertImg/info.png"
import warning from "../../assets/alertImg/warning.png"
import danger from "../../assets/alertImg/x.png"

const Alert = (props) => {
    const [animationOpen, setAnimationOpen] = useState("")
    const handleIcon = (type) => {
        
        switch (type) {
            case "success": return check;
            case "info": return info;
            case "warning": return warning;
            case "danger": return danger;
            default: break;
        }
    }

    useEffect(()=>{
        if (props.isVisibleModule) {
            setAnimationOpen("is-open")
        }
    },[props.isVisibleModule])

    const handleCloseAlert = (e, i) => {
        e.preventDefault()
        var arrayCheck = props.arrayAlertsModule.filter((a) => a.id !== i)
        props.setArrayAlertsModule(props.arrayAlertsModule.filter((a) => a.id !== i))
        if (arrayCheck.length > 0) {
            props.setIsVisibleModule(true)
        } else {
            props.setIsVisibleModule(false)
        }
    }

    return (
        <>
            {props.isVisibleModule &&
                props.arrayAlertsModule.map((v, i) => {
                    return (
                        <div key={i} className={"alert " + animationOpen}>
                            <div className={"alertbox " + v.type}>
                                <div className="icon"><img src={handleIcon(v.type)} alt="Icono de alerta" /></div>
                                <div className="message"><h2>{v.message}</h2></div>
                                <div className="closeButton" onClick={(e) => { handleCloseAlert(e, v.id) }}><button>X</button></div>
                            </div>
                        </div>
                    )
                })

            }
        </>


    )


}

export default Alert;
