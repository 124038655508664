import React, { useEffect } from "react";
import Axios from "axios";
import { useNavigate, Link } from "react-router-dom";
import { UserContext } from "../../context/userProvider";
import "./nuestrosCursos.scss";
import imgheader from "../../assets/nuestrosCursos/courses.jpg"
import img1 from "../../assets/nuestrosCursos/courses2.jpg"
import img2 from "../../assets/nuestrosCursos/courses3.jpg"
import img3 from "../../assets/nuestrosCursos/courses4.jpg"
import test from "../../assets/nuestrosCursos/test.png"
import { dataDecrypt } from "../../utils/dataDecrypt";
const NuestrosCursos = () => {
  const navigate = useNavigate();
  const [user, setUser] = React.useContext(UserContext);
  var sessionStorageArray = []
  useEffect(() => {
    Axios.get(process.env.REACT_APP_API_URL + "isUserAuth/", {
      headers: {
        "x-access-token": localStorage.getItem("token"),
      },
    }).then((response) => {
      if (response.data.auth === false) {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
      } else {
        var userStorage = localStorage.getItem("user");
        var userJson = dataDecrypt(userStorage);
        setUser(userJson);

        var checkAuth = false;
        sessionStorageArray = dataDecrypt(localStorage.getItem("session"));
        sessionStorageArray.forEach((e) => {
          if (userJson.id === e.id) {
            checkAuth = true;
          }
        });
        if (checkAuth !== true) {
          navigate("/login");
        }
      }
    });
  }, []);
  return (
    <div className="oferta">
      <div className="oft_header">
        <div className="oft_header_img">
          <img src={imgheader} />
          <div></div>
        </div>
        <p className="oft_header_text">Nos adaptamos a tus necesidades</p>
        <p className="oft_header_title1">Nuestros</p>
        <p className="oft_header_title2">cursos</p>
      </div>
      <Link className="test" to={'/examenDeUbicacion'}>
        <div className="test_container_label">
          <img src={test} />
          <div className="test_title">
            <p>EXAMEN DE <br />UBICACION</p>
          </div>
        </div>
      </Link>

      <div className="paragraph">
        <h1 className="caption1">Diferentes niveles de conocimiento... diferentes cursos</h1>
        <p>¿Eres un estudiante de nivel básico, intermedio o avanzado? No hay problema.
          Ofrecemos cursos en distintos niveles para que encuentres el que mejor
          se adapte a ti. Si no sabes cuál es tu nivel, puedes realizar un examen
          de ubicación para determinarlo.
        </p>
      </div>
      <div className="paragraph">
        <h1 className="caption1">Clases básicas</h1>
        <h2 className="caption2">El mejor comienzo está aquí</h2>
        <p> Inscríbete en este nivel si tienes poco o nulo conocimiento del idioma. Nuestro
          objetivo como institución educativa es despertar la curiosidad de nuestros alumnos
          para permitir que aprendan a su propio ritmo. En este nivel se sientan las bases del idioma,
          se comienza desde lo elemental para que no se pierda ningún detalle de la gramática a la vez
          que se va poniendo en práctica lo que se aprende. "Lo que bien empieza,
          bien acaba". Contactanos para saber más o revisa nuestros próximos cursos.
        </p>
      </div>
      <div className="parallax" style={{ backgroundImage: `url(${img1})`, filter: "brightness(1.1) contrast(1.2)" }}></div>
      <div className="paragraph">
        <h1 className="caption1">Clases intermedias</h1>
        <h2 className="caption2">Una experiencia de aprendizaje excepcional</h2>
        <p> Inscríbete en este nivel práctico e innovador para participar en una experiencia de aprendizaje
          única. En este nivel se desarrollan habilidades auditivas mientras que se siguen adquiriendo
          gramática y vocabulario para expresar ideas y seguir desarrollando la habilidad del habla.
          Nuestros profesores calificados y capacitados son facilitadores en el curso, guían a los alumnos
          en su exploración y aprendizaje individual. Contactanos para conocer más o aplica un Examen de Ubicación.
        </p>
      </div>

      <div className="parallax" style={{ backgroundImage: `url(${img2})` }}></div>
      <div className="paragraph">
        <h1 className="caption1">Clases avanzadas</h1>
        <h2 className="caption2">Divertido y desafiante</h2>
        <p> Adéntrate a las clases con nuestro personal académico. En este nivel se ponen a prueba al máximo las habilidades
          del habla, de escritura y auditivas de nuestros estudiantes mediante clases dinámicas y 100% en Inglés que
          los ayudan a sacar todo su potencial. El esfuerzo es grande, pero la recompensa es mejor. Contáctanos para
          saber más o aplica un Examen de Ubicación.
        </p>
      </div>

      <div className="paragraph">
        <h1 className="caption1">Cursos para Niños</h1>
        <h2 className="caption2">Se divierten y aprenden</h2>
        <p>También tenemos cursos para los más pequeños. En este curso dinámico y divertido los niños van a aprender a su
          propio ritmo sin la presión de tener que presentar examenes.
          Nuestros instructores están capacitados para darles a cada uno de los niños la atención que necesitan.
          Contáctanos para saber más.
        </p>
      </div>
      <div className="img-section"><img src={img3} alt="" /></div>

    </div>
  );
};
export default NuestrosCursos;
