import React, { useState } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import './carousel.scss'
const CarouselImg = (props) => {
    const images = props.array;
    const [selectedIndex, setSelectedIndex] = useState(0);

    const handleThumbnailClick = (index) => {
        setSelectedIndex(index);
    };

    return (
        images !== undefined &&
        <div className="carousel-wrapper">
            <div className="carousel-container">
                <Carousel
                    showThumbs={false}
                    autoPlay
                    infiniteLoop
                    interval={5000}
                    className="main-carousel"
                    selectedItem={selectedIndex}
                    onChange={(index) => {setSelectedIndex(index); console.log(index)}}
                    animationHandler={'slide'}
                >
                    {images.map((url, index) => (
                        <div key={index}>
                            <img src={url} alt={`Slide ${index + 1}`} />
                        </div>
                    ))}
                </Carousel>
                <div style={{ color: props.colorTitle }} className="carousel-title">{props.title}</div> {/* Añadido para el título general */}
            </div>
            <div className="thumbnail-container">
                {images.map((url, index) => (
                    <img
                        key={index}
                        src={url}
                        alt={`Thumbnail ${index + 1}`}
                        onClick={() => handleThumbnailClick(index)}
                        className={`thumbnail ${index === selectedIndex ? 'active' : ''}`}
                    />
                ))}
            </div>
        </div>
    );
}
export default CarouselImg