import React, { useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import './confirmModal.scss'
const ConfirmModal = (props) =>{
    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 400,
        bgcolor: "background.paper",
        border: "2px solid #000",
        boxShadow: 24,
        pt: 2,
        px: 4,
        pb: 3,
      };
    return(
        <Modal
            open={props.open}
            onClose={props.onClose}
            aria-labelledby="child-modal-title"
            aria-describedby="child-modal-description"
          >
            <Box sx={{ ...style, width: 250 }}>
              <div className="modalConfirmContainer">
                <h4>¿Estás seguro de que quieres realizar esta acción?</h4>

                <div className="childButtons">
                  <button
                    id="cancelConfirmButton"
                    onClick={props.onClickCancel}
                    disabled={props.disabled}
                    className={props.className}
                  >
                    Cancelar
                  </button>
                  <button
                    id="confirmButton"
                    onClick={props.handleConfirm}
                    disabled={props.disabled}
                    className={props.className}
                  >
                    Aceptar
                  </button>
                </div>
                <div>
                  <span>{props.confirmMessage}</span>
                </div>
              </div>
            </Box>
          </Modal>
    )
}
export default ConfirmModal