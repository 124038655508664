import React from 'react';
import './contactBubble.scss';
import PhoneSVG from '../../components/icons/phone'; 
import MsnSVG from '../../components/icons/msn';
import { useState, useRef, useEffect } from 'react';

const onClickOutside = (element, callback) => {
  document.addEventListener('click', e => {
    if (element && !element.contains(e.target)) {
      callback();
    }
  });
};

const ContactBubble = () => {
  const [checked, setChecked] = useState(false);
  const bubbleRef = useRef(null);

  useEffect(() => {
    onClickOutside(bubbleRef.current, () => setChecked(false));
  }, [bubbleRef]);

  return (
    <div className="contact-bubble" ref={bubbleRef}>
      <div className="contain">
        <input
          type="checkbox"
          id="toggle"
          className="toggle-checkbox"
          checked={checked}
          onChange={() => setChecked(!checked)}
        />
        <div className="bubble sub-bubble call">
          <a href="tel:+526647019736">
            <PhoneSVG className="icon" />
          </a>
        </div>
        <div className="bubble sub-bubble message">
          <a href="http://m.me/ActiveEnglishTijuana" target="blank">
            <MsnSVG className="icon" />
          </a>
        </div>

        <label htmlFor="toggle" className="bubble main-bubble scroll-effect">Contáctanos</label>
      </div>
    </div>
  );
};

export default ContactBubble;
