import React, { createContext, useState} from 'react';


const UserProvider = ({ children }) => {

    const [user, setUser] = useState({});

   

    return (
        <UserContext.Provider value={[user, setUser]}>
            {children}
        </UserContext.Provider>
    );
    
};

export const UserContext = createContext();

export default UserProvider;