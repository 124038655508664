import React, { useEffect, useState } from "react";
import '../modalTeacherClass.scss'
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import ModalEditEvent from "../modalEditEvent/modalEditEvent";

const ModalDataReport = (props) => {
    const [orderBy, setOrderBy] = useState(1)
    const [openModalEditEvent, setOpenModalEditEvent] = useState(false)
    const [arrayWeeklyReport, setArrayWeeklyReport] = useState([])
    const [weekTitle, setWeekTitle] = useState("")
    const [selectedRow, setSelectedRow] = useState({});
    useEffect(() => {
        if (props.open === true && props.filterWeeklyReport.date1 !== "") {
            FilterArrayData(props.filterWeeklyReport.date1, props.filterWeeklyReport.date2, props.filterWeeklyReport.case)
        }
    }, [props.open, props.handleEndProcess])
    const ConvertDateFormat = (date) => {
        var convert = new Date(date),
            mnth = ("0" + (convert.getMonth() + 1)).slice(-2),
            day = ("0" + convert.getDate()).slice(-2);
        return [day, mnth, convert.getFullYear()].join("-")
    }
    function addZero(i) {
        if (i < 10) { i = "0" + i }
        return i;
    }
    const FilterArrayData = (date1, date2, sum_minus) => {

        var teacherName
        var unitName
        var bookName
        var converted1;
        var converted2;
        setArrayWeeklyReport([])
        switch (sum_minus) {
            case 0:
                setWeekTitle(`${date2} / ${date1}`)
                converted1 = new Date(date2);
                converted2 = new Date(date1);
                props.arrayDataWeeklyReport.filter(event => event.start >= converted1 && event.start <= converted2).map(data => {
                    var hourStart = data.start.getHours();
                    var minStart = addZero(data.start.getMinutes());
                    var hourEnd = data.end.getHours();
                    var minEnd = addZero(data.end.getMinutes());
                    var date = ConvertDateFormat(data.start)
                    var objectData = {}
                    props.teacherList.filter(t => t.id_teacher === data.id_teacher).map(filter => teacherName = filter.name)
                    props.bookList.filter(b => b.book_id === data.book_id).map(filter => bookName = filter.name)
                    props.unitList.filter(u => u.unit_id === data.unit_id).map(filter => unitName = filter.name)
                    let partName = ''
                    if (data.fk_unit_part === 1) {
                        partName = 'a'
                    }
                    if (data.fk_unit_part === 2) {
                        partName = 'b'
                    }
                    objectData = {
                        teacher: {
                            id: data.id_teacher,
                            name: teacherName
                        },
                        event_id: data.event_id,
                        qtyStudents: data.qtyStudents,
                        book: {
                            id: data.book_id,
                            name: bookName,
                            idUnit: data.unit_id,
                            unitName: unitName,
                            fk_unit_part: data.fk_unit_part,
                            unit_part: partName
                        },
                        hourStart: hourStart,
                        hourEnd: hourEnd,
                        generalTime: hourStart + ':' + minStart + " - " + hourEnd + ":" + minEnd,
                        date: date
                    }
                    setArrayWeeklyReport(arrayWeeklyReport => [...arrayWeeklyReport, objectData])

                })


                break;
            case 1:
                setWeekTitle(`${date1} / ${date2}`)
                converted1 = new Date(date1);
                converted2 = new Date(date2);
                props.arrayDataWeeklyReport.filter(event => event.start >= converted1 && event.start <= converted2).map(data => {
                    var hourStart = data.start.getHours();
                    var minStart = addZero(data.start.getMinutes());
                    var hourEnd = data.end.getHours();
                    var minEnd = addZero(data.end.getMinutes());
                    var date = ConvertDateFormat(data.start)
                    var objectData = {}

                    props.teacherList.filter(t => t.id_teacher === data.id_teacher).map(filter => teacherName = filter.name)
                    props.bookList.filter(b => b.book_id === data.book_id).map(filter => bookName = filter.name)
                    props.unitList.filter(u => u.unit_id === data.unit_id).map(filter => unitName = filter.name)
                    let partName = ''
                    if (data.fk_unit_part === 1) {
                        partName = 'a'
                    }
                    if (data.fk_unit_part === 2) {
                        partName = 'b'
                    }
                    objectData = {
                        teacher: {
                            id: data.id_teacher,
                            name: teacherName
                        },
                        event_id: data.event_id,
                        qtyStudents: data.qtyStudents,
                        book: {
                            id: data.book_id,
                            name: bookName,
                            idUnit: data.unit_id,
                            unitName: unitName,
                            fk_unit_part: data.fk_unit_part,
                            unit_part: partName
                        },
                        hourStart: hourStart,
                        hourEnd: hourEnd,
                        generalTime: hourStart + ':' + minStart + " - " + hourEnd + ":" + minEnd,
                        date: date,

                    }
                    setArrayWeeklyReport(arrayWeeklyReport => [...arrayWeeklyReport, objectData])

                })


                break;
            default:
                break;
        }
    }

    const headerTemplate = (data) => {
        var header;
        var teacherData;
        switch (orderBy) {
            case 1: header = data.teacher.name; teacherData = [data.teacher.email] + " / " + [data.teacher.phoneNumber]; break;
            case 2: header = data.generalTime; break;
            case 3: header = data.book.name; break;
            default: break;
        }

        return (
            <React.Fragment>
                <span className="span" style={{ justifyContent: 'left' }} > {header} <p>{(teacherData)}</p></span>
            </React.Fragment>)
    }

    const obtenerClaseEstilo = (fila) => {
        var classStyle = "";
        var bookColor = fila.book.name;

        switch (bookColor) {
            case "Yellow": classStyle = "yellowbook"; break;
            case "Red": classStyle = "redbook"; break;
            case "Blue": classStyle = "bluebook"; break;
            case "Green": classStyle = "greenbook"; break;
            case "Purple": classStyle = "purplebook"; break;
            case "Smile 1": classStyle = "s1book"; break;
            case "Smile 2": classStyle = "s2book"; break;
            case "Smile 3": classStyle = "s3book"; break;
            case "Smile 4": classStyle = "s4book"; break;
            case "Smile 5": classStyle = "s5book"; break;
            case "Smile 6": classStyle = "s6book"; break;
            case "Mega": classStyle = "megabook"; break;
            default: break;
        }
        if (fila === selectedRow) { classStyle = classStyle + " selected-row"; }

        return classStyle;
    };
    // //         // Column width is being assingned by the ClassName which has percents clasifides by this way:
    // //         // class tableCType: T1 => 5% / T2 => 10% / T3 => 13%  / T4 => 15% / T5 => 25% / T6 => 30%

    /////Edit Event
    const handleOpenModalEditEvent = () => {
        if (Object.keys(selectedRow).length !== 0) {
            setOpenModalEditEvent(true)
        }

    }

    const handleSwitchBody = (data, column, orderBy) => {
        switch (orderBy) {
            case 1:
                switch (column) {
                    case 2:
                        if (data.book.fk_unit_part === 0) {
                            return (
                                <div><span>{data.book.unitName}</span></div>
                            )
                        } else {
                            return (
                                <div><span>{data.book.unitName} {data.book.unit_part}</span></div>
                            )
                        }
                    default:
                        break;
                }
                break;
            case 2:
                switch (column) {

                    case 3:
                        if (data.book.fk_unit_part === 0) {
                            return (
                                <div><span>{data.book.unitName}</span></div>
                            )
                        } else {
                            return (
                                <div><span>{data.book.unitName} {data.book.unit_part}</span></div>
                            )
                        }
                    default:
                        break;
                }
                break;
            case 3:
                switch (column) {
                    case 2:

                        if (data.book.fk_unit_part === 0) {
                            return (
                                <div><span>{data.book.unitName}</span></div>
                            )
                        } else {
                            return (
                                <div><span>{data.book.unitName} {data.book.unit_part}</span></div>
                            )
                        }
                    default:
                        break;
                }
                break;
            default:
                break;
        }

    }
    return (

        <>
            <Modal
                open={props.open}
                onClose={props.onClose}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
                disableEnforceFocus
                disableAutoFocus
            >
                <Box className="box">
                    {/* CHILD MODAL */}
                    <div className="modalHeader">
                        <div className="reporttitile"><p className="paragraphTitle">Reporte Semanal {weekTitle}</p></div>
                        <div className="filter">
                            <label className="filterlabel">Orden</label>
                            <select value={orderBy} className="filterselect" onChange={(e) => { setOrderBy(parseInt(e.target.value)) }}>
                                <option value={1}>Maestro</option>
                                <option value={2}>Horario</option>
                                <option value={3}>Libro</option>
                            </select>
                        </div>
                    </div>
                    <div className='modalBody'>
                        {
                            orderBy === 1 &&
                            <DataTable
                                value={arrayWeeklyReport}
                                rowGroupMode="subheader"
                                groupRowsBy="teacher.name"
                                sortMode="single"
                                sortField="teacher.name"
                                rowGroupHeaderTemplate={headerTemplate}
                                sortOrder={1}

                                rowClassName={(rowData) => obtenerClaseEstilo(rowData)}
                                selectionMode="single"
                                selection={selectedRow}
                                onSelectionChange={(e) => setSelectedRow(e.value)}
                                onDoubleClick={handleOpenModalEditEvent}

                            >
                                <Column field="book.name" header="Libros" className="tableCtype4 td"></Column>
                                <Column field="book.unitName" header="Unidad" body={(data) => handleSwitchBody(data, 2, 1)} className="tableCtype4 td"></Column>
                                <Column field="generalTime" header="Horario" className="tableCtype4 td"></Column>
                                <Column field="date" header="Fecha" className="tableCtype5 td"></Column>
                                <Column field="qtyStudents" header="Asistencia" className="tableCtype4 td"></Column>
                            </DataTable>

                        }
                        {
                            orderBy === 2 &&
                            <DataTable
                                value={arrayWeeklyReport}
                                rowGroupMode="subheader"
                                groupRowsBy="generalTime"
                                sortMode="single"
                                sortField="generalTime"
                                rowGroupHeaderTemplate={headerTemplate}
                                sortOrder={1}

                                rowClassName={(rowData) => obtenerClaseEstilo(rowData)}
                                selectionMode="single"
                                selection={selectedRow}
                                onSelectionChange={(e) => setSelectedRow(e.value)}
                            >
                                <Column field="teacher.name" header="Maestro" className="tableCtype4 td"></Column>
                                <Column field="book.name" header="Libro" className="tableCtype4 td"></Column>
                                <Column field="book.unitName" header="Unidad" body={(data) => handleSwitchBody(data, 3, 2)} className="tableCtype4 td"></Column>
                                <Column field="date" header="Fecha" className="tableCtype5 td"></Column>
                                <Column field="qtyStudents" header="Asistencia" className="tableCtype4 td"></Column>
                            </DataTable>

                        }
                        {
                            orderBy === 3 &&
                            <DataTable
                                value={arrayWeeklyReport}
                                rowGroupMode="subheader"
                                groupRowsBy="book.name"
                                sortMode="single"
                                sortField="book.name"
                                rowGroupHeaderTemplate={headerTemplate}
                                sortOrder={1}

                                rowClassName={(rowData) => obtenerClaseEstilo(rowData)}
                                selectionMode="single"
                                selection={selectedRow}
                                onSelectionChange={(e) => setSelectedRow(e.value)}
                            >
                                <Column field="teacher.name" header="Maestro" className="tableCtype4 td"></Column>
                                <Column field="book.unitName" header="Unidad" body={(data) => handleSwitchBody(data, 2, 3)} className="tableCtype4 td"></Column>
                                <Column field="generalTime" header="Horario" className="tableCtype4 td"></Column>
                                <Column field="date" header="Fecha" className="tableCtype5 td"></Column>
                                <Column field="qtyStudents" header="Asistencia" className="tableCtype4 td"></Column>
                            </DataTable>

                        }
                    </div>
                </Box>
            </Modal>
            <ModalEditEvent
                open={openModalEditEvent}
                dataToEdit={selectedRow}
                onClickCancel={(e) => { setOpenModalEditEvent(false); props.onClickCancel(e); }}
                handleEndProcess={() => { setOpenModalEditEvent(false); props.handleEndProcess(); }}
            />
        </>

    )
}
export default ModalDataReport