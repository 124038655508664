import React, { useRef, useState, useEffect } from "react";
import "./homeTKids.scss";
import HeaderTeacherKids from "../../../components/headerTeacherKids/headerTeacherKids";
import PDF from "../../../components/pdf/pdf";
import ReactAudioPlayer from "react-audio-player";
import ReactPlayer from "react-player";
import doggy from '../../../assets/TeacherIcons/kidsImage/kidsbackground.png';
import pptImg from '../../../assets/TeacherIcons/powerpoint.png'
import Axios from "axios";
import { dataDecrypt } from "../../../utils/dataDecrypt";
import { UserContext } from "../../../context/userProvider";
import { useNavigate } from "react-router-dom";

const HomeTKids = () => {
    const [UrlToPlay, setUrlToPlay] = useState([])
    const [viewModeBook, setViewModeBook] = useState(false);
    const handleCloseFile = (fileToClose) => {
        setUrlToPlay(UrlToPlay.filter((a) => a.file !== fileToClose))
    }
    var sessionStorageArray = [];
    const [user, setUser] = React.useContext(UserContext);
    const navigate = useNavigate();


    const [isMobile, setIsMobile] = useState(false)

    //choose the screen size 
    const handleResize = () => {
        if (window.innerWidth < 769) {
            setIsMobile(true)
        } else {
            setIsMobile(false)
        }
    }

    // Set up the event listener and call handleResize initially
    useEffect(() => {
        handleResize(); // Call once on mount
        window.addEventListener("resize", handleResize);
        // Clean up the event listener on unmount
        return () => {
            window.removeEventListener("resize", handleResize);
        }
    }, []);

    useEffect(() => {
        if (!localStorage.getItem("user")) {
            localStorage.removeItem("token");
        }
        Axios.get(process.env.REACT_APP_API_URL + "isUserAuth/", {
            headers: {
                "x-access-token": localStorage.getItem("token"),
            },
        }).then((response) => {
            if (response.data.auth === false) {
                localStorage.removeItem("token");
                localStorage.removeItem("user");
                navigate("/login");
            } else {
                if (Object.keys(user).length === 0) {
                    var userStorage = localStorage.getItem("user");
                    var userJson = dataDecrypt(userStorage);
                    setUser(userJson);
                    var checkAuth = false;
                    sessionStorageArray = dataDecrypt(localStorage.getItem("session"));
                    sessionStorageArray.forEach((e) => {
                        if (userJson.id === e.id) {
                            checkAuth = true;
                        }
                    });
                    if (
                        (userJson.idPriv.toString() !==
                            process.env.REACT_APP_API_TEACHER_PRIVILEGE &&
                            userJson.idPriv.toString() !==
                            process.env.REACT_APP_API_SUPERADMIN_PRIVILEGE) ||
                        checkAuth !== true
                    ) {
                        navigate("/login");
                    }
                } else {
                    var checkAuth = false;
                    sessionStorageArray = dataDecrypt(localStorage.getItem("session"));
                    sessionStorageArray.forEach((e) => {
                        if (user.id === e.id) {
                            checkAuth = true;
                        }
                    });
                    if (
                        (user.idPriv.toString() !==
                            process.env.REACT_APP_API_TEACHER_PRIVILEGE &&
                            user.idPriv.toString() !==
                            process.env.REACT_APP_API_SUPERADMIN_PRIVILEGE) ||
                        checkAuth !== true
                    ) {
                        navigate("/login");
                    }
                }
            }
        });
    }, []);

    return (
        <>
            <div className="containerTKids">
                <img src={doggy} className="logo" />
                <HeaderTeacherKids
                    url={UrlToPlay}
                    setURL={setUrlToPlay}
                ></HeaderTeacherKids>
                <div className="mediaContainer">
                    <div className="mediaItems">
                        {UrlToPlay.length !== 0 &&
                            UrlToPlay.map((v, i) => {
                                switch (v.file) {
                                    case "PPT":
                                        return (
                                            <div key={i} className="presentationContainer">
                                                <div className="headerPPTPresentation">
                                                    <a href={v.googleURL} target="blank" className="headerImg">
                                                        <img src={pptImg} alt="ppt imagen" />
                                                    </a>
                                                    <button onClick={() => { handleCloseFile(v.file) }}>
                                                        <i className="material-icons">close</i>{" "}
                                                    </button>
                                                </div>
                                                <PDF file={v.url}></PDF>
                                            </div>
                                        )
                                    case "BOOK":
                                        return (
                                            <div key={i} className="pdfContainer">
                                                <div className="headerPdf">
                                                    <button onClick={() => { setViewModeBook(!viewModeBook) }}>
                                                        {
                                                            isMobile === false ?
                                                                viewModeBook === false ? <i className="material-icons">slideshow</i> :
                                                                    <i className="material-icons">picture_as_pdf</i>
                                                                : <></>
                                                        }
                                                    </button>
                                                    <button onClick={() => { handleCloseFile(v.file) }}>
                                                        <i className="material-icons">close</i>{" "}
                                                    </button>
                                                </div>
                                                {
                                                    viewModeBook === false && isMobile === false ?
                                                        <div className="pdfMain">
                                                            <iframe style={{ width: '100%', height: '100%' }} src={`${v.url}#toolbar=0`} />
                                                            <div style={{ position: 'absolute', top: 0, left: 0, width: '97%', height: '98%', background: 'transparent' }} />
                                                        </div>
                                                        :
                                                        <PDF file={v.url}></PDF>

                                                }
                                            </div>
                                        )
                                    case "LESSON PLAN":
                                        return (
                                            isMobile === false ?
                                                <div key={i} className="pdfContainer">
                                                    <div className="headerPdf">
                                                        <button onClick={() => { handleCloseFile(v.file) }}>
                                                            <i className="material-icons">close</i>{" "}
                                                        </button>
                                                    </div>

                                                    <div className="pdfMain">
                                                        <iframe style={{ width: '100%', height: '100%' }} src={`${v.url}#toolbar=0`} />
                                                        <div style={{ position: 'absolute', top: 0, left: 0, width: '97%', height: '98%', background: 'transparent' }} />
                                                    </div>
                                                </div>
                                                :
                                                <div key={i} className="pdfContainer">
                                                    <div className="headerPdf">
                                                        <button onClick={() => { handleCloseFile(v.file) }}>
                                                            <i className="material-icons">close</i>{" "}
                                                        </button>
                                                    </div>
                                                    <PDF file={v.url}></PDF>

                                                </div>
                                        )
                                    case "VIDEO":
                                        return (
                                            <div key={i}
                                                className="videoPlayerContainer"
                                            >
                                                <div className="headerVideoPlayer">
                                                    <button onClick={() => { handleCloseFile(v.file) }}>
                                                        <i className="material-icons">close</i>{" "}
                                                    </button>
                                                </div>
                                                <ReactPlayer width={"100%"} height={"auto"} url={v.url} controls={true} />
                                            </div>
                                        )

                                    case "EXTRA MATERIAL":
                                        return (
                                            isMobile === false ?
                                                <div key={i} className="pdfContainer">
                                                    <div className="headerPdf">
                                                        <button onClick={() => { handleCloseFile(v.file) }}>
                                                            <i className="material-icons">close</i>{" "}
                                                        </button>
                                                    </div>

                                                    <div className="pdfMain">
                                                        <iframe style={{ width: '100%', height: '100%' }} src={`${v.url}#toolbar=0`} />
                                                        <div style={{ position: 'absolute', top: 0, left: 0, width: '97%', height: '98%', background: 'transparent' }} />
                                                    </div>
                                                </div>
                                                :
                                                <div key={i} className="pdfContainer">
                                                    <div className="headerPdf">
                                                        <button onClick={() => { handleCloseFile(v.file) }}>
                                                            <i className="material-icons">close</i>{" "}
                                                        </button>
                                                    </div>
                                                    <PDF file={v.url}></PDF>

                                                </div>
                                        )


                                    default:

                                        break;
                                }
                                switch (v.format) {
                                    case "wav":
                                    case "wma":
                                    case "mp3":
                                        return (
                                            <div key={i} className="audioPlayerContainer">
                                                <div className="headerAudioPlayer">
                                                    <button onClick={() => { handleCloseFile(v.file) }}>
                                                        <i className="material-icons">close</i>{" "}
                                                    </button>
                                                </div>
                                                <ReactAudioPlayer src={v.url} autoPlay controls />
                                            </div>
                                        )

                                    default:
                                        break;
                                }


                            })
                        }
                    </div>
                </div>
            </div>
        </>
    );
};

export default HomeTKids;