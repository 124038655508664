import React, { useEffect } from "react";
import Axios from "axios";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../context/userProvider";
import img1 from "../../assets/online/online0.jpg";
import img2 from "../../assets/online/online1.png";
import img3 from "../../assets/online/online2.jpg";
import img4 from "../../assets/online/online3.jpg";
import img5 from "../../assets/online/online4.jpg";
import "./activeOnline.scss";
import { dataDecrypt } from "../../utils/dataDecrypt";
const ActiveOnline = () => {
  const navigate = useNavigate();
  const [user, setUser] = React.useContext(UserContext);
  var sessionStorageArray = []
  useEffect(() => {
    Axios.get(process.env.REACT_APP_API_URL + "isUserAuth/", {
      headers: {
        "x-access-token": localStorage.getItem("token"),
      },
    }).then((response) => {
      if (response.data.auth === false) {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
      } else {
        var userStorage = localStorage.getItem("user");
        var userJson = dataDecrypt(userStorage);
        setUser(userJson);

        var checkAuth = false;
        sessionStorageArray = dataDecrypt(localStorage.getItem("session"));
        sessionStorageArray.forEach((e) => {
          if (userJson.id === e.id) {
            checkAuth = true;
          }
        });
        if (checkAuth !== true) {
          navigate("/login");
        }
      }
    });
  }, []);
  return (

    <div className="cmContainer">
      <div
        className="cmPresentacion"
        style={{
          backgroundImage: `url(${img1})`,
        }}
      >
        <img src={img2} alt="#activeOnline" />
      </div>

      <div className="contentCMContainer">
        <div className="welcomeContainer">
          <div className="messageContainer1">
            <div className="titleContainer">
              <h1>Bienvenido a una nueva forma de aprender
              </h1>
            </div>
            <div className="bodyContainer">
              <span>
                Sabemos que lo de hoy es lo digital. Por eso implementamos un sistema de enseñanza 100% en línea. Todo el conocimiento en la palma de tu mano,
                vamos contigo a donde vayas, estamos contigo cuando quieras. Misma calidad, diferente plataforma.
              </span>
            </div>
          </div>
          <div className="imageContainer">
            <img src={img3} alt="image1" />
          </div>
        </div>
        <div className="experienceContainer">
          <div className="messageContainer2">
            <div className="titleContainer">
              <h1>Una experiencia online diferente
              </h1>
            </div>
            <div className="bodyContainer">
              <span>
                Creemos que una experiencia de aprendizaje online debe ser diferente, significativa y divertida, sin dejar de ser efectiva.
                Una experiencia de aprendizaje online de calidad debe ofrecerte los mismos beneficios que una clase presencial. Nuestra plataforma de aprendizaje 100%
                online garantiza el desarrollo de nuestros alumnos fuera del entorno virtual.

              </span>
            </div>
          </div>
          <div className="imageContainer">
            <img src={img4} alt="image1" />
          </div>
        </div>
        <div className="methodContainer">
          <div className="messageContainer3">

            <div className="titleContainer">
              <h1>Método
              </h1>
            </div>
            <div className="bodyContainer">
              <span>
                Trasladamos nuestro efectivo formato de clases 70% práctico que ya conoces a una plataforma digital, la práctica es lo primordial.
                <br />
                <br />
                <p>
                  *Clases 100% en vivo.
                  <br />
                  *Material didáctico completamente digital.
                  <br />
                  *Simple y cómodo.
                </p>
              </span>
            </div>
          </div>
          <div className="imageContainer">
            <img src={img5} alt="image1" />
          </div>
        </div>
      </div>
    </div>

  );
};
export default ActiveOnline;
