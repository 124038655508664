import React, { useEffect, useState, useRef } from "react";
import HeaderT from "../../../components/headerTeacher/header";
import { UserContext } from "../../../context/userProvider";
import Axios from "axios";
import { useNavigate } from "react-router-dom";
import "./home.scss";
import PDF from "../../../components/pdf/pdf";
import ReactAudioPlayer from "react-audio-player";
import ReactPlayer from "react-player";
import firebase from "firebase/compat/app";
import { storage } from "../../../firebase/index";
import { dataDecrypt } from "../../../utils/dataDecrypt";
import logo from '../../../assets/TeacherIcons/logo.png'
import pptImg from '../../../assets/TeacherIcons/powerpoint.png'
const HomeT = () => {
  const [user, setUser] = React.useContext(UserContext);
  const navigate = useNavigate();
  ////pdf variables
  const [viewModeBook, setViewModeBook] = useState(false);
  const [isMobile, setIsMobile] = useState(false)
  // diable buttons
  var sessionStorageArray = [];
  const [UrlToPlay, setUrlToPlay] = useState([])

  const iconName = [
    "book",
    "extraSongs",
    "audio",
    "plan",
    "powerpoint",
    "songs",
    "trainingVideos",
    "videos",
  ];
  var arrayIconContainer = [];
  const [iconState, setIconState] = useState([]);

  const countFolderMaterialInit = (folder) => {
    const storageRef = firebase.storage().ref();
    // [START storage_list_all]
    // Create a reference under which you want to list
    var listRef = storageRef.child(folder);

    // Find all the prefixes and items.
    listRef
      .listAll()
      .then((res) => {
        var subIndex = 0;
        const prueba = res.prefixes;
        arrayIconContainer = [];
        for (let i = 0; i < prueba.length; i++) {
          if (iconName[i] === undefined) {
            arrayIconContainer.push(iconName[subIndex]);
            subIndex++;
          } else {
            arrayIconContainer.push(iconName[i]);
          }
        }
        setIconState([...iconState, ...arrayIconContainer]);
      })
      .catch((error) => {
        // Uh-oh, an error occurred!
        console.log(error);
      });
    // [END storage_list_all]
  };

  useEffect(() => {
    if (!localStorage.getItem("user")) {
      localStorage.removeItem("token");
    }
    Axios.get(process.env.REACT_APP_API_URL + "isUserAuth/", {
      headers: {
        "x-access-token": localStorage.getItem("token"),
      },
    }).then((response) => {
      if (response.data.auth === false) {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        navigate("/login");
      } else {
        if (Object.keys(user).length === 0) {
          var userStorage = localStorage.getItem("user");
          var userJson = dataDecrypt(userStorage);
          setUser(userJson);
          var checkAuth = false;
          sessionStorageArray = dataDecrypt(localStorage.getItem("session"));
          sessionStorageArray.forEach((e) => {
            if (userJson.id === e.id) {
              checkAuth = true;
            }
          });
          if (
            (userJson.idPriv.toString() !==
              process.env.REACT_APP_API_TEACHER_PRIVILEGE &&
              userJson.idPriv.toString() !==
              process.env.REACT_APP_API_SUPERADMIN_PRIVILEGE) ||
            checkAuth !== true
          ) {
            navigate("/login");
          }
        } else {
          var checkAuth = false;
          sessionStorageArray = dataDecrypt(localStorage.getItem("session"));
          sessionStorageArray.forEach((e) => {
            if (user.id === e.id) {
              checkAuth = true;
            }
          });
          if (
            (user.idPriv.toString() !==
              process.env.REACT_APP_API_TEACHER_PRIVILEGE &&
              user.idPriv.toString() !==
              process.env.REACT_APP_API_SUPERADMIN_PRIVILEGE) ||
            checkAuth !== true
          ) {
            navigate("/login");
          }
        }
      }
    });
  }, []);

  useEffect(() => {
    countFolderMaterialInit("MaterialV2/YELLOW LEVEL/");
  }, []);

  const handleCloseFile = (fileToClose) => {
    console.log(UrlToPlay)
    setUrlToPlay(UrlToPlay.filter((a) => a.nameFile !== fileToClose))
  }



  //choose the screen size 
  const handleResize = () => {
    if (window.innerWidth < 769) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }
  }

  // Set up the event listener and call handleResize initially
  useEffect(() => {
    handleResize(); // Call once on mount
    window.addEventListener("resize", handleResize);
    // Clean up the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    }
  }, []);
  // finally you can render components conditionally if isMobile is True or False 
  const disableRightClick = (e) => {
    e.preventDefault();
  };
  return (

    <div className="containerT" onContextMenu={disableRightClick}>
      <HeaderT
        url={UrlToPlay}
        setURL={setUrlToPlay}
      ></HeaderT>
      <div className="mediaContainer">
        <img className="logo" src={logo} />
        <div className="mediaItems">
          {UrlToPlay.length !== 0 &&
            UrlToPlay.map((v, i) => {
              switch (v.nameFile) {
                case "PPT":
                  return (
                    <div key={i} className="presentationContainer">
                      <div className="headerPPTPresentation">
                        <a href={v.googleURL} target="blank" className="headerImg">
                          <img src={pptImg} alt="ppt imagen" />
                        </a>
                        <button onClick={() => { handleCloseFile(v.nameFile) }}>
                          <i className="material-icons">close</i>{" "}
                        </button>
                      </div>
                      <PDF file={v.url}></PDF>
                    </div>
                  )
                case "BOOK":
                  return (
                    <div key={i} className="pdfContainer">
                      <div className="headerPdf">
                        <button onClick={() => { setViewModeBook(!viewModeBook) }}>
                          {
                            isMobile === false ?
                              viewModeBook === false ? <i className="material-icons">slideshow</i> :
                                <i className="material-icons">picture_as_pdf</i>
                              :
                              <></>
                          }
                        </button>
                        <button onClick={() => { handleCloseFile(v.nameFile) }}>
                          <i className="material-icons">close</i>{" "}
                        </button>
                      </div>
                      {
                        viewModeBook === false && isMobile === false ?
                          <div className="pdfMain">
                            <iframe style={{ width: '100%', height: '100%' }} src={`${v.url}#toolbar=0`} />
                            <div style={{ position: 'absolute', top: 0, left: 0, width: '97%', height: '98%', background: 'transparent' }} />
                          </div>
                          :
                          <PDF file={v.url}></PDF>

                      }

                    </div>
                  )
                case "LESSON PLAN":
                  return (
                    isMobile === false ?
                      <div key={i} className="pdfContainer">
                        <div className="headerPdf">
                          <button onClick={() => { handleCloseFile(v.nameFile) }}>
                            <i className="material-icons">close</i>{" "}
                          </button>
                        </div>
                        <div className="pdfMain">
                          <iframe style={{ width: '100%', height: '100%' }} src={`${v.url}#toolbar=0`} />
                          <div style={{ position: 'absolute', top: 0, left: 0, width: '97%', height: '98%', background: 'transparent'}} />
                        </div>
                      </div>
                      :
                      <div key={i} className="pdfContainer">
                        <div className="headerPdf">
                          <button onClick={() => { handleCloseFile(v.nameFile) }}>
                            <i className="material-icons">close</i>{" "}
                          </button>
                        </div>
                        <PDF file={v.url}></PDF>

                      </div>
                  )
                case "VIDEO":
                  return (
                    <div key={i}
                      className="videoPlayerContainer"
                    >
                      <div className="headerVideoPlayer">
                        <button onClick={() => { handleCloseFile(v.nameFile) }}>
                          <i className="material-icons">close</i>{" "}
                        </button>
                      </div>
                      <ReactPlayer width={"100%"} height={"auto"} url={v.url} controls={true} />
                    </div>
                  )
                case "EXTRA MATERIAL":
                  return (
                    isMobile === false ?
                      <div key={i} className="pdfContainer">
                        <div className="headerPdf">
                          <button onClick={() => { handleCloseFile(v.nameFile) }}>
                            <i className="material-icons">close</i>{" "}
                          </button>
                        </div>

                        <div className="pdfMain">
                          <iframe style={{ width: '100%', height: '100%' }} src={`${v.url}#toolbar=0`} />
                          <div style={{ position: 'absolute', top: 0, left: 0, width: '97%', height: '98%', background: 'transparent'}} />
                        </div>
                      </div>
                      :
                      <div key={i} className="pdfContainer">
                        <div className="headerPdf">
                          <button onClick={() => { handleCloseFile(v.nameFile) }}>
                            <i className="material-icons">close</i>{" "}
                          </button>
                        </div>
                        <PDF file={v.url}></PDF>

                      </div>
                  )
                default:

                  break;
              }
              switch (v.format) {
                case "wav":
                case "wma":
                case "mp3":
                  return (
                    <div key={i} className="audioPlayerContainer">
                      <div className="headerAudioPlayer">
                        <button onClick={() => { handleCloseFile(v.nameFile) }}>
                          <i className="material-icons">close</i>{" "}
                        </button>
                      </div>
                      <ReactAudioPlayer src={v.url} autoPlay controls />
                    </div>
                  )

                default:
                  break;
              }


            })
          }
        </div>
      </div>
    </div>

  );
};
export default HomeT;
