import React, { useState } from "react";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import './pdf.scss'
const PDF = (props) => {
  //////
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  function changePage(offSet) {
    setPageNumber((prevPageNumber) => prevPageNumber + offSet);

  }

  function changePageSubmit(e) {
    if (e.key === "Enter") {
      if (e.target.value !== "") {
        var pageNumberInput = parseInt(e.target.value)
        if (pageNumberInput === 0 || pageNumberInput > numPages) {
          setPageNumber((prevPageNumber) => prevPageNumber)
        } else {
          setPageNumber(0 + pageNumberInput)
        }
        e.target.value = ""

      }

    }
  }

  function changePageBack() {
    changePage(-1);
  }

  function changePageNext() {
    changePage(+1);
  }

  return (
    <>
      <div className={"pdfMedia"} style={props.style}>
        <Document file={props.file} onLoadSuccess={onDocumentLoadSuccess}>
          <Page height={props.height} pageNumber={pageNumber} />
        </Document>
        <div className="labelAndButtonContainer">
          <p>
            {" "}
            Page {<input className="pageNumberInput" type="number" placeholder={pageNumber} onKeyUp={e => { changePageSubmit(e) }} />} of {numPages}
          </p>
          <div className="buttonContainer">
            {pageNumber > 1 && (
              <button onClick={changePageBack}>Previous Page</button>
            )}
            {pageNumber < numPages && (
              <button onClick={changePageNext}>Next Page</button>
            )}

          </div>
        </div>
      </div>
    </>
  );
};

export default PDF;
