import Axios  from "axios";

export function emailVerification(email){
  try {
    return new Promise((resolve, reject)=>{
      Axios.post(`${process.env.REACT_APP_API_URL}sendEmailLinkAgain`,{
        email:email
      }).then((response) => {
          resolve(response.data)
      })

    })

  } catch (error) {
    console.log("Ocurrio Un Error", error);
  }
};