import React, { useState, useEffect } from "react";
import Axios from "axios";
import "./login.scss";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../context/userProvider";
import { dataEncrpt } from "../../utils/dataEncrypt";
import { dataDecrypt } from "../../utils/dataDecrypt";
import { emailVerification } from "../../utils/Apis/emailVerification";
import InputPassword from "../../components/inputPassword/inputPassword";

const Login = () => {
  const [ip, setIP] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorM, setErrorM] = useState("");
  const [countTries, setCountTries] = useState(0);
  const [user, setUser] = React.useContext(UserContext);
  const [isMaintenedSession, setIsMaintenedSession] = useState(false);

  var userData = [];
  var sessionLocal = [];
  var objUserPriv = {};
  var objUser = {};
  var objSession = {};

  const navigate = useNavigate();

  Axios.defaults.withCredentials = true;

  const sendToAuthentication = (id, email, phone, isFromLoginButton) => {
    objSession = {
      id: id,
      email: email,
      phone: phone,
      isFromLoginButton: isFromLoginButton
    };
    navigate("/authentication", { state: objSession });
  };


  const login = (event) => {
    event.preventDefault();
    Axios.post(process.env.REACT_APP_API_URL + "login/", {
      email: email,
      password: password,
      sessionMaintened: isMaintenedSession,
    }).then((response) => {
      if (!response.data.auth) {
        if (response.data.status === 203) {
          emailVerification(response.data.result.email);
          navigate("/emailVerification", {
            state: { email: response.data.result.email },
          });
        } else {
          setErrorM(response.data.message);
          if (email) {
            setCountTries(countTries + 1);
          }
          setTimeout(() => {
            setErrorM("");
          }, 5000);
        }
      } else {
        localStorage.setItem("token", response.data.token);
        objUser = response.data.result;
        Axios.post(process.env.REACT_APP_API_URL + "privilege/", {
          idUser: response.data.result.id,
        }).then((response) => {
          if (response.data.privilege === false) {
            var idPriv = { idPriv: 0 };
            objUserPriv = response.data;
            objUserPriv = Object.assign(objUserPriv, idPriv);
            var objGeneral = Object.assign(objUser, objUserPriv);
            setUser(objGeneral);
            localStorage.setItem("user", dataEncrpt(objGeneral));
            if (!localStorage.getItem("session")) {
              localStorage.setItem("session", dataEncrpt([]));
              sendToAuthentication(
                objGeneral.id,
                objGeneral.email,
                objGeneral.phoneNumber,
                true
              );
            } else {
              objSession = {
                id: objGeneral.id,
                email: objGeneral.email,
                phone: objGeneral.phoneNumber,
              };
              var exist = 0;
              sessionLocal = dataDecrypt(localStorage.getItem("session"));
              sessionLocal.forEach((e) => {
                if (objSession.id === e.id) {
                  exist = exist + 1;
                }
              });
              if (exist < 1) {
                sendToAuthentication(
                  objGeneral.id,
                  objGeneral.email,
                  objGeneral.phoneNumber,
                  true
                );
              } else {
                setErrorM(
                  "Tu usuario ya existe, pero no hemos asignado ningún privilegio a tu cuenta"
                );
                setTimeout(() => {
                  setErrorM("");
                  navigate("/");
                }, 2500);
              }
            }
          } else {
            var privilege = { privilege: response.data.privilege };
            objUserPriv = response.data.result;
            objUserPriv = Object.assign(objUserPriv, privilege);
            var objGeneral = Object.assign(objUser, objUserPriv);
            setUser(objGeneral);
            localStorage.setItem("user", dataEncrpt(objGeneral));
            if (!localStorage.getItem("session")) {
              localStorage.setItem("session", dataEncrpt([]));
              sendToAuthentication(
                objGeneral.id,
                objGeneral.email,
                objGeneral.phoneNumber,
                true
              );
            } else {
              objSession = {
                id: objGeneral.id,
                email: objGeneral.email,
                phone: objGeneral.phoneNumber,
              };
              var exist = 0;
              sessionLocal = dataDecrypt(localStorage.getItem("session"));
              sessionLocal.forEach((e) => {
                if (objSession.id === e.id) {
                  exist = exist + 1;
                }
              });
              if (exist < 1) {
                sendToAuthentication(
                  objGeneral.id,
                  objGeneral.email,
                  objGeneral.phoneNumber,
                  true
                );
              } else {
                if (
                  objGeneral.idPriv ==
                  process.env.REACT_APP_API_TEACHER_PRIVILEGE
                ) {
                  navigate("/HomeT");
                } else if (
                  objGeneral.idPriv ==
                  process.env.REACT_APP_API_SUPERADMIN_PRIVILEGE
                ) {
                  navigate("/superAdmin");
                }
              }
            }
          }
        });
      }
    });
  };

  useEffect(() => {
    Axios.get(process.env.REACT_APP_API_URL + "isUserAuth/", {
      headers: {
        "x-access-token": localStorage.getItem("token"),
      },
    }).then((response) => {
      if (response.data.auth === false) {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
      } else {
        var userObj = dataDecrypt(localStorage.user);
        Axios.post(process.env.REACT_APP_API_URL + "privilege/", {
          idUser: userObj.id,
        }).then((response) => {
          userData = dataDecrypt(localStorage.getItem("user"));
          sessionLocal = dataDecrypt(localStorage.getItem("session"));
          var checkAuth = false;
          if (response.data.privilege === true) {
            sessionLocal.forEach((e) => {
              if (userData.id === e.id) {
                checkAuth = true;
              }
            });
            if (
              userObj.idPriv == process.env.REACT_APP_API_TEACHER_PRIVILEGE &&
              checkAuth === true
            ) {
              navigate("/HomeT");
            } else if (
              userObj.idPriv ==
              process.env.REACT_APP_API_SUPERADMIN_PRIVILEGE &&
              checkAuth === true
            ) {
              navigate("/superAdmin");
            } else if (checkAuth !== true) {
              sendToAuthentication(
                userData.id,
                userData.email,
                userData.phoneNumber,
                false
              );
            }
          } else {
            sessionLocal.forEach((e) => {
              if (userData.id === e.id) {
                checkAuth = true;
              }
            });
            if (checkAuth !== true) {
              sendToAuthentication(
                userData.id,
                userData.email,
                userData.phoneNumber,
                false
              );
            } else {
              navigate("/");
            }
          }
        });
      }
    });
  }, []);

  return (
    <>
      <div className="LoginContainer">
        <form className="login" onSubmit={login}>
          <h1>Iniciar Sesión</h1>
          <div className="formContainer">
            <input
              type="text"
              placeholder="Email"
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
            <InputPassword
              placeholder="Contraseña"
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
            <span>
              <input
                type="checkbox"
                checked={isMaintenedSession}
                onChange={() => {
                  setIsMaintenedSession(!isMaintenedSession);
                }}
              />{" "}
              Mantener sesión iniciada
            </span>
            <div className="bottomForm">
              <span>
                ¿No tienes una cuenta? <a href="/register">Registrate aquí.</a>
              </span>
              <a
                className={
                  countTries > 2 ? "forgottenPassword" : "hideForgottenPassword"
                }
                href="/recoverAccount"
              >
                ¿Has olvidado tu contraseña?.
              </a>
              <div className="button">
                <input
                  className="buttonLogin"
                  type={"submit"}
                  value={"Iniciar Sesión"}
                ></input>
              </div>
            </div>
            {errorM.length > 0 ? (
              <div className="errorMessage">
                <span id="spanErrorM">{errorM}</span>
              </div>
            ) : (
              <></>
            )}
          </div>
        </form>
      </div>
    </>
  );
};
export default Login;
