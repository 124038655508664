import React, { useState, useEffect } from "react";
import Axios from "axios";
import "./register.scss";
import { useNavigate } from "react-router-dom";
import InputPassword from "../../components/inputPassword/inputPassword";
const Register = () => {
  var arrayRegister = [];

  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [genre, setGenre] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [registerMessage, setRegisterMessage] = useState("");
  const navigate = useNavigate();

  const [classN, setClassN] = useState("");
  const [classLN, setClassLN] = useState("");
  const [classG, setClassG] = useState("");
  const [classE, setClassE] = useState("");
  const [classP, setClassP] = useState("");
  const [classPhoneN, setClassPhoneN] = useState("");

  function checkEmpty() {
    arrayRegister.length = 0;
    if (name === "" || name === null) {
      arrayRegister.push("registerName");
      setClassN("required");
    } else {
      setClassN("");
    }
    if (lastName === "" || lastName === null) {
      arrayRegister.push("registerLastName");
      setClassLN("required");
    } else {
      setClassLN("");
    }
    if (genre === "" || genre === null) {
      arrayRegister.push("registerGenre");
      setClassG("required");
    } else {
      setClassG("");
    }
    if (email === "" || email === null) {
      arrayRegister.push("registerEmail");
      setClassE("required");
    } else {
      setClassE("");
    }
    if (phoneNumber === "" || phoneNumber === null) {
      arrayRegister.push("registerPhoneNumber");
      setClassPhoneN("required");
    } else {
      setClassPhoneN("");
    }
    if (password === "" || password === null) {
      arrayRegister.push("registerPassword");
      setClassP("required");
    } else {
      setClassP("");
    }
  }

  const register = () => {
    checkEmpty();
    if (arrayRegister.length > 0) {
    } else {
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
        setClassE("");
        Axios.post(process.env.REACT_APP_API_URL + "register/", {
          name: name,
          lastname: lastName,
          fk_genre: genre,
          email: email,
          phoneNumber: phoneNumber,
          password: password,
        }).then((res) => {
          if (res.data.status === 200) {
            setRegisterMessage(res.data.message);
            document.getElementById("registerMessage").style.display = "flex";
            document.getElementById("spanRegisterMessage").style.color = "rgb(19, 56, 189)";
            document.getElementById("registerButton").disabled = true;
            setTimeout(() => {
              navigate("/login");
            }, 3000);
          } else if (res.data.status === 202) {
            setRegisterMessage(res.data.message);
            document.getElementById("registerMessage").style.display = "flex";
          }
        });
      } else {
        setRegisterMessage("Invalid Email Format");
        document.getElementById("registerMessage").style.display = "flex";
        document.getElementById("registerMessage").style.backgroundColor =
          "red";
        setClassE("required");
      }
    }
  };

  const handleGenre = (e) => {
    setGenre(e.target.value);
  };

  useEffect(() => {
    Axios.get(process.env.REACT_APP_API_URL + "isUserAuth/", {
      headers: {
        "x-access-token": localStorage.getItem("token"),
      },
    }).then((response) => {
      if (response.data.auth === false) {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
      } else {
        navigate(-1);
      }
    });
  }, []);

  function maxLenghtFunction(e) {
    if (e !== undefined && e !== null) {
      if (e.length > 10) {
        setPhoneNumber(e.slice(0, 10));
      }
    }
  }

  return (
    <>
      <div className="registerContainer">
        <div className="register">
          <h1>Registrarse</h1>
          <div className="formContainer">
            <input
              maxLength={"50"}
              id={"registerName"}
              className={classN}
              type="text"
              placeholder="Nombre"
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
            <input
              maxLength={"100"}
              id={"registerLastName"}
              className={classLN}
              type="text"
              placeholder="Apellido"
              onChange={(e) => {
                setLastName(e.target.value);
              }}
            />
            <select
              id={"registerGenre"}
              className={classG}
              value={genre}
              onChange={handleGenre}
            >
              <option value="">Seleccionar género</option>
              <option value="1">Hombre</option>
              <option value="2">Mujer</option>
            </select>
            <input
              maxLength={"100"}
              id={"registerEmail"}
              className={classE}
              type="text"
              placeholder="Email"
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
            <input
              id={"registerPhoneNumber"}
              className={classPhoneN}
              type="number"
              placeholder="Teléfono"
              onInput={maxLenghtFunction(phoneNumber)}
              onChange={(e) => {
                setPhoneNumber(e.target.value);
              }}
            />
            <InputPassword
              id={"registerPassword"}
              className={classP}
              placeholder="Contraseña"
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />

            <div className="bottomForm">
              <div
                id="registerMessage"
                className="registerMessage"
                style={{ display: "none" }}
              >
                <span id="spanRegisterMessage">{registerMessage}</span>
              </div>
              <span>
                ¿Ya tienes una cuenta? <a href="/login">Inicia sesión</a>
              </span>
              <div id="registerButton" className="button">
                <button onClick={register}>Registrar</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Register;
