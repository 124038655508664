import React, { useEffect } from "react";
import Axios from "axios";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../context/userProvider";
import "./examenUbicacion.scss";
import img1 from "../../assets/examenUbicacion/test1.jpg";
import { dataDecrypt } from "../../utils/dataDecrypt";
const ExamenUbicacion = () => {
  const navigate = useNavigate();
  const [user, setUser] = React.useContext(UserContext);
  var sessionStorageArray = []
  useEffect(() => {
    Axios.get(process.env.REACT_APP_API_URL + "isUserAuth/", {
      headers: {
        "x-access-token": localStorage.getItem("token"),
      },
    }).then((response) => {
      if (response.data.auth === false) {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
      } else {
        var userStorage = localStorage.getItem("user");
        var userJson = dataDecrypt(userStorage);
        setUser(userJson);

        var checkAuth = false;
        sessionStorageArray = dataDecrypt(localStorage.getItem("session"));
        sessionStorageArray.forEach((e) => {
          if (userJson.id === e.id) {
            checkAuth = true;
          }
        });
        if (checkAuth !== true) {
          navigate("/login");
        }
      }
    });
  }, []);
  return (
    <div className="test_container">
      <div className="tst_header">
        <div className="tst_header_img">
          <img src={img1} />
          <div></div>
        </div>
        <span className="tst_header_text">Nos adaptamos a tus necesidades</span>
        <h3 className="tst_header_title1">Exámen de</h3>
        <h1 className="tst_header_title2">Ubicación</h1>
      </div>

      <div className="paragraph">
        <h1 className="caption1">Exámen de Ubicación</h1>
        <span>
          ¿Ya tienes noción del idioma y no te gustaría empezar desde un curso básico? No tienes que hacerlo, mejor aplica un Examen de Ubicación totalmente gratis que te indica en el nivel exacto para ti.
        </span>
        <div className="buttonContainer">
          <div className="button">
            <a href="https://docs.google.com/forms/d/e/1FAIpQLSd5aq9yRv3BYYzThRZD6pp_68AUh2K1DOeWo9BNTHHLpH669g/viewform"
              target={"_blank"}>Aplicar exámen</a>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ExamenUbicacion;
