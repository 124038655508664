import React, { useEffect, useState } from "react"
import Map, { Marker, Room } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css'
import "./maps.scss"
const Maps = () => {
  const [viewPort, setViewPort] = useState({
    longitude: -116.872046,
    latitude: 32.513675,
    zoom: 16
  })
  let size = 40;
  return (
    <>
      <Map
        mapboxAccessToken={process.env.REACT_APP_ACCESS_TOKEN_MAP}
        initialViewState={viewPort}
        style={{ height: "40vh" }}
        mapStyle="mapbox://styles/mapbox/streets-v12">
        <Marker
          latitude={32.51275}
          longitude={-116.87746}
          style={{width:"50px", color:"red"}}
        >
            <i className="material-icons">location_on</i>
        </Marker>
        <Marker
          latitude={32.51350}
          longitude={-116.869410}
          style={{width:"50px", color:"red"}}
        >
            <i className="material-icons">location_on</i>
        </Marker>
      </Map>
    </>
  )
}

export default Maps;