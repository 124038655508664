import React from "react";
import "./error404.scss";
import error404Img from '../../assets/error_404.jpg'
const Error404 = () => {
  return (
    <>
      <div className="error404Container">
        <img src={error404Img} alt="" />
      </div>
    </>
  );
};
export default Error404;