import React, { useEffect, useState } from "react";
import Axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import "./authentication.scss";
import EmailAuthentication from "../emailAuthentication/emailAuthentication";
import PhoneAuthentication from "../phoneAuthentication/phoneAuthentication";
import ChooseAuthentication from "../chooseAuthentication/chooseAuthentication";
const Authentication = () => {
  const [option, setOption] = useState(0);
  const [id, setId] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [isFromLoginButton, setIsFromLoginButton] = useState(false);
  var status;
  var message;
  const [objUser, setObjUser] = useState({
    id: id,
    email: email,
    isFromLoginButton: isFromLoginButton,
    status: status,
    message: message,
  });

  const navigate = useNavigate();
  const location = useLocation();
  var render;

  useEffect(() => {
    if (!location.state) {
      navigate(-1);
    } else {
      setEmail(location.state.email);
      setId(location.state.id);
      setPhone(location.state.phone);
      setIsFromLoginButton(location.state.isFromLoginButton);
    }
  }, []);

  const sendEmailAuthentication = (id, email, isFromLoginButton) => {
    Axios.post(process.env.REACT_APP_API_URL + "authenticationRequestEmail/", {
      idUser: id,
      email: email,
      isFromLoginButton: isFromLoginButton,
    }).then((response) => {
      status = response.data.status;
      message = response.data.message;
      setObjUser({
        id: id,
        email: email,
        isFromLoginButton: isFromLoginButton,
        status: status,
        message: message,
      });
    });
  };

  const sendPhoneAuthentication = (id, phone, isFromLoginButton) => {
    Axios.post(process.env.REACT_APP_API_URL + "authenticationRequestPhone/", {
      idUser: id,
      phone: phone,
      isFromLoginButton: isFromLoginButton,
    }).then((response) => {
      status = response.data.status;
      message = response.data.message;
      setObjUser({
        id: id,
        phone: phone,
        isFromLoginButton: isFromLoginButton,
        status: status,
        message: message,
      });
    });
  };

  const handleOption = (option) => {
    setOption(option);
    switch (option) {
      case 1:
        sendEmailAuthentication(id, email, isFromLoginButton);
        break;
      case 2:
        sendPhoneAuthentication(id, phone, isFromLoginButton);
        break;
      default:
        break;
    }
  };
  const handleBack = (option) => {
    setOption(option);
  };

  const RenderAuthentication = () => {
    switch (option) {
      case 0:
        render = <ChooseAuthentication handleOption={handleOption} />;
        break;
      case 1:
        render = <EmailAuthentication handleBack={handleBack} emailUser={objUser} />;
        break;
      case 2:
        render = <PhoneAuthentication handleBack={handleBack} phoneUser={objUser}/>;
        break;

      default:
        break;
    }
    return render;
  };
  return (
    <>
      <div className="authenticationContainer">
        <RenderAuthentication />
      </div>
    </>
  );
};

export default Authentication;
