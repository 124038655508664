import React from "react";
import "./avisoPrivacidad.scss";
import privacy from "../../assets/avisoPrivacidad/privacy.webp"
const AvisoPrivacidad = () => {
  return (
    <>
      <div className="privContainer">
        <section
          className="parallaxSection"
          style={{ backgroundImage: `url(${privacy})` }}
        >
          <div className="snTitleContainer">
            <h3>Aviso de</h3>
            <h1>Privacidad</h1>
          </div>
        </section>

        <div className="privTextContainer">
          <div className="txtContain">
            <h3>¿Quiénes somos?</h3>
            <p>
              Be Active For Life, mejor conocido como Active English School, con domicilio en calle Blvd.
              Cucapah 22371, colonia Villa Fontana, ciudad Tijuana, municipio o delegación Tijuana, C.P. 22206,
              en la entidad de Baja California, país México, y portal de internet www.activeforlife.com.mx,
              es el responsable del uso y protección de sus datos personales, y al respecto le informamos lo siguiente:

            </p>
            <h3>¿Para qué fines utilizaremos sus datos personales?</h3>
            <p>
              En Active English School, utilizaremos sus datos personales para las siguientes finalidades que son
              necesarias para el servicio que solicita:
            </p>
            <ul className="listNumber">
              <li>
                <span>Proveer los servicios educativos: </span>Registro y administración de los estudiantes en los cursos y programas
                educativos ofrecidos por la escuela.
              </li>
              <li>
                <span>Comunicación: </span>Informar sobre cambios en los cursos, horarios, profesores, y cualquier otra información
                relevante para la adecuada prestación del servicio educativo.
              </li>
              <li>
                <span>Evaluación y seguimiento:</span>Realización de evaluaciones académicas, seguimiento del progreso de los estudiantes
                y entrega de calificaciones y certificados.
              </li>
              <li>
                <span>Facturación y pagos: </span>Generación de facturas, gestión de pagos y seguimiento de deudas.
              </li>
              <li>
                <span>Atención a solicitudes:</span>Responder a sus solicitudes de información, dudas, quejas y comentarios.
              </li>
              <li>
                <span>Cumplimiento legal: </span>Cumplir con obligaciones legales y reglamentarias aplicables a la institución educativa.
              </li>
            </ul>
            <p>
              Además, utilizaremos sus datos personales para las siguientes finalidades secundarias que no son necesarias para el servicio
              solicitado, pero que nos permiten y facilitan brindarle una mejor atención:
            </p>
            <ul className="listNumber">
              <li>
                <span>Marketing y publicidad: </span>Enviar promociones, ofertas y noticias relacionadas con los servicios educativos que ofrecemos.
              </li>
              <li>
                <span>Encuestas y análisis:  </span>Realizar encuestas de satisfacción y análisis estadísticos sobre el desempeño de la escuela y sus programas educativos.
              </li>
              <li>
                <span>Eventos y actividades: </span>Informar sobre eventos, actividades extracurriculares, y programas adicionales que puedan
                ser de interés para los estudiantes y sus familias.
              </li>
            </ul>
            <p>
              En caso de que no desee que sus datos personales sean tratados para las finalidades secundarias, usted puede comunicarlo a través
              de los medios de contacto proporcionados en este aviso de privacidad.
            </p>

            <h3>¿Qué datos personales utilizaremos para estos fines?</h3>
            <p>
              Le informamos que sus datos personales pueden ser compartidos con terceros dentro y fuera del país, en los siguientes casos:
            </p>
            <ul className="listDots">
              <li>	Con autoridades competentes en cumplimiento de obligaciones legales</li>
              <li>	Con proveedores de servicios que nos asistan en la operación y administración de nuestra escuela.</li>
              <li>	Con entidades financieras para procesar pagos y cobros.</li>
            </ul>

            <h3>¿Cómo puede acceder, rectificar o cancelar sus datos personales, u oponerse a su uso?</h3>
            <p>
              Usted tiene derecho a conocer qué datos personales tenemos de usted, para qué los utilizamos y las condiciones
              del uso que les damos (Acceso). Asimismo, es su derecho solicitar la corrección de su información personal en
              caso de que esté desactualizada, sea inexacta o incompleta (Rectificación); que la eliminemos de nuestros registros
              o bases de datos cuando considere que la misma no está siendo utilizada adecuadamente (Cancelación); así como oponerse
              al uso de sus datos personales para fines específicos (Oposición). Estos derechos se conocen como derechos ARCO.
            </p>
            <p>
              Para el ejercicio de cualquiera de los derechos ARCO, usted deberá presentar la solicitud respectiva a través del siguiente medio: [Correo Electrónico].
            </p>
            <p>
              Para conocer el procedimiento y requisitos para el ejercicio de los derechos ARCO, usted podrá ponerse en contacto
              con nuestro Departamento de Datos Personales en el correo electrónico [Correo Electrónico] o visitar nuestra página de internet <a href="www.activeforlife.com.mx/contacto">www.activeforlife.com.mx/contacto</a>.
            </p>


            <h3>¿Cómo puede revocar su consentimiento para el uso de sus datos personales?</h3>
            <p>
              Usted puede revocar el consentimiento que, en su caso, nos haya otorgado para el tratamiento de sus datos personales.
              Sin embargo, es importante que tenga en cuenta que no en todos los casos podremos atender su solicitud o concluir el
              uso de forma inmediata, ya que es posible que por alguna obligación legal requiramos seguir tratando sus datos personales. Asimismo, usted deberá
              considerar que, para ciertos fines, la revocación de su consentimiento implicará que no podamos seguir prestando el servicio que nos solicitó, o la conclusión de su relación con nosotros.
            </p>
            <p>
              Para revocar su consentimiento deberá presentar su solicitud a través del siguiente medio: [Correo Electrónico].
            </p>


            <h3>¿Cómo puede conocer los cambios en este aviso de privacidad?</h3>
            <p>
              El presente aviso de privacidad puede sufrir modificaciones, cambios o actualizaciones derivadas de nuevos requerimientos legales;
              de nuestras propias necesidades por los servicios que ofrecemos; de nuestras prácticas de privacidad; de cambios en nuestro modelo de negocio, o por otras causas.
            </p>
            <p>
              Nos comprometemos a mantenerlo informado sobre los cambios que pueda sufrir el presente aviso de privacidad, a través de: <a href="www.activeforlife.com.mx/avisodeprivacidad">www.activeforlife.com.mx/avisodeprivacidad</a>
            </p>
            <p>
              Fecha de última actualización: 09 de julio de 2024
            </p>
          </div>

        </div>

      </div>
    </>
  );
};

export default AvisoPrivacidad;
