import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Axios from "axios";
import authenticationImage from "../../../assets/authenticationImage.png";
import "./chooseAuthentication.scss";

const ChooseAuthentication = ({ handleOption }) => {
  const [option, setOption] = useState(0);
  const [message, setMessage] = useState("");
  
  const handleSubmit = (event) => {
    event.preventDefault();
    if (option !== 0) {
      handleOption(option);
    } else {
      setMessage("Selecciona un método de autenticación, por favor.");
      setTimeout(() => {
        setMessage("");
      }, 5000);
    }
  };

  const handleChangeOption = (event) => {
    setOption(parseInt(event.target.value));
  };

  return (
    <>
      <div className="chooseAuthenticationContainer">
        <div className="chooseAuthenticationContainerBorder">
          <h1>Autentica tu cuenta</h1>
          <img src={authenticationImage} alt="" />
          <p>
            Para iniciar sesión es necesario autenticar tu cuenta. Por favor,
            Selecciona que método de autenticación que desea utilizar:
          </p>

          <form
            className="chooseAuthenticationSelectOption"
            onSubmit={handleSubmit}
          >
            <div className="optionInput" onChange={handleChangeOption}>
              <div>
                <input type="radio" name="selectOption" value={1} />{" "}
                Autenticación por correo electrónico.
              </div>
              <div>
                <input type="radio" name="selectOption" value={2} />{" "}
                Autenticación por SMS.
              </div>
            </div>
            <input className="buttonSubmit" type={"submit"} value={"Aceptar"} />
          </form>
          <p className="message">{message}</p>
        </div>
      </div>
    </>
  );
};

export default ChooseAuthentication;
