import React, { useState } from "react";
import "./inputPassword.scss";
const InputPassword = (props) => {
  const [isVisible, setIsVisible] = useState(false);
  const handleVisibility = () => {
    setIsVisible(!isVisible);
  };
  return (
    <div className={`inputPassword ${props.className}`}>
      <input
        id={props.id}
        type={!isVisible ? "password" : "text"}
        placeholder={props.placeholder}
        onChange={props.onChange}
      />
      <i className="material-icons visibility" onClick={handleVisibility}>
        {!isVisible ? "visibility_off" : "visibility"}
      </i>
    </div>
  );
};
export default InputPassword;
