import React, { useEffect, useState } from "react";
import emailIMG from "../../../assets/email.png";
import Axios from "axios";
import "./emailAuthentication.scss";
import { dataDecrypt } from "../../../utils/dataDecrypt";
import { dataEncrpt } from "../../../utils/dataEncrypt";
import { useNavigate } from "react-router-dom";
const EmailAuthentication = (props) => {
  const [id, setId] = useState("");
  const [email, setEmail] = useState("");
  const [send, setSend] = useState(0);
  const [code, setCode] = useState("");
  const [message, setMessage] = useState("");
  var sessionStorageArray = [];
  var objSessionStorage = {};
  const navigate = useNavigate();

  useEffect(() => {
    setId(props.emailUser.id);
    setEmail(props.emailUser.email);
  }, []);
  const handleSubmit = (event) => {
    event.preventDefault();
    var exist = 0;
    Axios.patch(
      process.env.REACT_APP_API_URL + "authenticationEmailCodeProcess/",
      {
        email: email,
        idUser: id,
        code: code,
      }
    ).then((response) => {
      let status = response.data.status;
      if (status === 200) {
        sessionStorageArray = dataDecrypt(localStorage.getItem("session"));
        objSessionStorage = { id: id, email: email };
        sessionStorageArray.forEach((e) => {
          if (objSessionStorage.id === e.id) {
            exist = exist + 1;
          }
        });
        if (exist === 0) {
          sessionStorageArray.push(objSessionStorage);
          localStorage.setItem("session", dataEncrpt(sessionStorageArray));
        }
        checkRequestAuthentication();
        navigate("/login");
      } else if (status === 201) {
        setMessage(response.data.message);
        setTimeout(() => {
          setMessage("");
        }, 3000);
      }
    });
  };
  const checkRequestAuthentication = () => {
    Axios.post(process.env.REACT_APP_API_URL + "checkRequestAuthentication/", {
      idUser: id,
    }).then((response) => {
      deleteRequestAuthentication(
        response.data.emailAuth,
        response.data.phoneAuth
      );
    });
  };
  const deleteRequestAuthentication = (idEmail, idPhone) => {
    Axios.delete(
      process.env.REACT_APP_API_URL +
        `deleteRequestAuthentication/${idEmail}/${idPhone}`
    ).then((response) => {});
  };

  const sendEmailAgain = () => {
    Axios.post(process.env.REACT_APP_API_URL + "authenticationRequestEmail/", {
      email: email,
      idUser: id,
      isFromLoginButton: true,
    }).then((response) => {
      setSend(1);
      setMessage(response.data.message);
      setTimeout(() => {
        setMessage("");
      }, 3000);
      setTimeout(() => {
        setSend(0);
      }, 20000);
    });
  };

  const handleBackOption = () => {
    props.handleBack(0);
  };
  return (
    <>
      <div className="emailAuthenticationContainer">
        <div className="emailAuthenticationContainerBorder">
          <h1>Autentica tu cuenta</h1>
          <img src={emailIMG} alt="" />
          <p>
            Hemos enviado a tu correo electrónico el link de activación de
            cuenta para que puedas utilizarla, El link expirara en 15 minutos.
          </p>
          <p>
            Da{" "}
            {send === 0 ? (
              <a onClick={sendEmailAgain}>click aquí</a>
            ) : (
              "click aquí"
            )}{" "}
            si no recibiste el link en tu correo electrónico
          </p>
          <form
            className="emailAuthenticationCodeInput"
            onSubmit={handleSubmit}
          >
            <input
              className="codeInput"
              type="number"
              placeholder="Ingresa el código aquí"
              value={code}
              onChange={(e) => {
                setCode(e.target.value);
              }}
            />
            <div className="buttonSubmitContainer">
              <input
                className="buttonSubmit"
                onClick={handleBackOption}
                type={"button"}
                value={"Regresar"}
              />
              <input
                className="buttonSubmit"
                type={"submit"}
                value={"Aceptar"}
              />
            </div>
          </form>
          <p className="message">{message}</p>
        </div>
      </div>
    </>
  );
};

export default EmailAuthentication;
