import React, { useEffect, useRef, useState } from "react";
import Axios from "axios";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../context/userProvider";
import "./home.scss";
import { dataDecrypt } from "../../utils/dataDecrypt";
import home_ce from '../../assets/home/home_ce.png'
import school_io from "../../assets/home/home_io.jpeg";
import home_flex from "../../assets/home/home_flex.jpg";
import home_ct from "../../assets/home/home_ct.jpg";
import CardInfo from "../../components/cardInfo/cardInfo";

const Home = () => {
  const navigate = useNavigate();
  const [user, setUser] = React.useContext(UserContext);
  var sessionStorageArray = [];


  useEffect(() => {
    Axios.get(process.env.REACT_APP_API_URL + "isUserAuth/", {
      headers: {
        "x-access-token": localStorage.getItem("token"),
      },
    }).then((response) => {
      if (response.data.auth === false) {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
      } else {
        var userStorage = localStorage.getItem("user");
        var userJson = dataDecrypt(userStorage);
        setUser(userJson);

        var checkAuth = false;
        sessionStorageArray = dataDecrypt(localStorage.getItem("session"));
        sessionStorageArray.forEach((e) => {
          if (userJson.id === e.id) {
            checkAuth = true;
          }
        });
        if (checkAuth !== true) {
          navigate("/login");
        }
      }
    });
  }, []);


  return (
    <>
      <div className="homeContainer">
        <div className="homeTitleContainer">
          <div className="homeTitle">
            <h1>Be Active</h1>
            <h3>For Life</h3>
          </div>
        </div>
        <div className="contentHome">
          
          <CardInfo
            src={home_ce}
            title={"Comprometidos con la excelencia"}
            body={"Nos comprometemos a brindar experiencias educativas de excelencia que promuevan el desarrollo integral de nuestros alumnos dentro y fuera del aula."}
            to={"./sobreNosotros"}
          />
          <CardInfo
            src={school_io}
            title={"Instalaciones optimas"}
            body={"Nos esforzamos para que tu experiencia de aprendizaje sea en un entorno cómodo. Por este motivo, nuestras instalaciones cuentan con equipamiento moderno para brindarte el ambiente ideal."}
            to={"./instalaciones"}
          />
          <CardInfo
            src={home_flex}
            title={"Flexibilidad"}
            body={"Nuestra institución ofrece clases presenciales y en línea, y una amplia variedad de horarios que brindan la flexibilidad de elegir la modalidad que mejor se adapte a sus necesidades."}
            to={"./clasesEnLinea"}
          />
          <CardInfo
            src={home_ct}
            title={"Clases para todos"}
            body={"Nuestra oferta educativa incluye clases dirigidas tanto a adultos como a niños, garantizando así una atención integral a todas las edades y niveles de aprendizaje."}
            to={"./ofertaEducativa"}
          />
        </div> 
      </div>
    </>
  );
};
export default Home;
