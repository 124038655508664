import React, { useRef, useEffect, useState } from 'react';
import './headerTeacherKids.scss'
import firebase from "firebase/compat/app";
import back from '../../assets/TeacherIcons/kidsImage/backArrow.png'
const HeaderTeacherKids = (props) => {
    const [selectedItem, setSelectedItem] = useState(null);
    const [isChecked, setIsChecked] = useState(0);
    const [arrayMaterialTeacher, setArrayMaterialTeacher] = useState([])
    const [arrayToGetMaterial, setArrayToGetMaterial] = useState([])
    const [indexToRender, setIndexToRender] = useState({})
    const [isUnitSelected, setIsUnitSelected] = useState(false)
    const [stateDisabledButton, setStateDisabledButton] = useState(false)
    const [buttonSelected, setButtonSelected] = useState({
        book: null,
        unit: null,
    })
    const initialized = useRef(false)

    const handleItemClick = (index) => {
        setSelectedItem(index);
        setIsChecked(!isChecked);
    }
    const fireBfolderColors = [
        'SMILE 1',
        'SMILE 2',
        'SMILE 3',
        'SMILE 4',
        'SMILE 5',
        'SMILE 6',
        'MEGA'
    ]
    const poligonPoints = [
        {
            name: 'smile1',
            poligon1: '0,0 0,100 100,80 100,0',
            poligon2: '3,8 3,92 97,72 97,8'
        },
        {
            name: 'smile2',
            poligon1: '0,20 0,80 100,100 100,0',
            poligon2: '3,28 3,72 97,92 97,8'
        },
        {
            name: 'smile3',
            poligon1: '0,0 0,100 100,80 100,20',
            poligon2: '3,8 3,92 97,72 97,28'
        },
        {
            name: 'smile4',
            poligon1: '0,20 0,80 100,100 100,0',
            poligon2: '3,28 3,72 97,92 97,8'
        },
        {
            name: 'smile5',
            poligon1: '0,0 0,100 100,80 100,20',
            poligon2: '3,8 3,92 97,72 97,28'
        },
        {
            name: 'smile6',
            poligon1: '0,20 0,80 100,100 100,0',
            poligon2: '3,28 3,72 97,92 97,8'
        },
        {
            name: 'mega',
            poligon1: '0,0 0,100 100,100 100,20',
            poligon2: '3,8 3,92 97,92 97,28'
        },
    ]
    const iconToShow = [
        {
            name: 'book',
            img: '3845824'
        },
        {
            name: 'lessonPlan',
            img: '3845895'
        },
        {
            name: 'ppt',
            img: '3845862'
        },
        {
            name: 'extraMaterial',
            img: '3845893'
        },
    ]
    // Effect to list folders when the component mounts
    useEffect(() => {
        listFolder()
    }, [])
    // Function to list folders in Firebase Storage
    function listFolder() {
        if (!initialized.current) {

            initialized.current = true
            if (arrayMaterialTeacher.length === 0) {

                const storageRef = firebase.storage().ref();
                // [START storage_list_all]
                // Create a reference under which you want to list
                let i = 0
                let arrayMain = []
                // Create an object for each color level and add it to arrayMain
                fireBfolderColors.forEach((v) => {
                    var folderName = fireBfolderColors[i]
                    let objMain = {
                        name: folderName,
                        unitFolders: []
                    }

                    arrayMain.push(objMain)
                    i++
                })
                // Iterate over arrayMain to get folders and files for each color level
                for (let i = 0; i < arrayMain.length; i++) {
                    let arrayFolder = []
                    const b = fireBfolderColors[i]
                    var listRef = storageRef.child(`MaterialV2 Kids/${b}`);
                    // Find all the prefixes and items.
                    listRef
                        .listAll()
                        .then((res) => {
                            res.prefixes.forEach((folderRef) => {
                                var nFolder = folderRef.name;
                                let subObj = {
                                    name: nFolder,
                                    folderItems: [

                                    ]
                                }
                                if (nFolder !== "BOOK") {
                                    arrayFolder.push(subObj)
                                }
                            });
                            arrayMain[i].unitFolders = arrayFolder
                        })
                        .catch((error) => {
                            // Uh-oh, an error occurred!
                            console.log(error); // Handle errors in data retrieval
                        });
                    // [END storage_list_all]
                }
                setArrayMaterialTeacher(arrayMain) // Update state with retrieved folders and files
            }
        }

    }

    const handleUnitSelected = (book, unit, indexBook, indexUnit) => {
        setButtonSelected({
            book: indexBook,
            unit: indexUnit
        })
        setIsUnitSelected(true)
        var array = arrayMaterialTeacher
        setStateDisabledButton(true)
        if (array[indexBook].unitFolders[indexUnit].folderItems.length === 0) {
            const storageRef = firebase.storage().ref();
            var listBook = storageRef.child(`MaterialV2 Kids/${book}/BOOK`);
            listBook
                .listAll()
                .then((res) => {
                    res.items.forEach((v, i) => {
                        let arrayItems = []
                        let urlObj = {}
                        v.getDownloadURL().then((url) => {
                            let typeFile = url.split(".").pop();
                            let format = typeFile.split('?');
                            urlObj = {
                                file: 'BOOK',
                                name: v.name,
                                url: url,
                                format: format[0]
                            }
                            arrayItems.push(urlObj)
                        })
                        const obj = {
                            name: 'BOOK',
                            items: arrayItems

                        }
                        array[indexBook].unitFolders[indexUnit].folderItems.push(obj)
                    })
                })
                .catch((error) => {
                    // Uh-oh, an error occurred!
                    console.log(error); // Handle errors in data retrieval
                });
            // [END storage_list_all]
            // Find all the prefixes and items.
            var listRef = storageRef.child(`MaterialV2 Kids/${book}/${unit}`);
            listRef
                .listAll()
                .then((res) => {
                    res.prefixes.forEach((folderRef) => {
                        const nameFolder = folderRef.name
                        var listUnit = storageRef.child(`MaterialV2 Kids/${book}/${unit}/${nameFolder}`);
                        listUnit
                            .listAll()
                            .then((resItems) => {
                                let arrayItems = []
                                let urlObj = {}
                                let urlObjTxt = {}
                                let arrayTxt = []
                                if (nameFolder === 'PPT') {
                                    resItems.items.forEach((txtRef) => {
                                        setTimeout(() => {
                                            let nameFile = txtRef.name
                                            let typeFile = nameFile.split(".").pop();
                                            txtRef.getDownloadURL().then((url) => {
                                                if (typeFile === 'txt') {
                                                    fetch(url).then((r) => {
                                                        r.text().then((linkGoogle) => {
                                                            urlObjTxt = {
                                                                file: nameFolder,
                                                                name: nameFile,
                                                                url: url,
                                                                format: typeFile
                                                            }
                                                            urlObjTxt.googleURL = linkGoogle

                                                            arrayTxt.push(urlObjTxt)
                                                        });
                                                    });

                                                }

                                            });
                                        }, 100);
                                    })
                                }
                                resItems.items.forEach((itemRef) => {
                                    if (nameFolder === 'PPT') {
                                        let nameFile = itemRef.name
                                        let typeFile = nameFile.split(".").pop();
                                        itemRef.getDownloadURL().then((url) => {
                                            setTimeout(() => {
                                                if (typeFile === 'pdf') {
                                                    let nombreSinExt = nameFile.replace(/\.[^.]*$/, '');
                                                    if (nombreSinExt === unit) {
                                                        const objURLTXT = arrayTxt.find((a) => a.name === 'URL.txt')
                                                        urlObj = {
                                                            file: nameFolder,
                                                            name: nameFile,
                                                            url: url,
                                                            format: typeFile,
                                                            googleURL: objURLTXT.googleURL
                                                        }
                                                        arrayItems.push(urlObj)
                                                    } else {
                                                        const objURLTXT = arrayTxt.find((a) => a.name === `${nombreSinExt}.txt`)
                                                        urlObj = {
                                                            file: nameFolder,
                                                            name: nameFile,
                                                            url: url,
                                                            format: typeFile,
                                                            googleURL: objURLTXT.googleURL
                                                        }
                                                        arrayItems.push(urlObj)
                                                    }
                                                }
                                            }, 800);
                                        });

                                    } else {
                                        itemRef.getDownloadURL().then((url) => {
                                            let typeFile = url.split(".").pop();
                                            let format = typeFile.split('?');
                                            urlObj = {
                                                file: nameFolder,
                                                name: itemRef.name,
                                                url: url,
                                                format: format[0]
                                            }
                                            arrayItems.push(urlObj)
                                        })
                                    }
                                })
                                const obj = {
                                    name: nameFolder,
                                    items: arrayItems
                                }
                                array[indexBook].unitFolders[indexUnit].folderItems.push(obj)

                            })
                            .catch((error) => {
                                console.log(error);
                            })

                    });
                    setTimeout(() => {
                        setTimeout(() => {
                            array[indexBook].unitFolders[indexUnit].folderItems.sort((a, b) => String(a.name).localeCompare(String(b.name)));
                            // Paso 1: Extraer el segundo elemento
                            let secondElement = array[indexBook].unitFolders[indexUnit].folderItems[1];
                            // Paso 2: Eliminar el segundo elemento del arreglo
                            array[indexBook].unitFolders[indexUnit].folderItems.splice(1, 1);
                            // Paso 3: Añadir el elemento extraído al final del arreglo
                            array[indexBook].unitFolders[indexUnit].folderItems.push(secondElement);
                            setArrayToGetMaterial(array)
                            setIndexToRender({
                                book: indexBook,
                                unit: indexUnit
                            })
                            setStateDisabledButton(false)
                        }, 900);

                    }, 1100)
                })
                .catch((error) => {
                    // Uh-oh, an error occurred!
                    console.log(error); // Handle errors in data retrieval
                });
            // [END storage_list_all]

        } else {

            setTimeout(() => {
                setIndexToRender({
                    book: indexBook,
                    unit: indexUnit
                })
                setStateDisabledButton(false)
            }, 500);
        }

    }
    const handleItemSelected = (obj) => {
        let objItem = obj
        if (objItem.format === "pdf") {
            if (props.url.length > 0) {
                props.setURL(props.url.filter((a) => a.file !== objItem.file))
            }
            if (objItem.file === "LESSON PLAN" || objItem.file === "BOOK" || objItem.file === "EXTRA MATERIAL") {
                props.setURL(props.url.filter((a) => a.file !== "BOOK" && a.file !== "LESSON PLAN" && a.file !== "EXTRA MATERIAL"))
            }
        }
        props.setURL((url) => [...url, objItem])
    }

    const handleOpenBook = (index) => {
        const objBook = arrayToGetMaterial[indexToRender.book].unitFolders[indexToRender.unit].folderItems[index].items[0]
        handleItemSelected(objBook)
    }
    return (
        <>
            <div className="kids_side_left">
                <ul className='kids_nav'>
                    {// Map and render retrieved folders and files
                        arrayMaterialTeacher.length !== 0 &&
                        arrayMaterialTeacher.map((cl, cil) => {
                            return (
                                <li key={cil} className={`kids_level${cil + 1} ${selectedItem !== cil + 1 && selectedItem !== null && isChecked ? 'disabled' : ''}`}>
                                    <a href='#' className='svg_box'>
                                        <svg preserveAspectRatio="none" viewBox="0 0 100 100">
                                            <polygon points={poligonPoints[cil].poligon1} />
                                            <polygon points={poligonPoints[cil].poligon2} />
                                        </svg>
                                        <div className='svg_text'><p>{cl.name}</p></div>
                                        <img className='svg_img' src={require(`../../assets/TeacherIcons/kidsImage/${cil + 1}.png`)} />
                                        <input type='checkbox' className='svg_check' onClick={() => handleItemClick(cil + 1)} />
                                        <img className="back_arrow" src={back} />
                                    </a>
                                    <div className='dropdown_unit_content'>
                                        <ul className='units_dropdown_ul'>
                                            {
                                                arrayMaterialTeacher[cil].name === 'MEGA' ?
                                                    arrayMaterialTeacher[cil].unitFolders.map((v, i) => {
                                                        let unit = i + 1
                                                        let numero = Math.ceil(unit / 2);
                                                        return (

                                                            <button disabled={stateDisabledButton} key={i} className={`units_dropdown_li ${buttonSelected.unit === i && buttonSelected.book === cil ? 'selected' : ''}`} onClick={() => handleUnitSelected(cl.name, v.name, cil, i)}>
                                                                {
                                                                    unit % 2 === 0 ?
                                                                        numero + ' B'
                                                                        :
                                                                        numero + ' A'
                                                                }
                                                            </button>
                                                        )
                                                    }
                                                    )
                                                    :
                                                    arrayMaterialTeacher.length !== 0 &&
                                                    arrayMaterialTeacher[cil].unitFolders.map((v, i) => {
                                                        return (
                                                            <button disabled={stateDisabledButton} key={i} className={`units_dropdown_li ${buttonSelected.unit === i && buttonSelected.book === cil ? 'selected' : ''}`} onClick={() => handleUnitSelected(cl.name, v.name, cil, i)}>
                                                                {i + 1}
                                                            </button>
                                                        )
                                                    }
                                                    )
                                            }

                                        </ul>
                                    </div>
                                </li>
                            )
                        })}
                </ul>
            </div>
            <div className="kids_side_right">
                <div className='material_menu'>
                    {
                        arrayToGetMaterial.length !== 0 &&
                        arrayToGetMaterial[indexToRender.book].unitFolders[indexToRender.unit].folderItems.map((e, i) => {
                            return (
                                e.name === 'BOOK' ?
                                    <div key={i} className={isUnitSelected ? `submenu sub${i + 1}` : 'submenu_disabled'}>
                                        <div className={`material_section${i + 1}`} onClick={() => { handleOpenBook(i) }}>
                                            <img src={`https://cdn-icons-png.flaticon.com/512/3845/${iconToShow[i].img}.png`} />
                                        </div>
                                    </div>
                                    :
                                    <div key={i} className={isUnitSelected ? `submenu sub${i + 1}` : 'submenu_disabled'}>
                                        <div className={`material_section${i + 1} material_section`}>

                                            <img src={iconToShow[i] !== undefined ? `https://cdn-icons-png.flaticon.com/512/3845/${iconToShow[i].img}.png` : `https://cdn-icons-png.flaticon.com/512/3845/3845783.png`} />
                                            <div className='material_book'>
                                                {
                                                    arrayToGetMaterial.length !== 0 &&
                                                    arrayToGetMaterial[indexToRender.book].unitFolders[indexToRender.unit].folderItems[i].items.map((v, item) => {
                                                        let typeFile = v.name.split(".").pop();
                                                        if (e.name !== 'PPT') {
                                                            return (
                                                                <li onClick={() => { handleItemSelected(v) }} key={item}>{v.name}</li>
                                                            )

                                                        } else {
                                                            if (typeFile !== 'txt') {
                                                                return (
                                                                    <li onClick={() => { handleItemSelected(v) }} key={item}>{v.name}</li>
                                                                )

                                                            }
                                                        }

                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                            )
                        })
                    }
                </div>

            </div>
        </>
    );
};

export default HeaderTeacherKids;
