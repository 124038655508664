import React, { useEffect, useState } from "react";
import "./functionButton.scss";
import trash from "../../assets/functionButtonIcons/trash.png";
import classlist from "../../assets/functionButtonIcons/classlist.png";
import copy from "../../assets/functionButtonIcons/copy.png";
import email from "../../assets/functionButtonIcons/email.png";
import groups from "../../assets/functionButtonIcons/groups.png";
import GroupsTable from "../../assets/functionButtonIcons/GroupsTable.png";
import table from "../../assets/functionButtonIcons/table.png";
import tabs from "../../assets/functionButtonIcons/tabs.png";
import eventadd from "../../assets/functionButtonIcons/eventadd.png";
import createR from "../../assets/functionButtonIcons/createReport.png";
const FunctionButton = (props) => {
    const [functionClass, setFunctionClass]=useState("divFunctionButton hoverButton");

    useEffect (()=>{
        if (props.hover ?? "") {
            hover();
        }
        
    },[])
    function hover() {        
        if (props.hover === "none") {
            setFunctionClass("divFunctionButton");
        } 
    }
    function iconButton(typeIcon) {
        switch (typeIcon) {
            case "trash": return trash; 
            case "classlist": return classlist; 
            case "copy": return copy; 
            case "email": return email; 
            case "groups": return groups; 
            case "groupstable": return GroupsTable; 
            case "table": return table; 
            case "tabs": return tabs; 
            case "eventadd": return eventadd; 
            case "createWReport": return createR; 
            default: break;
        }
    }

    return (
        <>
            <div className={functionClass}>
                <button className={"functionButton " + props.className +" "+ props.background} onClick={props.onClick} disabled={props.disabled}>
                    <div className="buttonIcon"><img src={iconButton(props.icon)} /></div>
                    <div className="buttonText"><p>{props.text}</p></div>
                </button>
            </div>

        </>
    );
};

export default FunctionButton;