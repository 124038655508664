import React, { useState, useEffect, useContext } from "react";
import Axios from "axios";
import { useNavigate, Link, useLocation } from "react-router-dom";
import "./header.scss";
import img1 from "../../assets/activeHeaderLogo.png";
import { UserContext } from "../../context/userProvider";
import { dataDecrypt } from "../../utils/dataDecrypt";

const Header = (props) => {
  const location = useLocation();
  // normal header
  const [userContext, setUserContext] = useContext(UserContext);
  const [menuOpen, setMenuOpen] = useState(false);
  const [privilege, setPrivilege] = useState(0)

  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      if (!localStorage.getItem('user') && !localStorage.getItem('token')) {
        setUserContext({})
      }
    }, 100);
  }, [location]);

  const handleSignOut = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    setUserContext({});
    navigate("/login");
  }

  const options = (userPrivilege) => {
    let stringPriv = userPrivilege.toString()
    switch (stringPriv) {
      case process.env.REACT_APP_API_SUPERADMIN_PRIVILEGE:
        return (
          <>
            <li className="navItem colorfull navISuper">
              <a href="/proximosCursos" className="navLinks">Próximos Cursos</a>
            </li>
            <li className="navItem navISuper">
              <a href="/" className="navLinks">Inicio</a>
            </li>
            <li className="navItem navISuper">
              <p href="" className="navLinks">Conócenos</p>
              <div className="dropdown">
                <ul>
                  <li><a href="/sobreNosotros">Sobre nosotros</a></li>
                  <li><a href="/nuestroEquipo">Nuestro equipo</a></li>
                  <li><a href="/instalaciones">Instalaciones</a></li>
                </ul>
              </div>
            </li>
            <li className="navItem navISuper">
              <p href="" className="navLinks">Académico</p>
              <div className="dropdown">
                <ul>
                  <li><a href="/ofertaEducativa">Oferta educativa</a></li>
                  <li><a href="/metodoDeEnsenanza">Método de enseñanza</a></li>
                  <li><a href="/clasesEnLinea">Clases en línea</a></li>
                  <li><a href="/examenDeUbicacion">Examen de ubicación</a></li>
                </ul>
              </div>
            </li>
            <li className="navItem navISuper">
              <a href="/contacto" className="navLinks">Contáctanos</a>
            </li>
            <li className="navItem navISuper">
              <Link className="navLinks">Super Admin</Link>
              <div className="dropdown">
                <ul>
                  <li><a href="./superAdmin">Administrar Usuarios</a></li>
                  <li><a href="./HomeT">M.Adultos</a></li>
                  <li><a href="./HomeTKids">M.Niños</a></li>
                  <li><a onClick={handleSignOut}>Cerrar Sesión</a></li>
                </ul>
              </div>
            </li>
          </>
        );

      case process.env.REACT_APP_API_TEACHER_PRIVILEGE:
        return (
          <>
            <li className="navItem colorfull navITeacher">
              <a href="/proximosCursos" className="navLinks">Próximos Cursos</a>
            </li>
            <li className="navItem navITeacher">
              <a href="/" className="navLinks">Inicio</a>
            </li>
            <li className="navItem navITeacher">
              <p href="" className="navLinks">Conócenos</p>
              <div className="dropdown">
                <ul>
                  <li><a href="/sobreNosotros">Sobre nosotros</a></li>
                  <li><a href="/nuestroEquipo">Nuestro equipo</a></li>
                  <li><a href="/instalaciones">Instalaciones</a></li>
                </ul>
              </div>
            </li>
            <li className="navItem navITeacher">
              <p href="" className="navLinks">Académico</p>
              <div className="dropdown">
                <ul>
                  <li><a href="/ofertaEducativa">Oferta educativa</a></li>
                  <li><a href="/metodoDeEnsenanza">Método de enseñanza</a></li>
                  <li><a href="/clasesEnLinea">Clases en línea</a></li>
                  <li><a href="/examenDeUbicacion">Examen de ubicación</a></li>
                </ul>
              </div>
            </li>
            <li className="navItem navITeacher">
              <a href="/contacto" className="navLinks">Contáctanos</a>
            </li>
            <li className="navItem navITeacher">
              <a className="navLinks">Maestro</a>
              <div className="dropdown">
                <ul>
                  <li><a href="./HomeT">M.Adultos</a></li>
                  <li><a href="./HomeTKids">M.Niños</a></li>
                  <li><a onClick={handleSignOut}>Cerrar Sesión</a></li>
                </ul>
              </div>
            </li>
            {/* <li className="navItem navITeacher">
              <Link to="./Attendance" className="navLinks">Asistencia</Link>
            </li> */}

          </>
        );

      case process.env.REACT_APP_API_DIRECCION_PRIVILEGE:
        return (
          <>
            <li className="navItem">
              <a href="/direccion" className="navLinks">Dirección</a>
            </li>
            <li className="navItem">
              <a onClick={handleSignOut} className="navLinks">Cerrar Sesión</a>
            </li>
          </>
        );

      default:
        return (
          <>
            <li className="navItem colorfull">
              <a href="/proximosCursos" className="navLinks">Próximos Cursos</a>
            </li>
            <li className="navItem">
              <a href="/" className="navLinks">Inicio</a>
            </li>
            <li className="navItem">
              <p href="" className="navLinks">Conócenos</p>
              <div className="dropdown">
                <ul>
                  <li><a href="/sobreNosotros">Sobre nosotros</a></li>
                  <li><a href="/nuestroEquipo">Nuestro equipo</a></li>
                  <li><a href="/instalaciones">Instalaciones</a></li>
                </ul>
              </div>
            </li>
            <li className="navItem">
              <p href="" className="navLinks">Académico</p>
              <div className="dropdown">
                <ul>
                  <li><a href="/ofertaEducativa">Oferta educativa</a></li>
                  <li><a href="/metodoDeEnsenanza">Método de enseñanza</a></li>
                  <li><a href="/clasesEnLinea">Clases en línea</a></li>
                  <li><a href="/examenDeUbicacion">Examen de ubicación</a></li>
                </ul>
              </div>
            </li>
            <li className="navItem">
              <a href="/contacto" className="navLinks">Contáctanos</a>
            </li>
          </>
        );
    }
  };

  const handleMenuClick = () => {
    setMenuOpen(!menuOpen);
  };
  return (
    <>
      {window.location.pathname !== "/recoverAccount" &&
        window.location.pathname !== "/recoverAccountCode" &&
        window.location.pathname !== "/changePassword" && (
          <header>
            <nav className="navbar">
              <div className="headerTop">
                <a href="/" className="logo">
                  <img src={img1} alt="Logo" />
                  <h1>
                    <i className="schoolName"></i>
                    <p>&#174;</p>
                  </h1>
                </a>
              </div>

              <div className="menuIcon" onClick={handleMenuClick}>
                <i className="material-icons">
                  <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#e8eaed">
                    <path d="M120-240v-80h720v80H120Zm0-200v-80h720v80H120Zm0-200v-80h720v80H120Z" />
                  </svg>
                </i>
              </div>

              <div className={`headerBottom ${menuOpen ? 'active' : ''}`}>
                <ul className="headerBottomList">
                  {options(userContext.idPriv || privilege)}
                </ul>
              </div>
            </nav>
          </header>
        )}
    </>
  );
};

export default Header;
