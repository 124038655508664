import React, { useState, useEffect } from "react";
import "./superAdmin.scss";
import Axios from "axios";
import DataTable from "react-data-table-component";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import InputPassword from "../../components/inputPassword/inputPassword";
import { UserContext } from "../../context/userProvider";
import { useNavigate } from "react-router-dom";
import { dataDecrypt } from "../../utils/dataDecrypt";

import ConfirmModal from "../../components/confirmModal/confirmModal";
import ChangePasswordModal from "../../components/changePasswordModal/changePasswordModal";

const SuperAdmin = () => {
  const [dataUsr, setDataUsr] = useState([]);
  const [open, setOpen] = useState(false);
  const [openChild, setOpenChild] = useState(false);

  const [id, setId] = useState(null);
  const [name, setName] = useState("");
  const [lastname, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [genre, setGenre] = useState(null);
  const [privilege, setPrivilege] = useState(null);
  const [status, setStatus] = useState(null);

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [refresh, setRefresh] = useState(0);
  const [updatePasswordMessage, setUpdatePasswordMessage] = useState("");
  const [user, setUser] = React.useContext(UserContext);

  const [onProcess, setOnProcess] = useState(false);
  const [isMyAccount, setIsMyAccount] = useState(false);

  var arrayEdit = [];
  var arrayEditPassword = [];
  var sessionStorageArray = [];
  var userStorageArray = [];

  const [classN, setClassN] = useState("");
  const [classLN, setClassLN] = useState("");
  const [classE, setClassE] = useState("");
  const [classP, setClassP] = useState("");
  const [classPassword, setClassPassword] = useState("");
  const [classConfirmP, setClassConfirmP] = useState("");
  const [classPhoneN, setClassPhoneN] = useState("");

  const [openChildConfirm, setOpenChildConfirm] = useState(false);
  const [action, setAction] = useState(null);
  const [confirmMessage, setConfirmMessage] = useState("");
  const [generalMessage, setGeneralMessage] = useState("");

  const navigate = useNavigate();

  const columns = [
    {
      name: "ID",
      selector: (row) => row.id,
      omit: true,
    },
    {
      name: "Nombre",
      selector: (row) => row.name,
    },
    {
      name: "Apellido",
      selector: (row) => row.lastname,
    },
    {
      name: "Email",
      selector: (row) => row.email,
    },
    {
      name: "Telefono",
      selector: (row) => row.phoneNumber,
    },
    {
      name: "Privilegio",
      selector: (row) => row.privilege,
    },
    {
      name: "Genero",
      selector: (row) => row.genre,
    },
    {
      name: "Estatus",
      selector: (row) => row.status,
    },
    {
      name: "Fecha de Creacion",
      selector: (row) => row.creation_date,
    },
    {
      name: "Ultimo inicio de sesion",
      selector: (row) => row.last_time_loggedin,
    },
  ];

  useEffect(() => {
    if (!localStorage.getItem("user")) {
      localStorage.removeItem("token");
    }
    Axios.get(process.env.REACT_APP_API_URL + "isUserAuth/", {
      headers: {
        "x-access-token": localStorage.getItem("token"),
      },
    }).then((response) => {
      if (response.data.auth === false) {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        navigate("/login");
      } else {
        if (Object.keys(user).length === 0) {
          var userStorage = localStorage.getItem("user");
          var userJson = dataDecrypt(userStorage);
          setUser(userJson);
          var checkAuth = false;
          sessionStorageArray = dataDecrypt(localStorage.getItem("session"));
          sessionStorageArray.forEach((e) => {
            if (userJson.id === e.id) {
              checkAuth = true;
            }
          });
          if (
            userJson.idPriv.toString() !==
              process.env.REACT_APP_API_SUPERADMIN_PRIVILEGE ||
            checkAuth !== true
          ) {
            navigate("/login");
          }
        } else {
          var checkAuth = false;
          sessionStorageArray = dataDecrypt(localStorage.getItem("session"));
          sessionStorageArray.forEach((e) => {
            if (user.id === e.id) {
              checkAuth = true;
            }
          });
          if (
            user.idPriv.toString() !==
              process.env.REACT_APP_API_SUPERADMIN_PRIVILEGE ||
            checkAuth !== true
          ) {
            navigate("/login");
          }
        }
      }
    });
  }, []);
  useEffect(() => {
    if (!localStorage.getItem("user")) {
      localStorage.removeItem("token");
    }
    Axios.get(process.env.REACT_APP_API_URL + "getUsers/", {
      headers: {
        "x-access-token": localStorage.getItem("token"),
      },
    }).then((response) => {
      setDataUsr(response.data);
      setFiltrado(response.data);
    });
  }, [refresh]);


  ///////////////////////
  const handleOpen = (param) => {
    setOpen(true);
    setId(param.id);
    setName(param.name);
    setLastName(param.lastname);
    setEmail(param.email);
    setPhoneNumber(param.phoneNumber);
    setGenre(param.k_genre);
    setPrivilege(param.k_privilege);
    setStatus(param.k_status);
    
    checkUserDelete(param.id);
  };
  //////////
  const checkUserDelete = (idUser) =>{
    userStorageArray = dataDecrypt(localStorage.getItem("user"));
    if (idUser === userStorageArray.id) {
      setIsMyAccount(true)      
    }else{
      setIsMyAccount(false)      
    }
  }
  /////////////////////////////////
  const handleOpenChild = (param) => {
    setClassPassword("");
    setClassConfirmP("");
    setOpenChild(true);
  };

  //Edit User
  function checkEmpty() {
    arrayEdit.length = 0;
    if (name === "" || name === null) {
      arrayEdit.push("editName");
      setClassN("required");
    } else {
      setClassN("");
    }
    if (lastname === "" || lastname === null) {
      arrayEdit.push("editLastName");
      setClassLN("required");
    } else {
      setClassLN("");
    }
    if (email === "" || email === null) {
      arrayEdit.push("editEmail");
      setClassE("required");
    } else {
      setClassE("");
    }
    if (phoneNumber === "" || phoneNumber === null) {
      arrayEdit.push("editPhoneNumber");
      setClassPhoneN("required");
    } else {
      setClassPhoneN("");
    }
    if (privilege === "" || privilege === null || privilege === undefined) {
      arrayEdit.push("editPrivilege");
      setClassP("required");
    } else {
      setClassP("");
    }
  }

  function checkEmptyPassword() {
    arrayEditPassword.length = 0;
    if (password === "" || password === null) {
      arrayEditPassword.push("editPassword");
      setClassPassword("required");
    } else {
      setClassPassword("");
    }
    if (confirmPassword === "" || confirmPassword === null) {
      arrayEditPassword.push("editConfirmPassword");
      setClassConfirmP("required");
    } else {
      setClassConfirmP("");
    }
  }

  const handleEditUser = () => {
    setAction(2);
    setOpenChildConfirm(true);
  };

  const handleEditUserAction = () => {
    checkEmpty();
    if (arrayEdit.length < 1) {
      if (!localStorage.getItem("user")) {
        localStorage.removeItem("token");
      }
      Axios.patch(
        process.env.REACT_APP_API_URL + "updateUser/" + id,
        {
          name: name,
          lastname: lastname,
          email: email,
          phoneNumber: phoneNumber,
          fk_genre: genre,
          fk_status: status,
          fk_privilege: privilege,
        },
        { headers: { "x-access-token": localStorage.getItem("token") } }
      ).then((response) => {
        if (response.data.status === 200) {
          setConfirmMessage(response.data.message);
          setTimeout(() => {
            handleCloseChildConfirm();
            handleClose();
          }, 3000);
        } else {
          setConfirmMessage(response.data.message);
          setTimeout(() => {
            setGeneralMessage("Verifique que no haya campos vacíos");
            handleCloseChildConfirm();
          }, 3000);
          setOnProcess(false);
        }
      });
    } else {
      setTimeout(() => {
        setGeneralMessage("Verifique que no haya campos vacíos");
        handleCloseChildConfirm();
        setOnProcess(false);
      }, 3000);
    }
  };
  //Delete

  const handleDeleteUser = () => {
    setAction(1);
    setOpenChildConfirm(true);
  };
  const handleCloseChildConfirm = () => {
    setConfirmMessage("");
    setOpenChildConfirm(false);
  };

  const handleConfirmChild = () => {
    setOnProcess(true);
    switch (action) {
      case 1:
        handleDeleteUserAction();
        break;
      case 2:
        handleEditUserAction();
        break;

      default:
        break;
    }
  };
  const handleDeleteUserAction = () => {
    Axios.delete(process.env.REACT_APP_API_URL + "deleteUser/" + id).then(
      (response) => {
        let status = response.data.status;
        if (status === 200) {
          setConfirmMessage(response.data.message);
          setTimeout(() => {
            handleCloseChildConfirm();
            handleClose();
          }, 1500);
        }
      }
    );
  };
  ////////////////////////////////////////
  const handleClose = () => {
    setOnProcess(false);
    setRefresh(refresh + 1);
    setClassN("");
    setClassLN("");
    setClassE("");
    setClassP("");
    setClassPhoneN("");
    setGeneralMessage("");
    setOpen(false);
  };

  const handleCloseChild = () => {
    setClassPassword("");
    setClassConfirmP("");
    setPassword("");
    setConfirmPassword("");
    setOpenChild(false);
  };
  /////////////////////////////////////
  const handleChangePassword = () => {
    checkEmptyPassword();
    if (arrayEditPassword.length < 1) {
      if (!localStorage.getItem("user")) {
        localStorage.removeItem("token");
      }
      if (
        user.idPriv.toString() ===
        process.env.REACT_APP_API_SUPERADMIN_PRIVILEGE
      ) {
        Axios.patch(
          process.env.REACT_APP_API_URL + "changeUserPassword/" + id,
          {
            password: password,
            confirmPassword: confirmPassword,
          },
          { headers: { "x-access-token": localStorage.getItem("token") } }
        ).then((response) => {
          if (response.data.status === 200) {
            setOnProcess(true);
            setGeneralMessage(response.data.message);
            handleCloseChild();
            setTimeout(() => {
              handleClose();
            }, 3000);
          } else {
            setUpdatePasswordMessage(response.data.message);
            setTimeout(() => {
              setUpdatePasswordMessage("");
            }, 3000);
          }
        });
      } else {
        setGeneralMessage(
          "Este usuario no tiene el privilegio para esta acción"
        );
        setTimeout(() => {
          setGeneralMessage("");
        }, 3000);
      }
    } else {
      setUpdatePasswordMessage("Verifique que no haya campos vacíos");
      setTimeout(() => {
        setUpdatePasswordMessage("");
      }, 3000);
    }
  };

  /////////////////////////////////
  const handleGenre = (e) => {
    setGenre(e.target.value);
  };
  const handlePrivilege = (e) => {
    setPrivilege(e.target.value);
  };
  const handleStatus = (e) => {
    setStatus(e.target.value);
  };

  

  // Barra de busqueda
  const [search, setSearch] = useState("");
  const [filter, setFiltrado] = useState([]);

  const onChangeSearch = async (e) => {
    e.persist();
    await setSearch(e.target.value);
    if (e.target.value === "") {
      setFiltrado(dataUsr);
    } else {
      filterElement();
    }
  };

  const filterElement = () => {
    var searchF = dataUsr.filter((item) => {
      if (
        item.name.includes(search.charAt(0).toUpperCase()) ||
        item.lastname.includes(search.charAt(0).toUpperCase()) ||
        item.email.includes(search.charAt(0).toUpperCase())
      ) {
        return item;
      }
    });
    setFiltrado(searchF);
  };

  function maxLenghtFunction(e) {
    if (e !== undefined && e !== null) {
      if (e.length > 10) {
        setPhoneNumber(e.slice(0, 10));
      }
    }
  }

  return (
    <div className="containerSuperAdmin">
      <div className="searcherContainer">
        <input
          type="text"
          placeholder="Buscar"
          className="search"
          value={search}
          onChange={onChangeSearch}
        ></input>
      </div>
      <DataTable
        columns={columns}
        data={filter}
        pagination
        responsive
        onRowDoubleClicked={(row, event) => {
          handleOpen(row);
        }}
      />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box className={'containerEditModal'}>
          <div className="editContainer">
            <h1>PERFIL</h1>
            <div className="formContainer">
              <input
                maxLength={"50"}
                id={"editName"}
                type="text"
                className={classN}
                placeholder="Nombre"
                onChange={(e) => {
                  setName(e.target.value);
                }}
                value={name}
              />
              <input
                maxLength={"100"}
                id={"editLastName"}
                type="text"
                className={classLN}
                placeholder="Apellido"
                onChange={(e) => {
                  setLastName(e.target.value);
                }}
                value={lastname}
              />
              <select id={"editGenre"} value={genre} onChange={handleGenre}>
                {genre === null && (
                  <option value="">Seleccionar Género</option>
                )}
                <option value="1">Hombre</option>
                <option value="2">Mujer</option>
              </select>
              <input
                id={"editEmail"}
                type="text"
                className={classE}
                placeholder="Email"
                disabled
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                value={email}
              />
              <input
                id={"editPhoneNumber"}
                type="number"
                className={classPhoneN}
                placeholder="Teléfono"
                onInput={maxLenghtFunction(phoneNumber)}
                onChange={(e) => {
                  setPhoneNumber(e.target.value);
                }}
                value={phoneNumber}
              />
              <select
                id={"editPrivilege"}
                value={privilege}
                className={classP}
                onChange={handlePrivilege}
              >
                {privilege === null && (
                  <option value="">Selecciona Privilegio</option>
                )}
                <option value="1">Alumno</option>
                <option value="2">Profesor</option>
                <option value="3">Administración</option>
                <option value="4">Dirección</option>
                <option value="5">superAdmin</option>
              </select>
              <select id={"editStatus"} value={status} onChange={handleStatus}>
                <option value="1">Activo</option>
                <option value="2">Inactivo</option>
              </select>
            </div>
            <div className="changePasswordContainer">
              <button onClick={handleOpenChild}>Cambiar contraseña</button>
            </div>
            <div className="bottomForm">
              <button
                id="cancelButton"
                onClick={handleClose}
                disabled={onProcess === true ? true : false}
                className={onProcess === true ? "disabled" : ""}
              >
                Cancelar
              </button>
              <button
                id="editButton"
                onClick={handleEditUser}
                disabled={onProcess === true ? true : false}
                className={onProcess === true ? "disabled" : ""}
              >
                Guardar Cambios
              </button>
              <button
                id="deleteButton"
                onClick={handleDeleteUser}
                disabled={(onProcess === true || isMyAccount === true) ? true : false}
                className={(onProcess === true || isMyAccount === true) ? "disabled" : ""}
              >
                Eliminar
              </button>
            </div>
            <div>
              <span>{generalMessage}</span>
            </div>
          </div>
          {/* CHILD MODAL */}


          <ChangePasswordModal
            open = {openChild}
            onClose = {handleCloseChild}
            classNamePassword = {classPassword}
            onChangePassword = {(e) => {
              setPassword(e.target.value);
            }}
            classNameConfirmPassword = {classConfirmP}
            onChangeConfirmPassword = {(e) => {
              setConfirmPassword(e.target.value);
            }}
            onClickCancel = {handleCloseChild}
            onClickConfirm = {handleChangePassword}
            disabled = {onProcess === true ? true : false}
            classNameButton = {onProcess === true ? "disabled" : ""}
            message = {updatePasswordMessage}
          />
          
          <ConfirmModal
            open={openChildConfirm}
            onClose={handleCloseChildConfirm}
            onClickCancel={handleCloseChildConfirm}
            handleConfirm={handleConfirmChild}
            disabled={onProcess === true ? true : false}
            className={onProcess === true ? "disabled" : ""}
            confirmMessage={confirmMessage}
          />
        </Box>
      </Modal>
    </div>
  );
};

export default SuperAdmin;
