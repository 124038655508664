import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Row } from "primereact/row";
import { ColumnGroup } from "primereact/columngroup";
import "./modalGroupsTable.scss"
import { Switch } from "@mui/material";
import InputAnimated from "../../inputAnimated/inputAnimated";
import Axios from "axios";
import InputDate from "../../inputDate/inputDate";
import {jsPDF} from 'jspdf';
import html2canvas from 'html2canvas';
const ModalGroupsTable = (props) => {
    //States 
    const [arrayTeacherClasses, setArrayTeacherClasses] = useState([])
    const [selectedCell, setSelectedCell] = useState(null);
    const [selectedRow, setSelectedRow] = useState(false);
    const [rowClick, setRowClick] = useState(true);
    const [weekSelected, setWeekSelected] = useState(true);


    useEffect(() => {
        // Fetch teacher classes data when modal opens
        if (props.open === true) {
            setSelectedRow(false)
            const teacherClasses = GetAllTeacherClasses();
            teacherClasses.then((classT) => {
                //if token expire the page is going to reload
                if (classT.teacherClasses !== undefined) {
                    // setArrayTeacherClasses(classT.teacherClasses)
                    const arrayFilter = classT.teacherClasses.filter(c => c.st !== 2);// Filter out classes with status 2 (inactive)
                    // Group teacher classes by teacher and full day class
                    const groupedData = arrayFilter.reduce((gteacher, current) => {
                        const teacherN = current.teacher.name;
                        if (!gteacher[teacherN]) {
                            gteacher[teacherN] = {};
                        }

                        const fullDayClassKey = current.full_dayclass;
                        if (!gteacher[teacherN][fullDayClassKey]) {
                            gteacher[teacherN][fullDayClassKey] = current;
                        }
                        return gteacher;
                    }, []);
                    // Convert grouped data to array format
                    const arrayTeacher = Object.keys(groupedData).map((teacher) => ({
                        teacher,
                        data: groupedData[teacher],
                    }))
                    setArrayTeacherClasses(arrayTeacher)
                } else {
                    window.location.reload()
                }
            })



        }
    }, [props.open])

    // Function to fetch all teacher classes from API
    const GetAllTeacherClasses = async () => {
        const res = await Axios.get(process.env.REACT_APP_API_URL + "getAllTeacherClasses/", {
            headers: {
                "x-access-token": localStorage.getItem("token"),
            },
        })
        return res.data
    }

    // Function to convert 24-hour format to 12-hour format
    function convertTwelve(hour24) {
        let hour12 = parseInt(hour24, 10);
        if (hour12 > 12) {
            hour12 -= 12;
        }
        return `${hour12}`;
    }

    // Function to generate column header based on full day class
    function columnHeader(fulldayclass) {
        var day = fulldayclass.split(' ')[0];
        var columnHeader = "";

        if (day === "Lunes") {
            columnHeader = convertTwelve(fulldayclass.substr(-11, 2)) + "-" + convertTwelve(fulldayclass.substr(-5, 2));
        } else {
            columnHeader = <React.Fragment><p>{day}</p><p>{convertTwelve(fulldayclass.substr(-11, 2)) + "-" + convertTwelve(fulldayclass.substr(-5, 2))}</p></React.Fragment>
        }
        return columnHeader;

    }
    // Function to get the style class based on book color
    const getStyleClass = (cell) => {
        var classStyle = "";
        var bookColor = cell.book.name;

        switch (bookColor) {
            case "Yellow": classStyle = "yellowbook"; break;
            case "Red": classStyle = "redbook"; break;
            case "Blue": classStyle = "bluebook"; break;
            case "Green": classStyle = "greenbook"; break;
            case "Purple": classStyle = "purplebook"; break;
            case "Smile 1": classStyle = "s1book"; break;
            case "Smile 2": classStyle = "s2book"; break;
            case "Smile 3": classStyle = "s3book"; break;
            case "Smile 4": classStyle = "s4book"; break;
            case "Smile 5": classStyle = "s5book"; break;
            case "Smile 6": classStyle = "s6book"; break;
            case "Mega": classStyle = "megabook"; break;
            default: break;
        }
        return classStyle;
    };

    // Header structure for the data table
    const tableHeader = (
        <ColumnGroup>
            <Row>
                {/* <Column header="" colSpan={1}></Column> */}
                {/* <Column header="" colSpan={1}></Column> */}
                <Column className="tblank" header="" colSpan={1} />
                <Column className="morning" header="Matutino" colSpan={3} />
                <Column className="afternoon" header="Vespertino" colSpan={6} />
                <Column className="weekend" header="Fin de Semana" colSpan={3} />
            </Row>
            <Row>
                {/* <Column header="" colSpan={1}></Column> */}
                {/* <Column header="" colSpan={1}></Column> */}
                <Column header="Maestro" colSpan={1} />
                <Column header="8-9" colSpan={1} />
                <Column header="9-10" colSpan={1} />
                <Column header="10-11" colSpan={1} />
                <Column header="3-4" colSpan={1} />
                <Column header="4-5" colSpan={1} />
                <Column header="5-6" colSpan={1} />
                <Column header="6-7" colSpan={1} />
                <Column header="7-8" colSpan={1} />
                <Column header="8-9" colSpan={1} />
                <Column header="8-1" colSpan={1} />
                <Column header="1:30-5:30" colSpan={1} />
                <Column header="9-1" colSpan={1} />
            </Row>
        </ColumnGroup>

    );

    // Function to determine if the class start date is today
    const Today = (datestart) => {
        var today = new Date();
        var start = new Date(datestart);
        var date = start.getDate + "-" + start.getMonth
        if (start > today) {
            return (true)
        } else {
            return (false)
        }
    }

    // Function to format the class start date
    const orderDate = (datestart) => {
        var start = new Date(datestart);
        var date = start.getDate() + " " + start.toLocaleString('default', { month: 'long' }).toUpperCase();
        return (date)
    }

    // Function to filter attendance list based on teacher, start time, end time, and week start date
    const attendenceListfilter = (teacher, start, end, ws) => {
        //Calculate one last week
        var startDate = new Date(ws.getFullYear(), ws.getMonth(), ws.getDate() - 7);
        //Calculate 6 days later to get the full week
        var endDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() + 6);
        endDate.setHours(23); // Set the end time to the last hour of the day

        // Get the array of events from props
        var eventos = props.arrayData;

        // Filter events based on teacher ID, start and end time, and within the selected week
        var eventsFiltered = eventos.filter((evento) => {
            // Extract start and end dates from the event
            const eventStartDate = new Date(evento.start);
            const eventEndDate = new Date(evento.end);

            // Get the start time and end time of the event in a readable format
            const timeStart = eventStartDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
            const timeEnd = eventEndDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });

            // Check if the event matches the criteria: same teacher, within the selected week, and same start and end time
            return evento.id_teacher === teacher &&
                eventStartDate >= startDate && eventStartDate <= endDate &&
                timeStart === start && timeEnd === end;
        });

        // Calculate the average attendance if events are found
        if (eventsFiltered.length > 0) {
            // Calculate the total attendance for all events
            const attendenceTotal = eventsFiltered.reduce((total, event) => total + event.qtyStudents, 0);
            // Calculate the average attendance
            const average = Math.round(attendenceTotal / eventsFiltered.length);
            return average; // Return the average attendance
        }
    }


    // Function to toggle between cell and row selection mode
    const selected = (selection) => {

        setSelectedRow(!selectedRow);
    }

    // Handle change in week selection
    const handleChangeWeek = (dateSelection) => {
        // Iterate over each teacher class in the arrayTeacherClasses
        arrayTeacherClasses.forEach(a => {
            // Define the schedule for each day and time slot
            const schedule = ['Lunes - Viernes 08:00-09:00', 'Lunes - Viernes 09:00-10:00', 'Lunes - Viernes 10:00-11:00', 'Lunes - Viernes 15:00-16:00',
                'Lunes - Viernes 16:00-17:00', 'Lunes - Viernes 17:00-18:00', 'Lunes - Viernes 18:00-19:00', 'Lunes - Viernes 19:00-20:00',
                'Lunes - Viernes 20:00-21:00', 'Sabado AM 08:00-13:00', 'Sabado PM 13:30-17:30', 'Domingo 09:00-13:00'];
            // Iterate over each schedule
            schedule.forEach(s => {
                // Check if the current teacher class has data for the current schedule
                if (a.data[s] !== undefined) {
                    const obj = a.data[s];
                    // Calculate the average attendance for the current teacher class and schedule
                    const avrg = attendenceListfilter(obj.teacher.id, obj.time.start, obj.time.end, dateSelection);
                    // Add the average attendance to the teacher class object
                    obj.avarageAttendance = avrg;
                }
            });
        });
        // Call the handleDateSelection function with the selected date
        handleDateSelection(dateSelection);
    }

    // Function that is necesary to handle date selection from the InputDate
    //Function that helps to render attandence in the tablegroup
    const handleDateSelection = (dateSelection) => {
        setWeekSelected(dateSelection)
    }

    /////Print table
    //Function to export the table as image to a PDF
    const generatePDF = () => {
        const modalContent = document.getElementById('table-group');

        if (!modalContent) {
            console.error('No se encontró el contenido del modal');
            return;
        }
        const pdf = new jsPDF('L', 'mm', 'letter');

        html2canvas(modalContent, {
            scrollY: window.scrollY, // Adjust the scroll position to capture the entire table.
            windowHeight: '2400mm'// Canvas total size
        }).then(canvas => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF('p', 'mm', 'letter'); // 'l' (p->portrait, milimeters, sheet size)
            let imgWidth = 205;
            let imgHeight = 180;

            console.log(imgHeight)
            pdf.addImage(imgData, 'PNG', 5, 5, imgWidth, imgHeight);
            pdf.save('tabla-grupos.pdf');
        });
    };


    ///////------------------------------------------------------------------
    return (
        <>
            <Modal
                open={props.open}
                onClose={props.onClose}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
                disableEnforceFocus
                disableAutoFocus
            >
                <Box className="box boxGroup" style={{ width: "90%", padding: "2%" }}>
                    <div className="modalHeader">
                        <div className="reporttitle">
                            <p className="paragraphTitle">Tabla de grupos</p>

                        </div>
                        <div className="filter">
                            <div className="switch"><div className="switchSelect"><p>Selección | Celda</p><Switch onClick={selected}></Switch><p>Fila</p></div></div>
                            <div className="dateFilter" >
                                <button onClick={generatePDF}>Imprimir</button>
                                <InputDate selectionType="week" dateSelection={handleChangeWeek} />
                            </div>
                        </div>
                        {/* <div className="filter"><div className="switch"><div className="switchSelect"><p>Selección | Celda</p><Switch onClick={selected}></Switch><p>Fila</p></div></div>
                            <div className="dateFilter" style={{ margin: "5px 0px 15px 0px" }}>
                                <InputAnimated type="week" step="7" onChange={(e) => handleChangeWeek(e)} />
                            </div>
                        </div> */}

                    </div>
                    <div className="modalBody">
                        <DataTable
                            value={arrayTeacherClasses}
                            tableStyle={{ minWidth: '200px', height: '600px' }}
                            sortMode="single"
                            headerColumnGroup={tableHeader}
                            className="groupDataTable"
                            cellSelection
                            selectionMode={rowClick ? null : 'radiobutton'}
                            selection={selectedCell}
                            onSelectionChange={(e) => setSelectedCell(e.value)}
                            scrollable
                            scrollHeight="100%"
                            id={"table-group"}
                        >
                            <Column header="" selectionMode={selectedRow ? "single" : ""} className="tableCtype"></Column>
                            <Column field="teacher" header="Maestro" className="tableCtype4 teacher" />
                            {['Lunes - Viernes 08:00-09:00', 'Lunes - Viernes 09:00-10:00', 'Lunes - Viernes 10:00-11:00', 'Lunes - Viernes 15:00-16:00',
                                'Lunes - Viernes 16:00-17:00', 'Lunes - Viernes 17:00-18:00', 'Lunes - Viernes 18:00-19:00', 'Lunes - Viernes 19:00-20:00',
                                'Lunes - Viernes 20:00-21:00', 'Sabado AM 08:00-13:00', 'Sabado PM 13:30-17:30', 'Domingo 09:00-13:00'].map((schedule) => (
                                    <Column
                                        key={schedule}
                                        body={(rowData) => {
                                            if (rowData.data[schedule]) {
                                                return (
                                                    <div className="tdDiv">
                                                        {rowData.data[schedule].is_online === 1 && (
                                                            <div className="groupDiv isOnline"><span>Online</span></div>
                                                        )}
                                                        {rowData.data[schedule].day_off === 1 && (
                                                            <div className="groupDiv dayOff" style={{ height: "50px", verticalAlign: "middle", lineHeight: "normal" }}><span>Libre</span></div>
                                                        )}
                                                        {Today(rowData.data[schedule].date.start) && (
                                                            <div className="groupDiv datestart" style={{ height: "50px", verticalAlign: "middle", lineHeight: "normal" }}><span >{orderDate(rowData.data[schedule].date.start)}</span></div>
                                                        )}
                                                        {rowData.data[schedule].is_online === 0 && rowData.data[schedule].day_off === 0 && Today(rowData.data[schedule].date.start) === false && (
                                                            <React.Fragment>
                                                                <div className={"groupDiv " + getStyleClass(rowData.data[schedule])}>
                                                                    <span>{rowData.data[schedule].book.name + " - "}</span>
                                                                    <span>{rowData.data[schedule].book.unitName.substr(7, 3)}</span>
                                                                </div>
                                                                <div className="studentsqty">
                                                                    <span>{rowData.data[schedule].students_qty} </span>
                                                                    <span> - {rowData.data[schedule].avarageAttendance}</span>
                                                                </div>
                                                            </React.Fragment>
                                                        )}

                                                    </div>
                                                );
                                            }
                                            return null; // o cualquier otro comportamiento que desees para celdas sin datos
                                        }}
                                        // header={columnHeader(schedule)}
                                        className={"tableCtype1 "}
                                    />

                                ))}
                        </DataTable>
                    </div>
                    <div className="modalFooter"></div>
                </Box>
            </Modal>


        </>
    );
}
export default ModalGroupsTable