import React, { useEffect, useState } from "react"
import Popover from '@mui/material/Popover';
import "./cardDireccion.scss"
const CardDireccion = (props) => {
    const [idEvent, setIdEvent] = useState();
    const [color, setColor] = useState();
    const [unitBook, setUnitBook] = useState();
    const [date, setDate] = useState();
    const [teacher, setTeacher] = useState();
    const [asistencias, setAsistencias] = useState();
    useEffect(() => {
        if (props.open === true) {
        setIdEvent(props.cardData.idEvent)
        setColor(props.cardData.color)
        setUnitBook(`${props.cardData.nameBook} - ${props.cardData.nameUnit}`)
        setDate(props.cardData.date)
        setTeacher(props.cardData.teacher)
        setAsistencias(props.cardData.qtyStudents)
    }
    }, [props.open])
    return (
        <Popover
            id={props.id}
            open={props.open}
            anchorEl={props.anchorEl}
            onClose={props.onClose}
        >
            <div className="popContainerMain">

                <div className="headerPop" style={{ background: color }}>
                    <div className="headerButtons">
                        <button onClick={props.onClickEdit}><i className="material-icons">edit</i></button>
                        <button onClick={(e)=>{e.preventDefault();props.onClickDelete({idEvent})}}><i className="material-icons">delete</i></button>
                    </div>
                    <div className="headerTitle">
                        {unitBook}
                    </div>
                </div>
                <div className="bodyPop">
                    <span><i className="material-icons">event</i> {date}</span>
                    <span><i className="material-icons">supervisor_account</i> {teacher}</span>
                    <span><i className="material-icons">checklist</i>{asistencias} asistencias de alumnos</span>
                </div>
            </div>
        </Popover>

    )
}
export default CardDireccion