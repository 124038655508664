import React, { useEffect } from "react";
import Axios from "axios";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../context/userProvider";
import "./metodoEza.scss";
import img1 from "../../assets/metodoEnz/metodo1.jpg";
import img1_1 from "../../assets/metodoEnz/metodocurva.png";
import img2 from "../../assets/metodoEnz/metodo2.jpg";
import img3 from "../../assets/metodoEnz/metodo3.jpg";
import { dataDecrypt } from "../../utils/dataDecrypt";

const MetodoEza = () => {
  const navigate = useNavigate();
  const [user, setUser] = React.useContext(UserContext);
  var sessionStorageArray = []
  useEffect(() => {
    Axios.get(process.env.REACT_APP_API_URL + "isUserAuth/", {
      headers: {
        "x-access-token": localStorage.getItem("token"),
      },
    }).then((response) => {
      if (response.data.auth === false) {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
      } else {
        var userStorage = localStorage.getItem("user");
        var userJson = dataDecrypt(userStorage);
        setUser(userJson);

        var checkAuth = false;
        sessionStorageArray = dataDecrypt(localStorage.getItem("session"));
        sessionStorageArray.forEach((e) => {
          if (userJson.id === e.id) {
            checkAuth = true;
          }
        });
        if (checkAuth !== true) {
          navigate("/login");
        }
      }
    });
  }, []);
  return (
    <div className="metodo">
      <div className="oft_header">
        <div className="oft_header_img">
          <img className="img1" src={img1} />
          <div></div>
          <img className="img2" src={img1_1} />
        </div>
        <span className="oft_header_text">
          Estas listo para probar <br />algo nuevo
        </span>
        <h3 className="oft_header_title1">Método</h3>
        <h1 className="oft_header_title2">de enseñanza</h1>
      </div>
      <div className="paragraph">
        <h1 className="caption1">Así es como aprendes</h1>
        <span>
          En Active English School los alumnos reciben mucho más que sólo educación convencional.
          Creamos un ambiente único y dinámico que acepta a todo tipo de estudiantes y favorece
          el crecimiento a nivel personal y académico.
          <br /><br />
          No utilizamos nombres exagerados, rebuscados y de mercadotecnia para llamar a nuestro sistema de enseñanza, pero sí nos enorgullecemos de contar con un formato de clases 70% práctico que asegura que vas a aplicar y llevar a un campo real lo que aprendes.
        </span>
      </div>
      <div className="paragraph2">
        <img src={img2} />
        <div className="text">
          <h1 className="caption1">Conversación</h1>
          <h2 className="caption2">"Lights, camera, action..."</h2>
          <span>
            Así décimos en Active English School cuando presentamos una conversación y entonces la acción comienza. La conversación es una dinámica que permite llevar a un campo práctico todo lo aprendido, ayuda a ganar autoconfianza y prepara para el mundo real.

          </span>
        </div>
      </div>
      <div className="paragraph2">
        <img src={img3} />
        <div className="text">
          <h1 className="caption1">Exposiciones</h1>
          <h2 className="caption2">"Practice makes perfect"</h2>
          <span>
            En español decimos "la práctica hace al maestro" pero la idea es igual, entre más se practica algo mejor se hace. Las exposiciones en clase ayudan a eliminar el nerviosismo que se siente al hablar en público en un segundo idioma.

          </span>
        </div>
      </div>

    </div>
  );
};
export default MetodoEza;
