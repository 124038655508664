import React, { useEffect } from "react";
import Axios from "axios";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../context/userProvider";
import "./instalaciones.scss";
import CarouselImg from "../../components/carousel/carousel";
import img1 from "../../assets/instalaciones/i_p.png";
import img2 from "../../assets/instalaciones/facilities1.jpg";
import img3 from "../../assets/instalaciones/facilities2.jpg";
import img4 from "../../assets/instalaciones/facilities3.jpg";

import { dataDecrypt } from "../../utils/dataDecrypt";
const Instalaciones = () => {
  const navigate = useNavigate();
  const [user, setUser] = React.useContext(UserContext);
  var sessionStorageArray = [];
  // Crear el contexto para cargar las imágenes automáticamente de la carpeta "plazaEstrella"
  const plazaEstrellaContext = require.context("../../assets/instalaciones/plazaEstrella", false, /\.(jpg|jpeg|png)$/);
  const bugambiliasContext = require.context("../../assets/instalaciones/bugambilias", false, /\.(jpg|jpeg|png)$/);

  // Mapear las imágenes a un arreglo
  const plazaEstrellaImages = plazaEstrellaContext.keys().map(plazaEstrellaContext);
  const bugambiliasImages = bugambiliasContext.keys().map(bugambiliasContext);

  const carrouselImgPE = plazaEstrellaImages;
  const carrouselImgPB = bugambiliasImages;
  useEffect(() => {
    Axios.get(process.env.REACT_APP_API_URL + "isUserAuth/", {
      headers: {
        "x-access-token": localStorage.getItem("token"),
      },
    }).then((response) => {
      if (response.data.auth === false) {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
      } else {
        var userStorage = localStorage.getItem("user");
        var userJson = dataDecrypt(userStorage);
        setUser(userJson);

        var checkAuth = false;
        sessionStorageArray = dataDecrypt(localStorage.getItem("session"));
        sessionStorageArray.forEach((e) => {
          if (userJson.id === e.id) {
            checkAuth = true;
          }
        });
        if (checkAuth !== true) {
          navigate("/login");
        }
      }
    });
  }, []);
  return (
    <div className="instalacionesContainer">
      <div
        className="iPrensentacionContainer"
        style={{
          backgroundImage: `url(${img1})`,
        }}
      >
        <div className="iPresentacionTitle">
          <h3>Nuestras</h3>
          <h1>instalaciones</h1>
        </div>
        <div className="iPresentacionBody">
          <span>
            No sólo estudies Inglés, hazlo en el mejor lugar.
          </span>
        </div>
        {/* En imagenes utilizar position absolute y acomodar con el position top y left y el z index seria sobre las tarjeticas de imagen y el titulo */}
      </div>
      <div className="iCardContainer">
        <div className="cardContainer1">
          <div className="cardImgContainer">
            <img src={img2} alt="modernasImg" />
          </div>
          <div className="cardTitleContainer">
            <h2>Modernas</h2>
          </div>
        </div>
        <div className="cardContainer2">
          <div className="cardTitleContainer">
            <h2>Bien Equipadas</h2>
          </div>
          <div className="cardImgContainer">
            <img src={img3} alt="modernasImg" />
          </div>
        </div>
        <div className="cardContainer3">
          <div className="cardImgContainer">
            <img src={img4} alt="modernasImg" />
          </div>
          <div className="cardTitleContainer">
            <h2>Amenidades</h2>
          </div>
        </div>
      </div>
      <div className="explora">
        <h1>Explora</h1>
        <span>
          Observa nuestras instalaciones, sumérgete en la experiencia Active.
          Nos enorgullecemos no sólo de contar con clases dinámicas y de
          calidad, sino de tener instalaciones y mobiliario para clases que
          están a la vanguardia y donde se combinan la diversión y el
          profesionalismo para ofrecerte lo mejor de ambas cosas, un servicio
          de 5 estrellas: la experiencia Active.
        </span>
      </div>
      <CarouselImg array={carrouselImgPE} title={"Plantel Estrella"} colorTitle={"#1976d2"} />
      <CarouselImg array={carrouselImgPB} title={"Plantel Bugambilias"} colorTitle={"#842B97"} />
    </div>

  );
};
export default Instalaciones;
