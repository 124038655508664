import React, { useEffect, useState, useRef } from "react";
import Axios from "axios";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../context/userProvider";
import "./contacto.scss";
import img1 from "../../assets/contacto/contact1.jpg";
import { dataDecrypt } from "../../utils/dataDecrypt";
import Maps from "../../components/map/maps";
const Contacto = () => {
  const navigate = useNavigate();
  const [user, setUser] = React.useContext(UserContext);
  const [classN, setClassN] = useState('');
  const [classE, setClassE] = useState('');
  const [classP, setClassP] = useState('');
  const [classM, setClassM] = useState('');
  const refTextArea = useRef(null);
  const refInputName = useRef(null);
  const refInputEmail = useRef(null);
  const refInputPhone = useRef(null);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');
  const [statusMessage, setStatusMessage] = useState('');
  var arrayContact = [];
  var sessionStorageArray = []
  useEffect(() => {
    Axios.get(process.env.REACT_APP_API_URL + "isUserAuth/", {
      headers: {
        "x-access-token": localStorage.getItem("token"),
      },
    }).then((response) => {
      if (response.data.auth === false) {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
      } else {
        var userStorage = localStorage.getItem("user");
        var userJson = dataDecrypt(userStorage);
        setUser(userJson);

        var checkAuth = false;
        sessionStorageArray = dataDecrypt(localStorage.getItem("session"));
        sessionStorageArray.forEach((e) => {
          if (userJson.id === e.id) {
            checkAuth = true;
          }
        });
        if (checkAuth !== true) {
          navigate("/login");
        }
      }
    });
  }, []);

  const SendContactRequest = () => {
    checkEmpty()
    if (arrayContact.length === 0) {
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
        Axios.post(process.env.REACT_APP_API_URL + "contactUs/", {
          name: name,
          email: email,
          phone: phone,
          message: message
        }).then((response) => {
          setStatusMessage(response.data.message)
          setTimeout(() => {
            setStatusMessage("")
            cleanInput();
          }, 6000);
        })
      } else {
        setStatusMessage("El formato del correo electrónico está incorrecto.")
        setClassE("required");
        setTimeout(() => {
          setStatusMessage("")
          setClassE("")
        }, 4000);
      }

    } else {
      setStatusMessage("Por favor, asegúrese que todos los campos estén llenos.")
      setTimeout(() => {
        setStatusMessage("")
      }, 4000);
    }
  }

  function checkEmpty() {
    arrayContact.length = 0;
    if (name === "" || name === null) {
      arrayContact.push("contactName");
      setClassN("required");
    } else {
      setClassN("");
    }
    if (email === "" || email === null) {
      arrayContact.push("contactEmail");
      setClassE("required");
    } else {
      setClassE("");
    }
    if (phone === "" || phone === null) {
      arrayContact.push("contactPhone");
      setClassP("required");
    } else {
      setClassP("");
    }
    if (message === "" || message === null) {
      arrayContact.push("contactMessage");
      setClassM("required");
    } else {
      setClassM("");
    }
  }

  const cleanInput = () => {
    refInputName.current.value = ""
    refInputEmail.current.value = ""
    refInputPhone.current.value = ""
    refTextArea.current.value = ""
  }
  function maxLenghtFunction(e) {
    if (e !== undefined && e !== null) {
      if (e.length > 10) {
        setPhone(e.slice(0, 10));
      }
    }
  }
  return (
    <>
      <div className="cContainer">
        <div
          className="cPresentacionContainer"
          style={{
            backgroundImage: `url(${img1})`,
          }}
        >
          <h1>Contáctanos</h1>
        </div>
        <div className="locations">
          <div className="estrella">
            <h1>Plantel Plaza Estrella</h1>
            <span>
              Plaza Estrella, Blvd. Cucapah 22371,
              <br />
              Villafontana, Fontana I, 22205 Tijuana, B.C.
            </span>
            <div className="phoneContainer">
              <h3>
                Teléfonos:
              </h3>
              <span>
                664-701-9736 y 664-650-9387
              </span>
            </div>
            <div className="hourContainer">
              <h3>
                Horario:
              </h3>
              <span>
                08:00 - 20:30
              </span>
            </div>
          </div>
          <div className="bugambilias">
            <h1>Plantel Plaza Bugambilias</h1>
            <span>
              Plaza Bugambilias, Blvd. Cucapah 21907,
              <br />
              Lomas del Matamoros, 22206 Tijuana, B.C.
            </span>
            <div className="phoneContainer">
              <h3>
                Teléfono:
              </h3>
              <span>
                664-870-6822
              </span>
            </div>
            <div className="hourContainer">
              <h3>
                Horario:
              </h3>
              <span>
                08:00 - 20:30
              </span>
            </div>
          </div>
        </div>
        <div className="emailForm">
          <div className="titleForm">
            <h1>Envianos Un Email</h1>
          </div>
          <div className="inputs">
            <input ref={refInputName} className={classN} type="text" placeholder="Nombre" onChange={(e) => { setName(e.target.value) }} />
            <input ref={refInputEmail} className={classE} type="email" placeholder="Email" onChange={(e) => { setEmail(e.target.value) }} />
            <input ref={refInputPhone} className={classP} type="number" placeholder="Teléfono" onInput={maxLenghtFunction(phone)} onChange={(e) => { setPhone(e.target.value) }} />
            <textarea ref={refTextArea} className={classM} type="text" placeholder="Escribe tu mensaje aquí" onChange={(e) => { setMessage(e.target.value) }} />
            <div className="containerStatusMessage">
              <span className="statusMessage">{statusMessage}</span>
            </div>
          </div>
          <button className="cButton" onClick={SendContactRequest}>Enviar</button>
        </div>
        <Maps />
      </div>
    </>
  );
};
export default Contacto;
