import React, { useEffect, useState } from "react";
import "./emailVerification.scss";
import emailIMG from "../../../assets/email.png";
import { useNavigate, useLocation } from "react-router-dom";
import Axios from "axios";
import { emailVerification } from "../../../utils/Apis/emailVerification";
const EmailVerification = () => {
  const [email, setEmail] = useState();
  const [send, setSend] = useState(0);
  const [message, setMessage] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    if (!location.state) {
      navigate(-1);
    } else {
      setEmail(location.state.email);
    }
  }, []);
  const goToLogin = () => {
    navigate("/login");
  };
  const sendEmailAgain = () => {
    emailVerification(email).then((response) => {
      setSend(1);
      setMessage(response.message);
      setTimeout(() => {
        setMessage("");
        setSend(0);
      }, 20000);
    });
  };

  return (
    <>
      <div className="emailVerificationContainer">
        <img src={emailIMG} alt="" />
        <h1>Verifica tu cuenta</h1>
        <p>
          Hemos enviado a tu correo electrónico el link de activación de cuenta
          para que puedas utilizarla, El link expirara en 15 minutos.
        </p>
        <p>
          Da{" "}
          {send === 0 ? <a onClick={sendEmailAgain}>clic aquí</a> : "clic aquí"}{" "}
          si no recibiste el link en tu correo electrónico o si el link de
          activación expiro
        </p>
        <input
          className="buttonLogin"
          type={"submit"}
          value={"Iniciar Sesión"}
          onClick={goToLogin}
        ></input>
        <p className="message">{message}</p>
      </div>
    </>
  );
};
export default EmailVerification;
