import React, { useEffect } from "react";
import Axios from "axios";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../context/userProvider";
import "./sobreNosotros.scss";
import img1 from "../../assets/sobreNosotros/knowUs1.jpg";
import img2 from "../../assets/sobreNosotros/knowUs2.jpg";
import img3 from "../../assets/sobreNosotros/team3.png";
import img4 from "../../assets/sn_04.jpg";
import { dataDecrypt } from "../../utils/dataDecrypt";

const SobreNosotros = () => {
  const navigate = useNavigate();
  const [user, setUser] = React.useContext(UserContext);
  var sessionStorageArray = []
  useEffect(() => {
    Axios.get(process.env.REACT_APP_API_URL + "isUserAuth/", {
      headers: {
        "x-access-token": localStorage.getItem("token"),
      },
    }).then((response) => {
      if (response.data.auth === false) {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
      } else {
        var userStorage = localStorage.getItem("user");
        var userJson = dataDecrypt(userStorage);
        setUser(userJson);

        var checkAuth = false;
        sessionStorageArray = dataDecrypt(localStorage.getItem("session"));
        sessionStorageArray.forEach((e) => {
          if (userJson.id === e.id) {
            checkAuth = true;
          }
        });
        if (checkAuth !== true) {
          navigate("/login");
        }
      }
    });
  }, []);
  return (
    <>
      <div className="snContainer">
        <section
          className="parallaxSection"
          style={{ backgroundImage: `url(${img1})` }}
        >
          <div className="snTitleContainer">
            <h3>Sobre</h3>
            <h1>Nosotros</h1>
          </div>
        </section>

        <div className="acercaDeContainer">
          <div className="acercaDeBody">
            <span>
              Active English School ofrece un ambiente académico robusto, diseñado
              para que nuestros alumnos aprendan, crezcan y se desarrollen. Tanto
              nuestros planes de estudio como los métodos de enseñanza que
              utilizamos incrementan la confianza de las personas y permiten que
              se desarrollen y que lleven su conocimiento a la práctica.
              <br />
              <br />
              Somos una empresa 100% mexicana idealizada sobre la base de servir a
              la sociedad por sobre todo, idea que transmitimos a nuestros
              estudiantes para ayudarlos a tener un impacto positivo en el mundo.
            </span>
          </div>
          <div className="acercaDeImgContainer">
            <img src={img2} alt="Recepcionistas" />
          </div>
        </div>
        <div className="ceContainer">
          <div className="ceImgContainer">
            <img src={img3} alt="" />
          </div>
          <div className="ceBody">
            <div className="ceTitle">
              <h2>Comprometidos con la excelencia</h2>
            </div>
            <div className="ceInfo">
              <span>
                Nos comprometemos a brindar experiencias educativas de excelencia que promuevan el desarrollo integral de nuestros alumnos dentro y fuera del aula. Desde su fundación
                en 2008, Active English School ha reflejado la energía dinámica y vibrante de la zona. Nuestro equipo dedicado está aquí para ayudar a los alumnos no solo a aprender
                inglés, sino también a tener un impacto positivo en el mundo.
                <br />
                <br />
                Ofrecemos clases tanto presenciales como en línea, con una variedad de horarios flexibles para adaptarse a sus necesidades. Nuestra oferta educativa incluye clases
                para adultos y niños, garantizando atención integral para todas las edades y niveles de aprendizaje.
                Nos esforzamos por proporcionar una experiencia de aprendizaje del inglés excepcional en un entorno cómodo, equipando nuestras instalaciones con tecnología
                moderna para brindar el ambiente ideal.

              </span>
            </div>
          </div>
        </div>
        <div className="misionVisionContainer">
          <div className="misionContainer">
            <div className="misionTitle">
              <h1>Misión</h1>
              <h3>Excelencia</h3>
            </div>
            <div className="misionBody">
              <span>
                Satisfacer los requerimientos de nuestros alumnos ofreciendo un sistema de enseñanza que supere sus expectativas.
                Que nuestro personal académico este excelentemente capacitado para el desarrollo académico y personal de nuestros estudiantes.
              </span>
            </div>
          </div>
          <div className="visionContainer">
            <div className="visionTitle">
              <h1>Visión</h1>
              <h3>Ser los Mejores</h3>
            </div>
            <div className="visionBody">
              <span>
                Lograr la máxima calidad de nuestro sistema de enseñanza implantando técnicas de enseñanza cada vez mas eficaces, practicas y divertidas logrando que todos nuestros
                egresados sean verdaderamente bilingües y estén listos para tomar nuevos retos.
                Contar con las instalaciones mas modernas y cómodas que cualquier estudiante pudiera encontrar en un instituto de idiomas de la región con el fin de crear el mejor y
                mas propicio ambiente dentro del aula.
              </span>
            </div>
          </div>
        </div>
        <div
          className="valores"
          style={{
            backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url(${img4})`,
          }}
        >
          <h1>Valores</h1>
          <div className="borderT"></div>
          <div className="vCaracteristicas">
            <div className="vCalidadH">
              <h2>Calidad Humana</h2>
              <span>
                El maestro tratará a sus alumnos con calor humano y será un
                ejemplo al promover respeto, actitud y profesionalismo.
              </span>
            </div>
            <div className="vInteres">
              <h2>Interés Genuino</h2>
              <span>
                El maestro deberá estar verdaderamente interesado en el
                bienestar y aprendizaje de sus alumnos.
              </span>
            </div>
            <div className="vEnseñanza">
              <h2>Enseñanza Práctica</h2>
              <span>
                El maestro será dinámico y explicará de la manera más sencilla y
                entendible para que todos logren hablar lo que aprenden.
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default SobreNosotros;
