import firebase from 'firebase/compat/app';
import "firebase/compat/storage";

const firebaseConfig = {
    apiKey: "AIzaSyBSXrSXC0zVnlBivmDK3C4AbChF62lNr0w",
    authDomain: "prueba-9fa4f.firebaseapp.com",
    projectId: "prueba-9fa4f",
    storageBucket: "prueba-9fa4f.appspot.com",
    messagingSenderId: "501796320413",
    appId: "1:501796320413:web:38929be883639294f35074",
    measurementId: "G-8BD069NNDN"
  };
  firebase.initializeApp(firebaseConfig);
  const storage = firebase.storage();
  export {storage, firebase as default};