import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { UserContext } from "../../context/userProvider";
import "./footer.scss";
import { dataDecrypt } from "../../utils/dataDecrypt";

import PhoneSVG from "../../components/icons/phone";
import FaceSVG from "../../components/icons/facebook";
import MailSVG from "../../components/icons/mail";
import TiktokSVG from "../../components/icons/tiktok";
import InstaSVG from "../../components/icons/instagram";
import UserpSVG from "../../components/icons/user";

import "../../components/icons/icons.scss"
const Footer = () => {
  const [userContext, setUserContext] = React.useContext(UserContext);
  const [checkAuth, setChechAuth] = useState(false);
  var sessionStorageArray = [];
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    setChechAuth(false)
    if (Object.keys(userContext).length !== 0) {
      if (localStorage.getItem("session")) {
        sessionStorageArray = dataDecrypt(localStorage.getItem("session"));
        sessionStorageArray.forEach((e) => {
          if (userContext.id === e.id) {
            setChechAuth(true);
          }
        });
      }
    } else {

      if (localStorage.getItem("user")) {
        setChechAuth(true)
        setUserContext(dataDecrypt(localStorage.getItem("user")));
      }
    }
  }, [location]);
  const handleSignOut = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    setUserContext({});
    navigate("/login");
  }
  return (
    <>
      {window.location.pathname !== "/recoverAccount" &&
        window.location.pathname !== "/recoverAccountCode" &&
        window.location.pathname !== "/changePassword" &&

        <footer className="footer">
          <div className="links">
            <div className="about">
              <p>Nuestra escuela</p>
              <Link to="/sobreNosotros" className="links_anchor">Sobre nosotros</Link>
              <Link to="/instalaciones" className="links_anchor">Instalaciones</Link>
              <Link to="/nuestroEquipo" className="links_anchor">Nuestro equipo</Link>
            </div>
            <div className="school">
              <p>Académico</p>
              <Link to="/ofertaEducativa" className="links_anchor">Oferta educativa</Link>
              <Link to="/metodoDeEnsenanza" className="links_anchor">Método de enseñanza</Link>
              <Link to="/clasesEnLinea" className="links_anchor">Clases en línea</Link>
              <Link to="/examenDeUbicacion" className="links_anchor">Examen de ubicación</Link>
            </div>
            <div className="contact">
              <p className="caption">Contácto</p>
              <div className="contact_element">
                <PhoneSVG className="small"></PhoneSVG>
                <p>664-701-9736</p>
              </div>
              <div className="contact_element">
                <MailSVG className="small"></MailSVG>
                <p>contacto@activeforlife.com.mx</p>
              </div>

              <div className="login_element">
                {
                  Object.keys(userContext).length === 0 ||
                    !localStorage.getItem("token") ?
                    <>
                      <UserpSVG className="small" />
                      <Link to="/login">Iniciar Sesión</Link>
                    </>
                    :
                    <>
                      <UserpSVG className="small" />
                      <a onClick={handleSignOut}>Cerrar Sesión</a>
                    </>
                }
              </div>

            </div>
          </div>
          <div className="social_media">
            <div className="social_img">
              <a href="https://www.facebook.com/ActiveEnglishTijuana" target="_blank"></a>
              <FaceSVG className="medium" />
            </div>
            <div className="social_img">
              <a href="https://www.instagram.com/active.english.school/?hl=es-la" target="_blank"></a>
              <InstaSVG className="medium" />
            </div>
            <div className="social_img">
              <a href="https://www.tiktok.com/@active.english.school" target="_blank"></a>
              <TiktokSVG className="medium" />
            </div>
          </div>
        </footer>}
    </>
  );
};
export default Footer;
