import React, { useEffect } from "react";
import Axios from "axios";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../context/userProvider";
import "./equipo.scss";
import { dataDecrypt } from "../../utils/dataDecrypt";
import img1 from "../../assets/equipo/Team6.jpg"
import img1a2 from "../../assets/equipo/Team4.png"
import img1a3 from "../../assets/equipo/Team3.png"
import img2 from "../../assets/equipo/Team7.png"
import img3 from "../../assets/equipo/Team8.jpg"
import img4 from "../../assets/equipo/tm3.png"
import img5 from "../../assets/equipo/Team11.jpg"
import img5a2 from "../../assets/equipo/Team9.png"
import imgbottom from "../../assets/equipo/Team12.png"

const Equipo = () => {
  const navigate = useNavigate();
  const [user, setUser] = React.useContext(UserContext);
  var sessionStorageArray = []
  useEffect(() => {
    Axios.get(process.env.REACT_APP_API_URL + "isUserAuth/", {
      headers: {
        "x-access-token": localStorage.getItem("token"),
      },
    }).then((response) => {
      if (response.data.auth === false) {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
      } else {
        var userStorage = localStorage.getItem("user");
        var userJson = dataDecrypt(userStorage);
        setUser(userJson);

        var checkAuth = false;
        sessionStorageArray = dataDecrypt(localStorage.getItem("session"));
        sessionStorageArray.forEach((e) => {
          if (userJson.id === e.id) {
            checkAuth = true;
          }
        });
        if (checkAuth !== true) {
          navigate("/login");
        }
      }
    });
  }, []);
  return (
    <>
      <div className="tm_container">
                <section className="tm_header">
                    <div className="tm_header_img">
                        <div className="img1">
                            <img className="img_bg" src={img1} />
                            <img className="img_front" src={img1a2} />
                            <img className="img_front2" src={img1a3} />
                        </div>
                        <div className="img2">
                            <img src={img2} />
                        </div>
                        <div className="img3">
                            <img className="img_bg" src={img3} />
                        </div>
                        <div className="tm_title_container">
                            <h3 className="tm_title1">Nuestro</h3>
                            <h1 className="tm_title2">Equipo</h1>
                        </div>
                        <div className="img4">
                            <img src={img4} />
                        </div>
                        <div className="img5">
                            <img className="img_bg" src={img5} />
                            <img className="img_front" src={img5a2} />
                        </div>
                        <div className="txt">
                            <h2>
                                Contamos con un equipo excepcional, dedicado a hacer que tu estancia en nuestras instalaciones sea agradable. Siempre te recibiremos con una sonrisa y la mejor actitud.
                            </h2>
                        </div>
                    </div>

                </section>

                <div className="paragraph">
                    <p>
                        Nuestros maestros son dinámicos y tienen un verdadero interés en que sus alumnos aprendan de manera entretenida. Se apegan a un método de enseñanza que garantiza que los estudiantes pongan en práctica todo lo que aprenden.
                        <br />
                        <br />
                        Constantemente realizan entrenamientos para estar siempre bien preparados y mejorar sus habilidades de enseñanza. Además, nuestros directores académicos se aseguran de mantener consistentemente la misma calidad educativa.
                    </p>
                </div>

                {/* <div className="tm_bottom">
                    <img src={imgbottom} />
                </div> */}


            </div>
    </>
  );
};
export default Equipo;
